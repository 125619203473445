import { ActionType } from "../../app/interfaces/enum";
import { EmagazineResponse } from "../../app/interfaces/interfaces";
import {
  EMAGAZINE_ACTION_LOADING,
  ACTION_EMAGAZINE_TYPE,
  MESSAGE_EMAGAZINE_ERROR,
  CREATE_EMAGAZINE_SUCCESS,
  GET_ALL_EMAGAZINES_ERROR,
  GET_ALL_EMAGAZINES_SUCCESS,
  EMAGAZINE_LOADING,
  SEARCH_EMAGAZINE_INPUT,
  SHOW_ALERT_EMAGAZINE,
  SHOW_EMAGAZINE_MODAL,
  TITLE_EMAGAZINE_INPUT,
  UPDATE_EMAGAZINE_SUCCESS,
  DELETE_RUBRIC_SUCCESS,
} from "../types/types";

export interface StateMagazine {
  title: string;
  search: string;
  isLoading: boolean;
  actionLoading: boolean;
  showModal: boolean;
  showAlert: boolean;
  error: string;
  emagazines: EmagazineResponse[];
  messageError: string;
  action: ActionType;
}

const initialStateRubric: StateMagazine = {
  title: "",
  search: "",
  isLoading: false,
  actionLoading: false,
  showModal: false,
  showAlert: false,
  error: "",
  emagazines: [],
  messageError: "",
  action: ActionType.POST,
};

export const reducerEmagazine = (state = initialStateRubric, action: any) => {
  switch (action.type) {
    case EMAGAZINE_LOADING:
      return {
        ...state,
        isLoading: action.payload,
        error: "",
      };
    case TITLE_EMAGAZINE_INPUT:
      return {
        ...state,
        title: action.payload,
      };
    case SEARCH_EMAGAZINE_INPUT:
      return {
        ...state,
        search: action.payload,
      };
    case GET_ALL_EMAGAZINES_SUCCESS:
      return {
        ...state,
        emagazines: action.payload,
        isLoading: false,
      };
    case GET_ALL_EMAGAZINES_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case SHOW_EMAGAZINE_MODAL:
      return {
        ...state,
        showModal: action.payload,
        error: "",
      };
    case ACTION_EMAGAZINE_TYPE:
      return {
        ...state,
        action: action.payload,
        error: "",
      };
    case EMAGAZINE_ACTION_LOADING:
      return {
        ...state,
        actionLoading: action.payload,
        error: "",
      };
    case CREATE_EMAGAZINE_SUCCESS:
      return {
        ...state,
        emagazines: [...state.emagazines, action.payload],
        messageError: "",
        actionLoading: false,
      };
    case MESSAGE_EMAGAZINE_ERROR:
      return {
        ...state,
        messageError: "",
        actionLoading: false,
      };
    case SHOW_ALERT_EMAGAZINE:
      return {
        ...state,
        showAlert: action.payload,
        createError: "",
      };
    case DELETE_RUBRIC_SUCCESS:
      const copy = [...state.emagazines];
      const index = copy.findIndex((element) => element.id === action.payload);
      if (index > -1) {
        copy.splice(index, 1);
      }
      return {
        ...state,
        emagazines: copy,
        createError: "",
        actionLoading: false,
      };
    case UPDATE_EMAGAZINE_SUCCESS:
      const copyUpdate = [...state.emagazines];
      const item = copyUpdate.find(
        (element) => element.id === action.payload.id
      );
      const i = copyUpdate.indexOf(item!);
      copyUpdate[i] = action.payload;
      return {
        ...state,
        emagazines: copyUpdate,
        createError: "",
        actionLoading: false,
      };
    default:
      return state;
  }
};
