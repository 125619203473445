import { User } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { createContext, useState, useEffect, ReactNode } from "react";
import { SignOutUser, userStateListener } from "../core/firebase/firebase-api";
import { useCookies } from "react-cookie";
import { setCookie } from "typescript-cookie";
import { UserResponse } from "../interfaces/interfaces";

interface Props {
  children?: ReactNode;
}

export const AuthContext = createContext({
  currentUser: {} as User | null,
  setCurrentUser: (_user: User) => {},
  signOut: () => {},
  setUserCookie: (_user: UserResponse) => {},
});

export const AuthProvider = ({ children }: Props) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, removeCookie] = useCookies(["user"]);

  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = userStateListener((user) => {
      if (user) {
        setCurrentUser(user);
      }
    });
    return unsubscribe;
  }, [setCurrentUser]);

  const signOut = () => {
    SignOutUser();
    setCurrentUser(null);
    removeCookie("user", {});
    navigate("/");
  };

  const setUserCookie = (user: UserResponse) => {
    setCookie("user", JSON.stringify(user));
  };

  const value = {
    currentUser,
    setCurrentUser,
    signOut,
    setUserCookie,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
