import { toast } from "react-toastify";
import { api } from "../../app/core/api/api";
import {
  ArticleResponse,
  SpotlightResponse,
} from "../../app/interfaces/interfaces";
import {
  ADD_SPOTLIGHT,
  DELETE_SPOTLIGHT_SUCCESS,
  GET_ALL_MAGAZINE,
  GET_ALL_SPOTLIGHT,
  MAGAZINE_LOADING,
  SPOTLIGHT_ERROR,
  SPOTLIGHT_LOADING,
} from "../types/types";

export function isSpotlightLoading(isload: boolean) {
  return {
    type: SPOTLIGHT_LOADING,
    payload: isload,
  };
}

export function getSpotlightsSuccess(spotlights: SpotlightResponse[]) {
  return {
    type: GET_ALL_SPOTLIGHT,
    payload: spotlights,
  };
}

export function addSpotlightsSuccess(spotlight: SpotlightResponse) {
  return {
    type: ADD_SPOTLIGHT,
    payload: spotlight,
  };
}

export function spotlightsError(error: string) {
  return {
    type: SPOTLIGHT_ERROR,
    payload: error,
  };
}

export function deleteSpotlightSuccess(magazineId: string) {
  return {
    type: DELETE_SPOTLIGHT_SUCCESS,
    payload: magazineId,
  };
}

export function isLoading(isload: boolean) {
  return {
    type: MAGAZINE_LOADING,
    payload: isload,
  };
}

export function getMagazinesSuccess(magazines: ArticleResponse[]) {
  return {
    type: GET_ALL_MAGAZINE,
    payload: magazines,
  };
}

export const apiGetSpotlights = () => {
  return (dispatch: any) => {
    dispatch(isSpotlightLoading(true));
    api.get(`/magazine/all-spotlight/spotlight`).then(async (res) => {
      if (res.status === 200) {
        const spotlights: SpotlightResponse[] = await res.json();
        dispatch(getSpotlightsSuccess(spotlights));
      } else {
        dispatch(spotlightsError("No data"));
      }
    });
  };
};

export const apiAddSpotlights = (magazineId: string) => {
  return (dispatch: any) => {
    dispatch(isSpotlightLoading(true));
    api.get(`/magazine/spotlight/${magazineId}`).then(async (res) => {
      if (res.status === 200) {
        window.location.href = "/magazines";
      } else {
        dispatch(spotlightsError("No data"));
      }
    });
  };
};

export const removeSpotlight = (magazineId: string) => {
  return (dispatch: any) => {
    api.delete(`/magazine/spotlight/${magazineId}`).then((res) => {
      if (res.status === 200) {
        dispatch(deleteSpotlightSuccess(magazineId));
        toast("Success...", { type: "success" });
      }
    });
  };
};

export const apiGetAllMagazine = () => {
  return (dispatch: any) => {
    dispatch(isLoading(true));
    api.get(`/magazine`).then(async (res) => {
      if (res.status === 200) {
        const magazines: ArticleResponse[] = await res.json();
        dispatch(getMagazinesSuccess(magazines));
      } else {
        dispatch(spotlightsError("No data"));
      }
    });
  };
};

export const siteGetAllMagazine = () => {
  return (dispatch: any) => {
    dispatch(isLoading(true));
    api.get(`/site/magazine`).then(async (res) => {
      if (res.status === 200) {
        const magazines: ArticleResponse[] = await res.json();
        dispatch(getMagazinesSuccess(magazines));
      } else {
        dispatch(spotlightsError("No data"));
      }
    });
  };
};
