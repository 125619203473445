import { ArticleResponse } from "../../app/interfaces/interfaces";
import ReactHtmlParser from "react-html-parser";
import {
  deltaTextToHtml,
  formatDateMMMddyyy,
  formatDatePost,
} from "../../utils/utils";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { TextLineReducer } from "./TextLineReducer";

interface SpotlightProps {
  spotlight: ArticleResponse;
  className?: string;
}
export function Spotlight({ spotlight, className }: SpotlightProps) {
  return (
    spotlight && (
      <div className={classNames(className, "flex flex-row ml-5 gap-5")}>
        <div className="h-24 w-28">
          <img
            src={spotlight.articlePic}
            className="h-full w-full object-cover"
            alt=""
          />
        </div>
        <div className="flex w-3/4 flex-col justify-between">
          <div className="text-2-lines text-white font-roboto text-sm font-light">
            <TextLineReducer
              text={deltaTextToHtml(spotlight).slice(0, 200)}
              maxLines={2}
            />
          </div>
          <div className="flex flex-row justify-between gap-4">
            <div className="flex flex-row gap-4">
              <div className="flex items-center gap-1">
                <svg
                  width="15"
                  height="14"
                  viewBox="0 0 15 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.56551 13.2707C4.10634 13.2707 1.29467 10.459 1.29467 6.99984C1.29467 3.54067 4.10634 0.729004 7.56551 0.729004C11.0247 0.729004 13.8363 3.54067 13.8363 6.99984C13.8363 10.459 11.0247 13.2707 7.56551 13.2707ZM7.56551 1.604C4.59051 1.604 2.16967 4.02484 2.16967 6.99984C2.16967 9.97484 4.59051 12.3957 7.56551 12.3957C10.5405 12.3957 12.9613 9.97484 12.9613 6.99984C12.9613 4.02484 10.5405 1.604 7.56551 1.604Z"
                    fill="white"
                  />
                  <path
                    d="M9.72966 9.29253C9.65382 9.29253 9.57799 9.27503 9.50799 9.22836L7.69966 8.14919C7.25049 7.88086 6.91799 7.29169 6.91799 6.77253V4.38086C6.91799 4.14169 7.11632 3.94336 7.35549 3.94336C7.59466 3.94336 7.79299 4.14169 7.79299 4.38086V6.77253C7.79299 6.98253 7.96799 7.29169 8.14882 7.39669L9.95716 8.47586C10.1672 8.59836 10.2313 8.86669 10.1088 9.07669C10.0213 9.21669 9.87549 9.29253 9.72966 9.29253Z"
                    fill="white"
                  />
                </svg>

                <span className="text-white text-sm font-light">
                  {formatDatePost(spotlight.created_at)}
                </span>
              </div>
              <div className="flex items-center gap-1">
                <svg
                  width="15"
                  height="14"
                  viewBox="0 0 15 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.23217 3.354C4.99301 3.354 4.79467 3.15567 4.79467 2.9165V1.1665C4.79467 0.927337 4.99301 0.729004 5.23217 0.729004C5.47134 0.729004 5.66967 0.927337 5.66967 1.1665V2.9165C5.66967 3.15567 5.47134 3.354 5.23217 3.354Z"
                    fill="white"
                  />
                  <path
                    d="M9.89884 3.354C9.65967 3.354 9.46134 3.15567 9.46134 2.9165V1.1665C9.46134 0.927337 9.65967 0.729004 9.89884 0.729004C10.138 0.729004 10.3363 0.927337 10.3363 1.1665V2.9165C10.3363 3.15567 10.138 3.354 9.89884 3.354Z"
                    fill="white"
                  />
                  <path
                    d="M9.31551 13.2707H5.81551C2.53717 13.2707 1.87801 11.7248 1.87801 9.22817V5.629C1.87801 2.864 2.81134 1.73817 5.20884 1.604H9.89884C9.90467 1.604 9.91634 1.604 9.92217 1.604C12.3197 1.73817 13.253 2.864 13.253 5.629V9.22817C13.253 11.7248 12.5938 13.2707 9.31551 13.2707ZM5.23217 2.479C3.59884 2.57234 2.75301 3.08567 2.75301 5.629V9.22817C2.75301 11.4623 3.17884 12.3957 5.81551 12.3957H9.31551C11.9522 12.3957 12.378 11.4623 12.378 9.22817V5.629C12.378 3.0915 11.538 2.57234 9.88717 2.479H5.23217Z"
                    fill="white"
                  />
                  <path
                    d="M12.6697 10.7041H2.46134C2.22217 10.7041 2.02384 10.5058 2.02384 10.2666C2.02384 10.0274 2.22217 9.8291 2.46134 9.8291H12.6697C12.9088 9.8291 13.1072 10.0274 13.1072 10.2666C13.1072 10.5058 12.9088 10.7041 12.6697 10.7041Z"
                    fill="white"
                  />
                  <path
                    d="M7.56551 4.8125C6.84801 4.8125 6.24134 5.20333 6.24134 5.96167C6.24134 6.32333 6.41051 6.5975 6.66717 6.7725C6.31134 6.9825 6.10717 7.32083 6.10717 7.7175C6.10717 8.44083 6.66134 8.89 7.56551 8.89C8.46384 8.89 9.02384 8.44083 9.02384 7.7175C9.02384 7.32083 8.81967 6.97667 8.45801 6.7725C8.72051 6.59167 8.88384 6.32333 8.88384 5.96167C8.88384 5.20333 8.28301 4.8125 7.56551 4.8125ZM7.56551 6.46917C7.26217 6.46917 7.04051 6.28833 7.04051 6.0025C7.04051 5.71083 7.26217 5.54167 7.56551 5.54167C7.86884 5.54167 8.09051 5.71083 8.09051 6.0025C8.09051 6.28833 7.86884 6.46917 7.56551 6.46917ZM7.56551 8.16667C7.18051 8.16667 6.90051 7.97417 6.90051 7.62417C6.90051 7.27417 7.18051 7.0875 7.56551 7.0875C7.95051 7.0875 8.23051 7.28 8.23051 7.62417C8.23051 7.97417 7.95051 8.16667 7.56551 8.16667Z"
                    fill="white"
                  />
                </svg>
                <span className="text-white text-xs font-light">
                  {formatDateMMMddyyy(spotlight.created_at)}
                </span>
              </div>
            </div>
            <Link
              to={`/magazine-detail/${spotlight.id}`}
              className="inline-flex items-center p-2 bg-transparent border-2 focus:outline-none text-lg mt-4 md:mt-0"
            >
              <svg
                width="12"
                height="8"
                viewBox="0 0 12 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.919 4.35355C11.1143 4.15829 11.1143 3.84171 10.919 3.64645L7.73706 0.464467C7.5418 0.269205 7.22522 0.269204 7.02996 0.464467C6.8347 0.659729 6.8347 0.976311 7.02996 1.17157L9.85838 4L7.02996 6.82843C6.83469 7.02369 6.83469 7.34027 7.02996 7.53553C7.22522 7.7308 7.5418 7.7308 7.73706 7.53553L10.919 4.35355ZM0.565491 4.5L10.5655 4.5L10.5655 3.5L0.565491 3.5L0.565491 4.5Z"
                  fill="white"
                />
              </svg>
            </Link>
          </div>
        </div>
      </div>
    )
  );
}
