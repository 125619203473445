import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Bars3BottomLeftIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Disclosure } from "@headlessui/react";
import { Link, useLocation } from "react-router-dom";
import {
  DashboardSVG,
  ShoppingSVG,
  BoxSVG,
  UsersSVG,
  PortraitSVG,
  MagazineSVG,
  BookSquareSVG,
  PlayCircleSVG,
} from "../components";
import { useCookies } from "react-cookie";
import { RoleUser } from "../interfaces/enum";
import { toast } from "react-toastify";
import { AuthContext } from "../context/auth-context";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function Content({ children }: any) {
  const { signOut } = React.useContext(AuthContext);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [cookies] = useCookies(["user"]);
  const location = useLocation();

  const getUrl = (url: string): boolean => {
    const href_location = location.pathname.search(url);
    if (href_location > -1) {
      return true;
    }
    return false;
  };

  const logout = async () => {
    try {
      signOut();
    } catch (error: any) {
      toast(error.message, { type: "error" });
    }
  };

  const navigation = [
    {
      name: "Tableau de bord",
      icon: DashboardSVG,
      href: "/dashboard",
      role: [RoleUser.Admin, RoleUser.Super_Admin],
    },
    {
      name: "Rubriques",
      icon: BoxSVG,
      href: "/dashboard/rubrics",
      role: [RoleUser.Super_Admin],
    },
    {
      name: "Magazines",
      icon: MagazineSVG,
      href: "/dashboard/magazines",
      role: [RoleUser.Admin, RoleUser.Super_Admin],
    },
    // {
    //   name: "Magazines a la une",
    //   icon: MagazineTwoSVG,
    //   href: "/dashboard/spotlights",
    //   role: [RoleUser.Admin, RoleUser.Super_Admin],
    // },
    {
      name: "Portraits",
      icon: PortraitSVG,
      current: false,
      href: "/dashboard/app-portrait",
      role: [RoleUser.Admin, RoleUser.Super_Admin],
    },
    {
      name: "videos",
      icon: PlayCircleSVG,
      current: false,
      href: "/dashboard/videos",
      role: [RoleUser.Admin, RoleUser.Super_Admin],
    },
    {
      name: "EMagazines",
      icon: BookSquareSVG,
      href: "/dashboard/e-magazines",
      role: [RoleUser.Admin, RoleUser.Super_Admin],
    },
    {
      name: "Sciences",
      icon: ShoppingSVG,
      href: "/dashboard/science",
      role: [RoleUser.Admin, RoleUser.Super_Admin],
    },
    // {
    //   name: "Newsletters",
    //   icon: SendSVG,
    //   current: false,
    //   href: "#",
    //   role: [RoleUser.Admin, RoleUser.Super_Admin],
    // },
    {
      name: "Utilisateurs",
      icon: UsersSVG,
      current: false,
      href: "/dashboard/users",
      role: [RoleUser.Super_Admin],
    },
  ];
  return (
    <div className="h-full">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex flex-shrink-0 items-center px-4">
                  <img
                    className="h-8 w-auto"
                    src="../assets/svg/logo.svg"
                    alt="Your Company"
                  />
                </div>
                <div className="mt-5 h-0 flex-1 overflow-y-auto">
                  <nav className="space-y-1 px-2">
                    {navigation.map((item) => (
                      <Disclosure
                        as="div"
                        key={item.name}
                        className="space-y-1"
                      >
                        {({ open }) => (
                          <>
                            <Disclosure.Button
                              className={classNames(
                                getUrl(item.href)
                                  ? "bg-primary-100 text-white"
                                  : "bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                                "group w-full flex h-12 items-center pl-2 pr-1 py-3 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-primary-100"
                              )}
                            >
                              <item.icon
                                color={getUrl(item.href) ? "#fff" : "#757B82"}
                                classname="mr-3 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"
                              />
                              <span className="flex-1">{item.name}</span>
                              <svg
                                className={classNames(
                                  open
                                    ? "text-white rotate-180"
                                    : "text-gray-300",
                                  "ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400"
                                )}
                                viewBox="0 0 20 20"
                                aria-hidden="true"
                              >
                                <path
                                  d="M4.70711 5.29289C4.31658 4.90237 3.68342 4.90237 3.29289 5.29289C2.90237 5.68342 2.90237 6.31658 3.29289 6.70711L4.70711 5.29289ZM8 10L7.29289 10.7071C7.68342 11.0976 8.31658 11.0976 8.70711 10.7071L8 10ZM12.7071 6.70711C13.0976 6.31658 13.0976 5.68342 12.7071 5.29289C12.3166 4.90237 11.6834 4.90237 11.2929 5.29289L12.7071 6.70711ZM3.29289 6.70711L7.29289 10.7071L8.70711 9.29289L4.70711 5.29289L3.29289 6.70711ZM8.70711 10.7071L12.7071 6.70711L11.2929 5.29289L7.29289 9.29289L8.70711 10.7071Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </Disclosure.Button>
                            <Disclosure.Panel className="space-y-1">
                              <div className="container mx-auto w-full h-full">
                                <div className="relative wrap h-full">
                                  <div className="border-2-2 absolute border-gray-90 h-full border"></div>

                                  <div className=" mx-3 flex flex-col justify-between items-center w-full"></div>
                                </div>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    ))}
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex flex-grow flex-col overflow-y-auto border-r border-gray-200 bg-white pt-5">
          <div className="flex flex-shrink-0 items-center px-4 ">
            <Link to={"/"}>
              <img
                className="h-10 w-auto"
                src="../assets/svg/div.svg"
                alt="Your Company"
              />
            </Link>
          </div>
          <div className="mt-5 flex flex-grow flex-col">
            <nav
              className="flex-1 space-y-1 bg-white px-2"
              aria-label="Sidebar"
            >
              {navigation.map(
                (item) =>
                  item.role.find((role) => role === cookies.user.role) && (
                    <div key={item.name}>
                      <Link
                        to={item.href}
                        className={classNames(
                          item.href === location.pathname
                            ? "bg-primary-100 text-white"
                            : "bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                          "group w-full h-12 flex items-center pl-2 py-4 text-sm font-medium rounded-lg"
                        )}
                      >
                        <item.icon
                          color={
                            item.href === location.pathname ? "#fff" : "#757B82"
                          }
                          classname={classNames(
                            item.href === location.pathname
                              ? "text-gray-500"
                              : "text-gray-400 group-hover:text-gray-500",
                            "mr-3 flex-shrink-0 h-6 w-6"
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link>
                    </div>
                  )
              )}
            </nav>
          </div>
        </div>
      </div>
      <div className="flex flex-1 flex-col md:pl-64 h-full">
        <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 bg-white shadow">
          <button
            type="button"
            className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:primary-100 md:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex flex-1 justify-between px-4">
            <div className="flex flex-1"></div>
            <div className="ml-4 flex items-center md:ml-6">
              {/* <button
                type="button"
                className="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-100 focus:ring-offset-2"
              >
                <span className="sr-only">View notifications</span>
                <BellIcon className="h-6 w-6" aria-hidden="true" />
              </button> */}
              <button
                type="button"
                onClick={() => logout()}
                className="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-100 focus:ring-offset-2"
              >
                <span className="sr-only">View notifications</span>
                <span className="h-6 w-6 rounded-full">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.24 22.27H15.11C10.67 22.27 8.53002 20.52 8.16002 16.6C8.12002 16.19 8.42002 15.82 8.84002 15.78C9.24002 15.74 9.62002 16.05 9.66002 16.46C9.95002 19.6 11.43 20.77 15.12 20.77H15.25C19.32 20.77 20.76 19.33 20.76 15.26V8.73998C20.76 4.66998 19.32 3.22998 15.25 3.22998H15.12C11.41 3.22998 9.93002 4.41998 9.66002 7.61998C9.61002 8.02998 9.26002 8.33998 8.84002 8.29998C8.42002 8.26998 8.12001 7.89998 8.15001 7.48998C8.49001 3.50998 10.64 1.72998 15.11 1.72998H15.24C20.15 1.72998 22.25 3.82998 22.25 8.73998V15.26C22.25 20.17 20.15 22.27 15.24 22.27Z"
                      fill="#292D32"
                    />
                    <path
                      d="M15.0001 12.75H3.62012C3.21012 12.75 2.87012 12.41 2.87012 12C2.87012 11.59 3.21012 11.25 3.62012 11.25H15.0001C15.4101 11.25 15.7501 11.59 15.7501 12C15.7501 12.41 15.4101 12.75 15.0001 12.75Z"
                      fill="#292D32"
                    />
                    <path
                      d="M5.84994 16.1001C5.65994 16.1001 5.46994 16.0301 5.31994 15.8801L1.96994 12.5301C1.67994 12.2401 1.67994 11.7601 1.96994 11.4701L5.31994 8.12009C5.60994 7.83009 6.08994 7.83009 6.37994 8.12009C6.66994 8.41009 6.66994 8.89009 6.37994 9.18009L3.55994 12.0001L6.37994 14.8201C6.66994 15.1101 6.66994 15.5901 6.37994 15.8801C6.23994 16.0301 6.03994 16.1001 5.84994 16.1001Z"
                      fill="#292D32"
                    />
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>

        <main className="flex-1 h-full">
          <div className="px-6 pt-3 h-full">{children}</div>
        </main>
      </div>
    </div>
  );
}
