export function LinkedinSVG() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_308_18824)">
        <path
          d="M13.1922 1.14111C6.64267 1.14111 1.33337 6.45041 1.33337 12.9999C1.33337 19.5495 6.64267 24.8588 13.1922 24.8588C19.7417 24.8588 25.051 19.5495 25.051 12.9999C25.051 6.45041 19.7417 1.14111 13.1922 1.14111ZM10.2893 17.9152H7.88785V10.1872H10.2893V17.9152ZM9.07373 9.23847C8.31526 9.23847 7.82485 8.70111 7.82485 8.03653C7.82485 7.35835 8.33008 6.83706 9.10461 6.83706C9.87914 6.83706 10.3535 7.35835 10.3683 8.03653C10.3683 8.70111 9.87914 9.23847 9.07373 9.23847ZM19.0598 17.9152H16.6584V13.6324C16.6584 12.6355 16.3101 11.9586 15.4417 11.9586C14.7783 11.9586 14.3843 12.4169 14.2101 12.8579C14.1458 13.0148 14.1298 13.2371 14.1298 13.4582V17.9139H11.7271V12.6516C11.7271 11.6868 11.6963 10.8802 11.6641 10.1859H13.7506L13.8605 11.2594H13.9087C14.2249 10.7554 14.9994 10.0118 16.2953 10.0118C17.8752 10.0118 19.0598 11.0704 19.0598 13.3458V17.9152Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_308_18824">
          <rect
            width="24.7059"
            height="24.7059"
            fill="white"
            transform="translate(0.838776 0.646484)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
