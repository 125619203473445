export function SearchInputSVG() {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9157 5.16719C10.8603 5.16719 8.88902 5.9837 7.43562 7.43711C5.98221 8.89051 5.1657 10.8618 5.1657 12.9172C5.1657 14.9726 5.98221 16.9439 7.43562 18.3973C8.88902 19.8507 10.8603 20.6672 12.9157 20.6672C14.9711 20.6672 16.9424 19.8507 18.3958 18.3973C19.8492 16.9439 20.6657 14.9726 20.6657 12.9172C20.6657 10.8618 19.8492 8.89051 18.3958 7.43711C16.9424 5.9837 14.9711 5.16719 12.9157 5.16719ZM2.58301 12.9172C2.58347 11.273 2.97629 9.65266 3.72884 8.19081C4.48139 6.72895 5.57192 5.46779 6.90984 4.51211C8.24776 3.55643 9.79442 2.93383 11.4213 2.69604C13.0482 2.45825 14.7084 2.61213 16.2639 3.1449C17.8193 3.67768 19.2252 4.57396 20.3647 5.75927C21.5041 6.94459 22.3443 8.38471 22.8153 9.95998C23.2863 11.5353 23.3746 13.2002 23.0729 14.8164C22.7711 16.4327 22.088 17.9536 21.0803 19.2528L28.0379 26.2123C28.2639 26.4578 28.3862 26.7811 28.3792 27.1147C28.3723 27.4482 28.2366 27.7662 28.0005 28.002C27.7645 28.2378 27.4464 28.3731 27.1128 28.3797C26.7792 28.3864 26.456 28.2637 26.2108 28.0374L19.2533 21.0799C17.7259 22.266 15.8964 22.9997 13.9727 23.1975C12.0491 23.3953 10.1085 23.0493 8.37173 22.1989C6.63496 21.3485 5.17169 20.0278 4.14835 18.3869C3.12501 16.7461 2.58267 14.851 2.58301 12.9172Z"
        fill="#333333"
        fill-opacity="0.99"
      />
    </svg>
  );
}
