import React from "react";

import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import { formatDateMMMddyyy } from "../../../utils/utils";
import { AddSVG, Button, Input, Spinner, TrashSVG } from "../../components";
import AlertConfirmation from "../../components/AlertConfirmation";
import { ScienceRequest } from "../../interfaces/interfaces";
import { Page } from "../../layouts/page";
import { useNavigate } from "react-router-dom";
import { isEmpty, isUndefined } from "lodash";
import {
  deleteScience,
  getSciences,
} from "../../core/firebase/firestore-collection";
import { FaSort } from "react-icons/fa";

export default function SciencePage() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchInput] = React.useState<string>();
  const [_articles, setArticles] = React.useState<ScienceRequest[]>([]);
  const [_showAlert, setShowAlert] = React.useState<boolean>(false);
  const [_loader, setLoader] = React.useState<boolean>(false);
  const [_articleId, setArticleId] = React.useState<string>();
  const [_loaderArticle, setLoaderArticle] = React.useState<boolean>(false);

  let navigate = useNavigate();

  const _removeArticle = React.useCallback(async (id: string) => {
    setLoader(true);
    try {
      await deleteScience(id).then(async () => {
        toast("Article supprimer", { type: "success" });
        await fecthArticles();
      });
    } catch (error: any) {
      toast(error.message, { type: "error" });
    }
    setLoader((loader) => !loader);
    setShowAlert((alert) => !alert);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const _findArticles = React.useMemo(() => {
  //   if (isEmpty(_searchInput)) return _articles;

  //   return _articles.filter((item) => {
  //     return (
  //       lowerCase(item.category).includes(lowerCase(_searchInput)) ||
  //       lowerCase(item.articleTitle).includes(lowerCase(_searchInput))
  //     );
  //   });
  // }, [_searchInput, _articles]);

  const fecthArticles = React.useCallback(async () => {
    setArticles([]);
    setLoaderArticle(true);
    (await getSciences()).forEach((item) => {
      const sciences = item.data() as ScienceRequest;
      setArticles((old) => [...old, sciences]);
    });
    setLoaderArticle(false);
  }, []);

  React.useEffect(() => {
    fecthArticles();
  }, [fecthArticles]);

  return (
    <>
      <ToastContainer />
      <Page
        left={
          <h1 className="font-bold text-2xl text-background-100">Sciences</h1>
        }
      >
        <div className="flex sm:flex-row flex-col sm:gap-0 gap-5 justify-between mt-5">
          <div className="flex flex-row ">
            <Input
              block
              type="search"
              placeholder="Chercher un magazine"
              id="title"
              onChange={(e) => setSearchInput(e.target.value)}
              name="title"
              className="md:w-60"
            />
          </div>
          <div className="flex">
            <Button
              color="primary"
              type="button"
              icon={<AddSVG />}
              title="Nouveau Magazine"
              className="w-auto"
              onClick={() => navigate("/dashboard/new-science-article")}
            />
          </div>
        </div>
        {_loaderArticle ? (
          <Spinner />
        ) : (
          <>
            {isEmpty(_articles) ? (
              <div className="bg-white py-12 flex mt-8 items-center justify-center w-full">
                <p>No Data</p>
              </div>
            ) : (
              <div className=" -mx-4 mt-8 ring-1 bg-white border-0 ring-white sm:-mx-6 md:mx-0 md:rounded-xl">
                <DataTable
                  title="Liste des magazines"
                  columns={[
                    {
                      name: "Ref",
                      selector: "id",
                    },
                    {
                      name: "Titre",
                      selector: "articleTitle",
                      cell: (row: ScienceRequest) => {
                        return <span> {row.articleTitle} </span>;
                      },
                    },
                    {
                      name: "Photo de coverture",
                      selector: "articlePic",
                      cell: (row: ScienceRequest) => {
                        return (
                          <img
                            alt="profil"
                            src={
                              row.articlePic
                                ? row.articlePic
                                : "../assets/svg/logo.svg"
                            }
                            className="object-cover w-10 h-10  rounded-md"
                          />
                        );
                      },
                    },

                    {
                      name: "Auteurs",
                      selector: "created_at",
                      cell: (row: ScienceRequest) => {
                        return !isUndefined(row.authors) ? (
                          row.authors!.map((author) => {
                            return (
                              <span className="bg-blue-100 text-blue-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
                                {author}
                              </span>
                            );
                          })
                        ) : (
                          <>Aucun</>
                        );
                      },
                    },
                    {
                      name: "Date de publication",
                      selector: "created_at",
                      cell: (row: ScienceRequest) => {
                        return (
                          <span> {formatDateMMMddyyy(row.created_at)} </span>
                        );
                      },
                    },
                    {
                      name: "Actions",
                      selector: "runtime",
                      right: true,
                      cell: (row: ScienceRequest) => (
                        <div className="flex flex-row gap-2 ">
                          <Button
                            onClick={() => {
                              setShowAlert(true);
                              setArticleId(row.id);
                            }}
                            color="danger"
                            className="h-8"
                            type="button"
                            icon={<TrashSVG stroke="#ffff" />}
                          />
                        </div>
                      ),
                    },
                  ]}
                  data={_articles}
                  defaultSortField="title"
                  sortIcon={<FaSort />}
                  pagination
                />
              </div>
            )}
          </>
        )}
      </Page>
      <AlertConfirmation
        description={`La suppression de cette magazine elle n'apparaitra plus sur le site`}
        title="Veuillez confirmer s'il vous plait...!"
        onShow={() => setShowAlert((alert) => !alert)}
        show={_showAlert}
        loading={_loader}
        onConfirm={() => _removeArticle(_articleId!)}
      />
    </>
  );
}
