interface Property {
  classname?: string;
  color?: string;
  width?: string;
  height?: string;
}

export function AddSVG({
  classname,
  color = "white",
  width = "20",
  height = "20",
}: Property) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33325 10H16.6666"
        stroke={color}
        strokeWidth="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 16.6667V3.33334"
        stroke={color}
        strokeWidth="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
