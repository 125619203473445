import { ArticleResponse } from "../../app/interfaces/interfaces";
import { Blockright } from "../layouts/blockRight";
import { NewsCard } from "./NewsCard";

interface BlockNewsProps {
  title: string;
  data: ArticleResponse[];
}

export function BlockNews({ title, data }: BlockNewsProps) {
  const shuffle = (arr: any[]) => [...arr].sort(() => Math.random() - 0.5);
  return (
    <Blockright title={title}>
      {shuffle(data)
        .slice(0, 4)
        .map((item, index) => {
          return <NewsCard key={index} magazine={item} />;
        })}
    </Blockright>
  );
}
