import {
  BusinessSVG,
  InnovationSVG,
  PortraitSVG,
  SanteSVG,
  ScienceSVG,
  SocieteSVG,
} from "../../site/components";
import { CarouselInterface, MenuInterface } from "./interfaces";
export const menu: MenuInterface[] = [
  {
    name: "Accueil",
    href: "/",
    icon: "",
  },
  {
    name: "Santé",
    href: "/actu-sante",
    icon: SanteSVG,
  },
  {
    name: "Innovation",
    href: "/innovation",
    icon: InnovationSVG,
  },
  {
    name: "Portrait",
    href: "/portrait",
    icon: PortraitSVG,
  },
  {
    name: "Business",
    href: "/business",
    icon: BusinessSVG,
  },
  {
    name: "Science",
    href: "/science",
    icon: ScienceSVG,
  },
  {
    name: "Société",
    href: "/societe",
    icon: SocieteSVG,
  },
  {
    name: "eMagazine",
    href: "/e-magazine",
    icon: "",
  },
  {
    name: "A propos",
    href: "/about-us",
    icon: "",
  },
  {
    name: "Contactez nous",
    href: "#",
    icon: "",
  },
];

export const menuMobile: MenuInterface[] = [
  {
    name: "Accueil",
    href: "/",
    icon: "",
  },
  {
    name: "Santé",
    href: "/actu-sante",
    icon: SanteSVG,
  },
  {
    name: "Innovation",
    href: "/innovation",
    icon: InnovationSVG,
  },
  {
    name: "Portrait",
    href: "/portrait",
    icon: PortraitSVG,
  },
  {
    name: "Business",
    href: "/business",
    icon: BusinessSVG,
  },
];

export const carousel: CarouselInterface[] = [
  {
    id: "1",
    title: "Pfizer et Friendsof Cancer Patients collaborent à nouveau",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum",
    url: "../assets/img/rectangle-19.png",
  },
  {
    id: "2",
    title: "Friendsof Cancer Patients collaborent à nouveau",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
    url: "../assets/img/rectangle-19.png",
  },
  {
    id: "3",
    title: "Patients collaborent à nouveau",
    description:
      "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum",
    url: "../assets/img/rectangle-19.png",
  },
  {
    id: "4",
    title: "Et Friendsof Cancer Patients collaborent à nouveau",
    description:
      "Eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum",
    url: "../assets/img/rectangle-19.png",
  },
  {
    id: "5",
    title: "Collaborent à nouveau",
    description:
      "Incididunt ut labore et dolore magna aliqua. Lorem ipsum Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum",
    url: "../assets/img/rectangle-19.png",
  },
];

export const tricksMocks = [1, 2, 3, 4];
export const blockNews1 = [1, 2, 3];
export const blockNews = [1, 2, 3, 4, 5, 6, 7];
