import { initializeApp } from "firebase/app";
import {
  getAuth,
  onAuthStateChanged,
  signOut,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  NextOrObserver,
  User,
} from "firebase/auth";
import { getFirebaseConfig } from "./firebase-config";
import {
  getFirestore,
  CollectionReference,
  collection,
  DocumentData,
  serverTimestamp,
} from "firebase/firestore";
import { getStorage } from "firebase/storage";

const app = initializeApp(getFirebaseConfig());
const auth = getAuth(app);
const firestore = getFirestore();

export const timestamp = serverTimestamp();

export const signInUser = async (email: string, password: string) => {
  if (!email && !password) return;

  return await signInWithEmailAndPassword(auth, email, password);
};

export const signUpUser = async (email: string, password: string) => {
  if (!email && !password) return;

  return await createUserWithEmailAndPassword(auth, email, password);
};

export const userStateListener = (callback: NextOrObserver<User>) => {
  return onAuthStateChanged(auth, callback);
};

export const SignOutUser = async () => await signOut(auth);

export const createCollection = <T = DocumentData>(collectionName: string) => {
  return collection(firestore, collectionName) as CollectionReference<T>;
};

export const simpleCollection = (collectionname: string) => {
  return collection(firestore, collectionname);
};

export const storage = getStorage(app);
