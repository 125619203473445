interface Property {
  stroke: string;
  classname?: string;
}

export function SwitchSVG({ stroke, classname }: Property) {
  return (
    <svg
      width="14"
      height="14"
      fill="none"
      stroke={stroke}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        strokeWidth="2"
        d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
      ></path>
    </svg>
  );
}
