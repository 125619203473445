import {
  AddSVG,
  Button,
  EditSVG,
  Input,
  Modal,
  Spinner,
  TrashSVG,
} from "../../components";
import { Page } from "../../layouts/page";
import DataTable from "react-data-table-component";
import { FaSort } from "react-icons/fa";
import React from "react";
import { isEmpty, isNil, lowerCase } from "lodash";
import AlertConfirmation from "../../components/AlertConfirmation";
import { ActionType } from "../../interfaces/enum";
import { RubricResponse } from "../../interfaces/interfaces";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { formatDateMMMddyyy } from "../../../utils/utils";
import { v4 as uuid } from "uuid";
import {
  deleteRubric,
  getRubrics,
  setDbRubric,
  updateRubric,
} from "../../core/firebase/firestore-collection";

export default function RubricsPage() {
  const [_isLoading, setLoading] = React.useState<boolean>(false);
  const [_isLoader, setLoader] = React.useState<boolean>(false);
  const [_show, setShow] = React.useState<boolean>(false);
  const [_showAlert, setShowAlert] = React.useState<boolean>(false);
  const [_action, setAction] = React.useState<ActionType>(ActionType.POST);
  const [_rubrics, seRubrics] = React.useState<RubricResponse[]>([]);
  const [_searchRubric, setSearchRubric] = React.useState<string>();
  const [_rubricTitle, setChangeTitle] = React.useState<string>();

  const [_rubricId, setRubricId] = React.useState<string>();

  const [form, _setFormReference] = React.useState<HTMLFormElement | null>(
    null
  );

  const handleButtonClick = (id: string, title: string, action?: boolean) => {
    if (action) {
      setChangeTitle(title);
      setAction(ActionType.PATCH);
      setShow(true);
    } else {
      setShowAlert(true);
    }
    setRubricId(id);
  };

  const _confirmForm = React.useCallback(() => {
    if (!isNil(form)) {
      form.requestSubmit();
    }
  }, [form]);

  const _findRubrics = React.useMemo(() => {
    if (isEmpty(_searchRubric)) return _rubrics;
    return _rubrics.filter((item) => {
      return lowerCase(item.name).includes(lowerCase(_searchRubric));
    });
  }, [_searchRubric, _rubrics]);

  const fecthRubrics = React.useCallback(async () => {
    seRubrics([]);
    setLoader(true);
    (await getRubrics()).forEach((item) => {
      const rubric = item.data() as RubricResponse;
      seRubrics((old) => [...old!, rubric]);
    });
    setLoader(false);
  }, []);

  React.useEffect(() => {
    fecthRubrics();
  }, [fecthRubrics]);

  const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    const target = e.target as typeof e.target & {
      title: { value: string };
      id: { value: string };
    };
    const id: string = uuid();
    if (_action === ActionType.POST) {
      try {
        await setDbRubric(id, {
          id: id,
          name: target.title.value,
          created_at: new Date().toString(),
        }).then(async () => {
          toast("Rubrique créer", { type: "success" });
          setShow(false);
          await fecthRubrics();
        });
      } catch (error: any) {
        toast(error.message, { type: "error" });
      }
    } else {
      try {
        await updateRubric(_rubricId!, {
          name: target.title.value,
        }).then(async () => {
          toast("Rubrique mise a jour", { type: "success" });
          setShow(false);
          await fecthRubrics();
        });
      } catch (error: any) {
        toast(error.message, { type: "error" });
      }
    }
    setLoading((loader) => !loader);
  };

  const _removeRubric = React.useCallback(
    async (id: string) => {
      setLoading(true);
      try {
        await deleteRubric(id).then(async () => {
          toast("Rubric supprimer", { type: "success" });
          await fecthRubrics();
        });
      } catch (error: any) {
        toast(error.message, { type: "error" });
      }
      setLoading((loader) => !loader);
      setShowAlert((alert) => !alert);
    },
    [fecthRubrics]
  );

  return (
    <>
      <ToastContainer />
      <Page
        left={
          <h1 className="font-bold text-2xl text-background-100">Rubriques</h1>
        }
      >
        {_isLoader ? (
          <Spinner />
        ) : (
          <>
            <div className="flex sm:flex-row flex-col sm:gap-0 gap-5 justify-between mt-5">
              <div className="flex flex-row ">
                <Input
                  block
                  type="search"
                  placeholder="Chercher une rubriques"
                  onChange={(e) => setSearchRubric(e.target.value)}
                  id="title"
                  name="title"
                  className="md:w-60"
                />
              </div>
              <div>
                <Button
                  color="primary"
                  type="button"
                  icon={<AddSVG />}
                  title="Nouvelle Rubrique"
                  className="w-auto"
                  onClick={() => {
                    setShow(true);
                    setAction(ActionType.POST);
                  }}
                />
              </div>
            </div>

            <div className="">
              {isEmpty(_rubrics) ? (
                <div className="bg-white py-12 flex mt-8 items-center justify-center w-full">
                  <p>No Data</p>
                </div>
              ) : (
                <div className=" -mx-4 mt-8 ring-1 bg-white border-0 ring-white sm:-mx-6 md:mx-0 md:rounded-xl">
                  <DataTable
                    title="Rubriques"
                    columns={[
                      {
                        name: "Ref.",
                        selector: "id",
                      },
                      {
                        name: "Titre",
                        selector: "name",
                      },
                      {
                        name: "Créer le",
                        selector: "created_at",
                        cell: (row: RubricResponse) => {
                          return (
                            <span> {formatDateMMMddyyy(row.created_at!)} </span>
                          );
                        },
                      },
                      {
                        name: "Actions",
                        selector: "runtime",
                        right: true,
                        cell: (row) => (
                          <div className="flex flex-row gap-2 h-8">
                            <Button
                              onClick={() =>
                                handleButtonClick(row.id, row.name, true)
                              }
                              color="success"
                              className="h-8"
                              type="button"
                              icon={<EditSVG stroke="#ffff" />}
                            />
                            <Button
                              onClick={() =>
                                handleButtonClick(row.id, row.name)
                              }
                              color="danger"
                              className="h-8"
                              type="button"
                              icon={<TrashSVG stroke="#ffff" />}
                            />
                          </div>
                        ),
                      },
                    ]}
                    data={_findRubrics!}
                    defaultSortField="title"
                    sortIcon={<FaSort />}
                    pagination
                  />
                </div>
              )}
            </div>
          </>
        )}
      </Page>

      <AlertConfirmation
        description={`Supprimer la rubrique ${_rubricTitle}. Cette opération supprime également tous les états associés, y compris les articles dans la rubrique.`}
        title="Veuillez confirmer s'il vous plait...!"
        onShow={() => setShowAlert(!_showAlert)}
        show={_showAlert}
        loading={_isLoading}
        onConfirm={() => _removeRubric(_rubricId!)}
      />
      <Modal
        show={_show}
        loading={_isLoading}
        onShow={() => setShow(!_show)}
        onConfirm={_confirmForm}
        title={
          _action === ActionType.POST
            ? "Ajouter une rubrique"
            : "Modifier une rubrique"
        }
      >
        <form
          className="mt-8 space-y-3"
          ref={_setFormReference}
          onSubmit={onFormSubmit}
        >
          <div className="w-full">
            <label className="block text-sm font-mediumtext-gray-700 mb-1">
              Nom
            </label>
            <Input
              block
              type="hidden"
              name="id"
              defaultValue={_rubricId}
              hidden={true}
            />
            <Input
              block
              type="text"
              name="title"
              defaultValue={_action === ActionType.PATCH ? _rubricTitle : ""}
              placeholder="Santé"
              required={true}
              disabled={_isLoading}
            />
          </div>
        </form>
      </Modal>
    </>
  );
}
