import { getDownloadURL } from "firebase/storage";
import { isEmpty, isNil } from "lodash";
import React from "react";
import { toast, ToastContainer } from "react-toastify";
import { showAlertEmagazine } from "../../../redux/eMagazine/actionEmagazine";
import { getRandomFileName } from "../../../utils/utils";
import {
  AddSVG,
  Button,
  Input,
  Modal,
  Spinner,
  TrashSVG,
} from "../../components";
import AlertConfirmation from "../../components/AlertConfirmation";
import {
  MAGAZINES_COVER_PATH,
  MAGAZINES_File_PATH,
} from "../../core/firebase/constantes";
import {
  deleteImage,
  deleteMagazine,
  getMagazine,
  setDbMagazine,
  uploadImage,
} from "../../core/firebase/firestore-collection";
import { Page } from "../../layouts/page";
import { v4 as uuid } from "uuid";
import { MagazineRequest } from "../../interfaces/interfaces";
import { Link } from "react-router-dom";

export default function Emagazine() {
  const [_showAlert, setShowAlert] = React.useState<boolean>(false);
  const [_isLoading, setLoading] = React.useState<boolean>(false);
  const [_isLoader, setLoader] = React.useState<boolean>(false);
  const [_magazine, setMagazine] = React.useState<MagazineRequest>();
  const [_show, setShow] = React.useState<boolean>(false);
  const [_magazines, setMagazines] = React.useState<MagazineRequest[]>([]);

  const [form, _setFormReference] = React.useState<HTMLFormElement | null>(
    null
  );

  const _confirmForm = React.useCallback(() => {
    if (!isNil(form)) {
      form.requestSubmit();
    }
  }, [form]);
  const fecthMagazines = React.useCallback(async () => {
    setMagazines([]);
    setLoader(true);
    (await getMagazine()).forEach((item) => {
      const magazine = item.data() as MagazineRequest;
      setMagazines((old) => [...old!, magazine]);
    });
    setLoader(false);
  }, []);

  const onFormSubmit = React.useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();

      //@ts-ignore
      const fileCover = e.target[0]?.files[0];

      //@ts-ignore
      const file = e.target[1]?.files[0];

      const coverFileName = getRandomFileName(fileCover.name);
      const documentFileName = getRandomFileName(file.name);

      const uploadCoverTask = uploadImage(
        MAGAZINES_COVER_PATH,
        coverFileName,
        fileCover
      );
      const uploadFileTask = uploadImage(
        MAGAZINES_File_PATH,
        documentFileName,
        file
      );
      const id: string = uuid();

      uploadFileTask.on(
        "state_changed",
        (snapshot) => {
          setLoading(true);
        },
        (error: any) => toast(error.message, { type: "error" }),
        () => {
          getDownloadURL(uploadFileTask.snapshot.ref).then(
            async (documentUrl: string) => {
              uploadCoverTask.on(
                "state_changed",
                (snapshot) => {},
                (error: any) => toast(error.message, { type: "error" }),
                () => {
                  getDownloadURL(uploadCoverTask.snapshot.ref).then(
                    async (urlCover: string) => {
                      try {
                        await setDbMagazine(id, {
                          id: id,
                          coverUrl: urlCover,
                          documentUrl: documentUrl,
                          fileName: documentFileName,
                          created_at: new Date().toString(),
                        }).then(async () => {
                          setShow(false);
                          await fecthMagazines();
                          toast("Portrait créer", { type: "success" });
                        });
                      } catch (error: any) {
                        toast(error.message, { type: "error" });
                      }
                      setLoading(false);
                    }
                  );
                }
              );
            }
          );
        }
      );
    },
    [fecthMagazines]
  );

  const onDeleteEmagazine = React.useCallback(async () => {
    try {
      setLoading(true);
      await deleteMagazine(_magazine?.id!).then(async () => {
        toast("Magazine supprimer", { type: "success" });
        await deleteImage(MAGAZINES_File_PATH, _magazine?.fileName!);
        await fecthMagazines();
      });
    } catch (error: any) {
      toast(error.message, { type: "error" });
    }
    setLoading(false);
    setShowAlert(false);
  }, [_magazine?.fileName, _magazine?.id, fecthMagazines]);

  React.useEffect(() => {
    fecthMagazines();
  }, [fecthMagazines]);

  return (
    <>
      <ToastContainer />
      <Page
        left={
          <h1 className="font-bold text-2xl text-background-100">E-Magazine</h1>
        }
      >
        {_isLoader ? (
          <Spinner />
        ) : (
          <>
            <div className="flex sm:flex-row flex-col sm:gap-0 gap-5 justify-between mt-5">
              <div className="flex flex-row "></div>
              <div>
                <Button
                  color="primary"
                  type="button"
                  icon={<AddSVG />}
                  title="Nouveau"
                  className="w-auto"
                  onClick={() => {
                    setShow(true);
                  }}
                />
              </div>
            </div>

            <div className="">
              {isEmpty(_magazines) ? (
                <div className="bg-white py-12 flex mt-8 items-center justify-center w-full">
                  <p>No Data</p>
                </div>
              ) : (
                <div className=" -mx-4 mt-8 ring-1 bg-white border-0 ring-white sm:-mx-6 md:mx-0 md:rounded-xl">
                  <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-4 justify-center items-center gap-y-4 p-4 -mx-4 ring-1 bg-white border-0 ring-white sm:-mx-6 md:mx-0 md:rounded-xl">
                    {_magazines.map((v) => {
                      return (
                        <div className="h-70 w-60">
                          <div className="relative">
                            <Link to={v.documentUrl} target="_blank">
                              <img
                                src={v.coverUrl}
                                alt=""
                                className="h-70 w-60"
                              />
                            </Link>
                            <div className="absolute -top-2 -right-2">
                              <Button
                                onClick={() => {
                                  setShowAlert(true);
                                  setMagazine(v);
                                }}
                                color="danger"
                                className="h-8"
                                type="button"
                                icon={<TrashSVG stroke="#ffff" />}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </Page>
      <AlertConfirmation
        description={`Supprimer le magazine`}
        title="Veuillez confirmer s'il vous plait...!"
        onShow={() => showAlertEmagazine(!_showAlert)}
        show={_showAlert}
        loading={_isLoading}
        onConfirm={() => onDeleteEmagazine()}
      />
      <Modal
        show={_show}
        loading={_isLoading}
        onShow={() => setShow(!_show)}
        onConfirm={_confirmForm}
        title={"Ajouter un Magazine"}
      >
        <form
          className="mt-8 space-y-3"
          ref={_setFormReference}
          onSubmit={onFormSubmit}
        >
          <div className="w-full">
            <label className="block text-sm font-mediumtext-gray-700 mb-1">
              Image de couverture
            </label>
            <Input block type="file" accept=".jpef, .png, .jpg" />
          </div>
          <div className="w-full">
            <label className="block text-sm font-mediumtext-gray-700 mb-1">
              Document
            </label>
            <Input block type="file" accept=".pdf, .docx" />
          </div>
        </form>
      </Modal>
    </>
  );
}
