const config = {
  apiKey: "AIzaSyD8NL1ZyAAa80w0UXvK8KWpg_Hzsa1PtuE",
  authDomain: "all-test-app-915b4.firebaseapp.com",
  projectId: "all-test-app-915b4",
  storageBucket: "all-test-app-915b4.appspot.com",
  messagingSenderId: "1071462145896",
  appId: "1:1071462145896:web:2046d2ebc34544ce01c0dd",
};

export function getFirebaseConfig() {
  if (!config || !config.apiKey) {
    throw new Error(
      "No Firebase configuration object provided. \n" +
        "Add your web app's configuration object to firebase-config.ts"
    );
  } else {
    return config;
  }
}
