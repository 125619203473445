import { ActionType } from "../../app/interfaces/enum";
import { RubricResponse } from "../../app/interfaces/interfaces";
import {
  ACTION_LOADING,
  ACTION_RUBRIC_TYPE,
  MESSAGE_RUBRIC_ERROR,
  CREATE_RUBRIC_SUCCESS,
  DELETE_RUBRIC_SUCCESS,
  GET_ALL_RUBRICS_ERROR,
  GET_ALL_RUBRICS_SUCCESS,
  RUBRIC_LOADING,
  SEARCH_RUBRIC_INPUT,
  SHOW_ALERT_RUBRIC,
  SHOW_RUBRIC_MODAL,
  TITLE_RUBRIC_INPUT,
  UPDATE_RUBRIC_SUCCESS,
} from "../types/types";

export interface StateRubric {
  title: string;
  search: string;
  isLoading: boolean;
  actionLoading: boolean;
  showModal: boolean;
  showAlert: boolean;
  error: string;
  rubrics: RubricResponse[];
  messageError: string;
  action: ActionType;
}

const initialStateRubric: StateRubric = {
  title: "",
  search: "",
  isLoading: false,
  actionLoading: false,
  showModal: false,
  showAlert: false,
  error: "",
  rubrics: [],
  messageError: "",
  action: ActionType.POST,
};

export const reducerRubric = (state = initialStateRubric, action: any) => {
  switch (action.type) {
    case RUBRIC_LOADING:
      return {
        ...state,
        isLoading: action.payload,
        error: "",
      };
    case TITLE_RUBRIC_INPUT:
      return {
        ...state,
        title: action.payload,
      };
    case SEARCH_RUBRIC_INPUT:
      return {
        ...state,
        search: action.payload,
      };
    case GET_ALL_RUBRICS_SUCCESS:
      return {
        ...state,
        rubrics: action.payload,
        isLoading: false,
      };
    case GET_ALL_RUBRICS_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case SHOW_RUBRIC_MODAL:
      return {
        ...state,
        showModal: action.payload,
        error: "",
      };
    case ACTION_RUBRIC_TYPE:
      return {
        ...state,
        action: action.payload,
        error: "",
      };
    case ACTION_LOADING:
      return {
        ...state,
        actionLoading: action.payload,
        error: "",
      };
    case CREATE_RUBRIC_SUCCESS:
      return {
        ...state,
        rubrics: [...state.rubrics, action.payload],
        messageError: "",
        actionLoading: false,
      };
    case MESSAGE_RUBRIC_ERROR:
      return {
        ...state,
        messageError: "",
        actionLoading: false,
      };
    case SHOW_ALERT_RUBRIC:
      return {
        ...state,
        showAlert: action.payload,
        createError: "",
      };
    case DELETE_RUBRIC_SUCCESS:
      const copy = [...state.rubrics];
      const index = copy.findIndex((element) => element.id === action.payload);
      if (index > -1) {
        copy.splice(index, 1);
      }
      return {
        ...state,
        rubrics: copy,
        createError: "",
        actionLoading: false,
      };
    case UPDATE_RUBRIC_SUCCESS:
      const copyUpdate = [...state.rubrics];
      const item = copyUpdate.find(
        (element) => element.id === action.payload.id
      );
      const i = copyUpdate.indexOf(item!);
      copyUpdate[i] = action.payload;
      return {
        ...state,
        rubrics: copyUpdate,
        createError: "",
        actionLoading: false,
      };
    default:
      return state;
  }
};
