export function ScienceSVG({ ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.5 8.6665C17.0353 8.6665 16.6373 8.93984 16.45 9.33317H15V10.3332H16.45C16.6377 10.7265 17.0357 10.9998 17.5 10.9998C18.1447 10.9998 18.6667 10.4778 18.6667 9.83317C18.6667 9.18884 18.1447 8.6665 17.5 8.6665Z"
        fill="currentColor"
      />
      <path
        d="M17.1667 13.0001C16.702 13.0001 16.304 13.2734 16.1167 13.6668H15.6097C15.5657 13.6668 15.523 13.6488 15.4917 13.6181L14.293 12.4194C14.1017 12.7091 13.8823 12.9644 13.651 13.1918L14.7843 14.3248C15.005 14.5454 15.298 14.6668 15.6093 14.6668H16.1163C16.304 15.0601 16.702 15.3334 17.1663 15.3334C17.811 15.3334 18.333 14.8114 18.333 14.1668C18.3333 13.5224 17.8113 13.0001 17.1667 13.0001Z"
        fill="currentColor"
      />
      <path
        d="M15.4917 6.38184C15.5227 6.35117 15.5653 6.33317 15.6093 6.33317H16.1163C16.304 6.7265 16.702 6.99984 17.1663 6.99984C17.811 6.99984 18.333 6.47784 18.333 5.83317C18.333 5.18884 17.811 4.6665 17.1663 4.6665C16.7017 4.6665 16.3037 4.93984 16.1163 5.33317H15.6093C15.2977 5.33317 15.005 5.4545 14.7843 5.67484L13.654 6.80517C13.8927 7.0385 14.107 7.29684 14.2947 7.5785L15.4917 6.38184Z"
        fill="currentColor"
      />
      <path
        d="M10.714 12.8272C11.146 13.0545 11.5997 13.3062 12.0447 13.5945C13.2873 12.8145 14.3333 11.7832 14.3333 10.0002C14.3333 7.54083 12.3457 6.50883 10.592 5.5985C9.51534 5.0395 8.47567 4.49616 7.79401 3.66683H12.2073C11.805 4.1565 11.2783 4.5465 10.6917 4.89783C10.7607 4.93416 10.828 4.96983 10.899 5.0065C11.2633 5.1955 11.6453 5.39516 12.0237 5.61416C13.287 4.71516 14.3333 3.4555 14.3333 1.3335H13C13 1.8405 12.92 2.27883 12.7793 2.66683H7.22234C7.08201 2.2785 7.00001 1.84083 7.00001 1.3335H5.66667C5.66667 4.5435 8.05701 5.78483 9.97801 6.78183C11.0193 7.3225 11.8357 7.77216 12.3563 8.3335H7.64534C8.03634 7.91216 8.59267 7.5535 9.28634 7.17316C8.85434 6.94583 8.40067 6.69416 7.95567 6.40583C6.71267 7.18583 5.66667 8.21716 5.66667 10.0002C5.66667 12.4595 7.65434 13.4915 9.40801 14.4018C10.4847 14.9608 11.5243 15.5042 12.206 16.3335H7.79267C8.19567 15.8438 8.72167 15.4538 9.30834 15.1025C9.23934 15.0662 9.172 15.0305 9.10101 14.9938C8.73667 14.8048 8.35467 14.6052 7.97634 14.3862C6.71301 15.2852 5.66667 16.5448 5.66667 18.6668H7.00001C7.00001 18.1598 7.08001 17.7215 7.22067 17.3335H12.7777C12.918 17.7218 13 18.1595 13 18.6668H14.3333C14.3333 15.4568 11.943 14.2155 10.022 13.2185C8.98067 12.6778 8.16467 12.2282 7.64367 11.6668H12.3547C11.964 12.0882 11.4077 12.4468 10.714 12.8272ZM7.08401 10.6668C7.03067 10.4625 7.00001 10.2432 7.00001 10.0002C7.00001 9.75783 7.02867 9.5375 7.08167 9.3335H12.916C12.9693 9.53783 13 9.75716 13 10.0002C13 10.2425 12.9713 10.4628 12.9183 10.6668H7.08401Z"
        fill="currentColor"
      />
      <path
        d="M2.83334 6.99984C3.29801 6.99984 3.69601 6.7265 3.88334 6.33317H4.39034C4.43434 6.33317 4.47701 6.35117 4.50834 6.38184L5.70501 7.5785C5.89267 7.2965 6.10701 7.0385 6.34567 6.80517L5.21534 5.67484C4.99501 5.4545 4.70201 5.33317 4.39067 5.33317H3.88334C3.69567 4.93984 3.29767 4.6665 2.83334 4.6665C2.18867 4.6665 1.66667 5.18884 1.66667 5.83317C1.66667 6.47784 2.18867 6.99984 2.83334 6.99984Z"
        fill="currentColor"
      />
      <path
        d="M2.49999 10.9998C2.96466 10.9998 3.36266 10.7265 3.54999 10.3332H4.99999V9.33317H3.54999C3.36233 8.93984 2.96433 8.6665 2.49999 8.6665C1.85533 8.6665 1.33333 9.18884 1.33333 9.83317C1.33333 10.4778 1.85533 10.9998 2.49999 10.9998Z"
        fill="currentColor"
      />
      <path
        d="M5.70701 12.4194L4.50834 13.6181C4.47734 13.6488 4.43467 13.6668 4.39067 13.6668H3.88334C3.69567 13.2734 3.29767 13.0001 2.83334 13.0001C2.18867 13.0001 1.66667 13.5224 1.66667 14.1668C1.66667 14.8114 2.18867 15.3334 2.83334 15.3334C3.298 15.3334 3.696 15.0601 3.88334 14.6668H4.39034C4.702 14.6668 4.99467 14.5454 5.21534 14.3251L6.34867 13.1921C6.118 12.9644 5.89834 12.7091 5.70701 12.4194Z"
        fill="currentColor"
      />
    </svg>
  );
}
