import Content from "./content";
import classNames from "classnames";

interface PageProps {
  className?: string;
  left?: React.ReactNode;
  right?: React.ReactNode;
}

export function Page({
  className,
  left,
  right,
  children,
}: React.PropsWithChildren<PageProps>) {
  return (
    <Content>
      <div
        className={classNames(
          className,
          "pb-6 w-full h-full px-6 py-4 mx-auto"
        )}
      >
        <div className="w-full">
          <div className="flex flex-row justify-between">
            {left}
            {right}
          </div>

          {children}
        </div>
      </div>
    </Content>
  );
}
