import { toast } from "react-toastify";
import { api } from "../../app/core/api/api";
import { ActionType } from "../../app/interfaces/enum";
import { ApiError, EmagazineResponse } from "../../app/interfaces/interfaces";
import {
  EMAGAZINE_ACTION_LOADING,
  ACTION_EMAGAZINE_TYPE,
  MESSAGE_EMAGAZINE_ERROR,
  CREATE_EMAGAZINE_SUCCESS,
  DELETE_EMAGAZINE_SUCCESS,
  GET_ALL_EMAGAZINES_ERROR,
  GET_ALL_EMAGAZINES_SUCCESS,
  EMAGAZINE_LOADING,
  SEARCH_EMAGAZINE_INPUT,
  SHOW_ALERT_EMAGAZINE,
  SHOW_EMAGAZINE_MODAL,
  TITLE_EMAGAZINE_INPUT,
  UPDATE_EMAGAZINE_SUCCESS,
} from "../types/types";

export function isLoading(isload: boolean) {
  return {
    type: EMAGAZINE_LOADING,
    payload: isload,
  };
}

export function onChangeFindEmagazine(search: string) {
  return {
    type: SEARCH_EMAGAZINE_INPUT,
    payload: search,
  };
}

export function onChangeTitle(title: string) {
  return {
    type: TITLE_EMAGAZINE_INPUT,
    payload: title,
  };
}

export function getEmagazinesSuccess(eMagazine: EmagazineResponse[]) {
  return {
    type: GET_ALL_EMAGAZINES_SUCCESS,
    payload: eMagazine,
  };
}

export function getEmagazinesError(error: string) {
  return {
    type: GET_ALL_EMAGAZINES_ERROR,
    payload: error,
  };
}

export function showEmagazineModal(show: boolean) {
  return {
    type: SHOW_EMAGAZINE_MODAL,
    payload: show,
  };
}

export function showAlertEmagazine(show: boolean) {
  return {
    type: SHOW_ALERT_EMAGAZINE,
    payload: show,
  };
}

export function actionEmagazine(action: ActionType) {
  return {
    type: ACTION_EMAGAZINE_TYPE,
    payload: action,
  };
}

export function actionLoading(isload: boolean) {
  return {
    type: EMAGAZINE_ACTION_LOADING,
    payload: isload,
  };
}

export function createEmagazinesSuccess(emagazine: EmagazineResponse) {
  return {
    type: CREATE_EMAGAZINE_SUCCESS,
    payload: emagazine,
  };
}

export function messageEmagazinesError(error: string) {
  return {
    type: MESSAGE_EMAGAZINE_ERROR,
    payload: error,
  };
}

export function deleteEmagazinesSuccess(emagazineId: string) {
  return {
    type: DELETE_EMAGAZINE_SUCCESS,
    payload: emagazineId,
  };
}

export function updateEmagazinesSuccess(emagazine: EmagazineResponse) {
  return {
    type: UPDATE_EMAGAZINE_SUCCESS,
    payload: emagazine,
  };
}

export const getEmagazines = () => {
  return (dispatch: any) => {
    dispatch(isLoading(true));
    api.get(`/rubrics`).then(async (res) => {
      if (res.status === 200) {
        const eMagazine: EmagazineResponse[] = await res.json();
        dispatch(getEmagazinesSuccess(eMagazine));
      } else {
        dispatch(getEmagazinesError("No data"));
      }
    });
  };
};

export const onFormSubmit = (
  e: React.FormEvent<HTMLFormElement>,
  action: ActionType
) => {
  return (dispatch: any) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(actionLoading(true));
    const target = e.target as typeof e.target & {
      title: { value: string };
      id: { value: string };
    };

    if (action === ActionType.POST) {
      api
        .post<any>(
          `/rubrics`,
          JSON.stringify({
            name: target.title.value,
          })
        )
        .then(async (res) => {
          if (res.status === 201) {
            const emagazine: EmagazineResponse = await res.json();
            dispatch(createEmagazinesSuccess(emagazine));
            toast("Rubrique créer", { type: "success" });
            dispatch(showEmagazineModal(false));
          } else {
            const error: ApiError = await res.json();
            toast(error.message, { type: "error" });
            dispatch(messageEmagazinesError(error.message));
          }
        });
    } else {
      api
        .patch<any>(
          `/rubrics/${target.id.value}`,
          JSON.stringify({
            name: target.title.value,
          })
        )
        .then(async (res) => {
          if (res.status === 200) {
            const emagazine: EmagazineResponse = await res.json();
            dispatch(updateEmagazinesSuccess(emagazine));
            toast("Rubrique mise a jour", { type: "success" });
            dispatch(showEmagazineModal(false));
          } else {
            const error: ApiError = await res.json();
            toast(error.message, { type: "error" });
            dispatch(messageEmagazinesError(error.message));
          }
        });
    }
  };
};

export const deleteEmagazine = (id: string) => {
  return (dispatch: any) => {
    api.delete(`/rubrics/${id}`).then((res) => {
      if (res.status === 200) {
        dispatch(deleteEmagazinesSuccess(id));
        dispatch(showAlertEmagazine(false));
        toast("Rubrique supprimer", { type: "success" });
      }
    });
  };
};
