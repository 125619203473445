import { onSnapshot } from "firebase/firestore";
import { isEmpty } from "lodash";
import React from "react";
import DataTable from "react-data-table-component";
import { FaSort } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import { formatDateMMMddyyy } from "../../../utils/utils";
import { Button, Spinner, TrashSVG } from "../../components";
import AlertConfirmation from "../../components/AlertConfirmation";
import {
  getArticlesSpotlight,
  updateArticle,
} from "../../core/firebase/firestore-collection";
import { ArticleResponse } from "../../interfaces/interfaces";
import { Page } from "../../layouts/page";

export default function SpotlightPage() {
  const [_showAlert, setShowAlert] = React.useState<boolean>(false);
  const [_articleId, setArticleId] = React.useState<string>();
  const [_articles, setArticles] = React.useState<ArticleResponse[]>([]);
  const [_loaderArticle, setLoaderArticle] = React.useState<boolean>(false);

  const fecthArticles = React.useCallback(async () => {
    setArticles([]);
    setLoaderArticle(true);
    onSnapshot(await getArticlesSpotlight(), (querySnapshot) => {
      return querySnapshot.forEach((doc) => {
        setArticles((old) => [...old, doc.data() as ArticleResponse]);
      });
    });
    setLoaderArticle(false);
  }, []);

  React.useEffect(() => {
    fecthArticles();
  }, [fecthArticles]);

  const _handleUpdateSpotlightClick = React.useCallback(
    async (id: string, spotlight: boolean) => {
      try {
        await updateArticle(id!, {
          spotlight: !spotlight,
        }).then(async () => {
          toast("Opération effectuer", { type: "success" });
          await fecthArticles();
        });
      } catch (error: any) {
        toast(error.message, { type: "error" });
      }
    },
    [fecthArticles]
  );

  return (
    <>
      <ToastContainer />
      <Page
        left={
          <h1 className="font-bold text-2xl text-background-100">
            Magazines a la une
          </h1>
        }
      >
        {_loaderArticle ? (
          <Spinner />
        ) : (
          <>
            {isEmpty(_articles) ? (
              <div className="bg-white py-12 flex mt-8 items-center justify-center w-full">
                <p>No Data</p>
              </div>
            ) : (
              <div className=" -mx-4 mt-8 ring-1 bg-white border-0 ring-white sm:-mx-6 md:mx-0 md:rounded-xl">
                <DataTable
                  title="Liste des magazines"
                  columns={[
                    {
                      name: "Ref",
                      selector: "id",
                    },
                    {
                      name: "Titre",
                      selector: "articleTitle",
                      cell: (row: ArticleResponse) => {
                        return <span> {row.articleTitle} </span>;
                      },
                    },
                    {
                      name: "Photo de coverture",
                      selector: "articlePic",
                      cell: (row: ArticleResponse) => {
                        return (
                          <img
                            alt="profil"
                            src={
                              row.articlePic
                                ? row.articlePic
                                : "../assets/svg/logo.svg"
                            }
                            className="object-cover w-10 h-10  rounded-md"
                          />
                        );
                      },
                    },
                    {
                      name: "Auteur",
                      selector: "author",
                      cell: (row: ArticleResponse) => {
                        return <span> {row.author} </span>;
                      },
                    },
                    {
                      name: "Date de publication",
                      selector: "created_at",
                      cell: (row: ArticleResponse) => {
                        return (
                          <span> {formatDateMMMddyyy(row.created_at)} </span>
                        );
                      },
                    },
                    {
                      name: "Actions",
                      selector: "runtime",
                      right: true,
                      cell: (row: ArticleResponse) => (
                        <div className="flex flex-row gap-2 ">
                          <Button
                            onClick={() => {
                              setShowAlert(true);
                              setArticleId(row.id);
                            }}
                            color="danger"
                            className="h-8"
                            type="button"
                            icon={<TrashSVG stroke="#ffff" />}
                          />
                        </div>
                      ),
                    },
                  ]}
                  data={_articles}
                  defaultSortField="title"
                  sortIcon={<FaSort />}
                  pagination
                />
              </div>
            )}
          </>
        )}
      </Page>
      <AlertConfirmation
        title="Veuillez confirmer s'il vous plait...!"
        onShow={() => setShowAlert((alert) => !alert)}
        show={_showAlert}
        onConfirm={() => {
          _handleUpdateSpotlightClick(_articleId!, false);
          setShowAlert(false);
        }}
      />
    </>
  );
}
