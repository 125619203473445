interface Property {
  classname?: string;
  stroke?: string;
}

export function EditSVG({ classname, stroke = "757B82" }: Property) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.73512 2.10009L2.94595 7.16925C2.76512 7.36175 2.59012 7.74092 2.55512 8.00342L2.33928 9.89342C2.26345 10.5759 2.75345 11.0426 3.43012 10.9259L5.30845 10.6051C5.57095 10.5584 5.93845 10.3659 6.11928 10.1676L10.9085 5.09842C11.7368 4.22342 12.1101 3.22592 10.8209 2.00675C9.53762 0.799252 8.56345 1.22509 7.73512 2.10009Z"
        stroke={stroke}
        strokeWidth="1.2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.93579 2.94592C7.18662 4.55592 8.49329 5.78676 10.115 5.95009"
        stroke={stroke}
        strokeWidth="1.2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.75 12.8334H12.25"
        stroke={stroke}
        strokeWidth="1.2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
