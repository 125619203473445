import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Button } from ".";

interface ModalVideoProps {
  show: boolean;
  onShow: () => void;
}

export function ModalVideo({
  show,
  onShow,
  children,
}: React.PropsWithChildren<ModalVideoProps>) {
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" static={true} className="relative z-10" onClose={onShow}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed w-full inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full w-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg px-4 pt-5 pb-4 text-left w-2/4 transition-all sm:my-8 sm:p-6">
                <div className="e absolute op-2 right-0 flex justify-center items-center sm:block">
                  <Button
                    onClick={onShow}
                    color="primary"
                    className="h-8"
                    type="button"
                    icon={<XMarkIcon className="h-6 w-6" aria-hidden="true" />}
                  />
                </div>
                <div className="mt-2">{children}</div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
