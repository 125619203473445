import classNames from "classnames";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { menu, menuMobile } from "../../utils/mocks/mocks";
import { SearchSVG } from "../components";
import React from "react";
import { isEmpty } from "lodash";

export default function SubHeader() {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = React.useState<string>("");

  const handleKeywordKeyPress = React.useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        if (isEmpty(searchTerm)) {
          return;
        }
        navigate(`/search-result?key=${searchTerm}`);
      }
    },
    [navigate, searchTerm]
  );

  return (
    <>
      <header className="bg-white header-outer z-50">
        <div className="w-full md:container md:mx-auto flex flex-wrap p-5 flex-col md:flex-row md:items-center justify-between">
          {/* <a className="hidden md:flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
          <GridMenuSVG />
          <span className="ml-3 text-xl font-normal text-natural-75">Menu</span>
        </a> */}

          <nav className="md:mr-auto hidden md:flex flex-wrap items-center text-normal justify-start">
            {menu.map((element, index) => {
              return (
                <Link
                  key={index}
                  className={classNames(
                    element.href === location.pathname
                      ? "text-primary-100 font-medium"
                      : "text-natural-75",
                    "xl:mr-6 mr-3 lg:text-xl text-base cursor-pointer hover:text-primary-100"
                  )}
                  to={element.href}
                >
                  <div className="relative flex items-center gap-x-2">
                    {element.icon ? (
                      <element.icon
                        fill={
                          element.href === location.pathname
                            ? "text-primary-100"
                            : "text-natural-75 hover:text-primary-100"
                        }
                      />
                    ) : (
                      ""
                    )}
                    {element.name}
                    {element.href === location.pathname && (
                      <div className="absolute  -bottom-5 inset-x-0 m-auto w-0 h-0 border-l-[10px] border-l-transparent border-b-[10px] border-b-primary-100 border-r-[10px] border-r-transparent"></div>
                    )}
                  </div>
                </Link>
              );
            })}
          </nav>
          <nav className="md:hidden flex flex-row items-center text-normal justify-between md:justify-center">
            {menuMobile.map((element, index) => {
              return (
                <Link
                  key={index}
                  className={classNames(
                    element.href === location.pathname
                      ? "text-primary-100 font-normal"
                      : "text-natural-75",
                    "mr-4 text-base cursor-pointer hover:text-primary-100"
                  )}
                  to={element.href}
                >
                  <div className="relative flex flex-col items-center">
                    {element.icon ? (
                      <element.icon
                        fill={
                          element.href === location.pathname
                            ? "text-primary-100"
                            : "text-natural-75 hover:text-primary-100"
                        }
                      />
                    ) : (
                      ""
                    )}

                    {element.name}
                    {element.href === location.pathname && (
                      <div className="absolute  -bottom-5 inset-x-0 m-auto w-0 h-0 border-l-[10px] border-l-transparent border-b-[10px] border-b-primary-100 border-r-[10px] border-r-transparent"></div>
                    )}
                  </div>
                </Link>
              );
            })}
          </nav>
          <div className="relative hidden md:flex">
            <form className="search-container">
              <input
                id="search-box"
                type="text"
                className="search-box"
                value={searchTerm}
                onKeyDown={handleKeywordKeyPress}
                onChange={(event) => setSearchTerm(event.target.value)}
              />
              <label htmlFor="search-box">
                <span className="search-icon">
                  <SearchSVG />
                </span>
              </label>

              <input type="submit" id="search-submit" />
            </form>
          </div>
        </div>
      </header>
    </>
  );
}
