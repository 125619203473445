import React, { Fragment } from "react";
import {
  ArticleResponse,
  PortraitResponse,
  RubricResponse,
} from "../app/interfaces/interfaces";
import Magazine from "./blocks/magazine";
import Portraits from "./blocks/portraits";
import {
  Actuality,
  BlockNews,
  Business,
  Innovation,
  LastPost,
  LineScienceSVG,
  Science,
  ScienceCard,
  SectionTitle,
  SpotlightCard,
  TextDividerSVG,
  Tricks,
} from "./components";
import { LineHealth } from "./components/svg/lineHealth";
import Footer from "./layouts/footer";
import Header from "./layouts/header";
import SubHeader from "./layouts/subHeader";
import classNames from "classnames";
import { onSnapshot } from "firebase/firestore";
import {
  getArticles,
  getArticlesSpotlight,
  getPortraits,
  getRubrics,
} from "../app/core/firebase/firestore-collection";
import { isEmpty, isUndefined } from "lodash";
import { VideoBlock } from "./components/videoBlock";

export default function Home() {
  const [_isLoading, setLoading] = React.useState<boolean>(false);

  const [_spotlights, setSpotlight] = React.useState<ArticleResponse[]>([]);
  const [_portraits, setProtrait] = React.useState<PortraitResponse[]>([]);
  const [_articles, setArticles] = React.useState<ArticleResponse[]>([]);
  const [_rubrics, seRubrics] = React.useState<RubricResponse[]>([]);

  const fecthArticlesSpotlight = React.useCallback(async () => {
    setSpotlight([]);
    onSnapshot(await getArticlesSpotlight(), (querySnapshot) => {
      return querySnapshot.forEach((doc) => {
        setSpotlight((old) => [...old, doc.data() as ArticleResponse]);
      });
    });
  }, []);

  const fecthPortraits = React.useCallback(async () => {
    setProtrait([]);
    (await getPortraits()).forEach((item) => {
      const portrait = item.data() as PortraitResponse;
      setProtrait((old) => [...old!, portrait]);
    });
  }, []);

  const getRubric = React.useCallback(async () => {
    (await getRubrics()).forEach((item) => {
      const rubric = item.data() as RubricResponse;
      seRubrics((old) => [...old, rubric]);
    });
  }, []);

  const fecthArticles = React.useCallback(async () => {
    setArticles([]);
    setLoading(true);
    (await getArticles()).forEach((item) => {
      const article = item.data() as ArticleResponse;
      setArticles((old) => [...old, article]);
    });

    setLoading(false);
  }, []);

  React.useEffect(() => {
    getRubric();
    fecthArticlesSpotlight();
    fecthPortraits();
    fecthArticles();
  }, [fecthArticles, fecthArticlesSpotlight, fecthPortraits, getRubric]);

  return (
    <>
      <Header />
      <SubHeader />

      {_isLoading ? (
        <div className=" right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2 ">
          <div role="status">
            <svg
              aria-hidden="true"
              className="w-16 h-16 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-primary-100"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : !isEmpty(_articles) ? (
        <div className="m-0 text-base bg-body_background antialiased font-normal leading-default text-slate-500">
          <div className="bg-white  mt-2 w-full">
            <div className="md:container mx-auto w-full flex flex-wrap p-5 flex-col md:flex-row items-center">
              <div className="w-full">
                <div className="flex flex-nowrap flex-row w-full justify-between overflow-auto">
                  {_articles.slice(0, 3).map((element, i) => {
                    return (
                      <Fragment key={i}>
                        <LastPost magazine={element} />
                        {i === 3 && <TextDividerSVG key={i} height="50" />}
                      </Fragment>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="relative mt-6 md:mt-4 bg-white pt-4 px-0 md:px-10 md:container mx-auto flex-wrap flex-col items-center">
            <Actuality magazines={_articles} rubrics={_rubrics} />
            {/* <ActualityPaginate /> */}
            <SpotlightCard spotlight={_spotlights} />
          </div>
          {_articles.filter(
            (item) =>
              _rubrics.find((r) => r.id === item.rubricId)?.name === "Science"
          ).length > 0 && (
            <div className="md:container bg-primary-100 mt-4 mx-auto w-full flex flex-wrap p-5 flex-col md:flex-row items-center py-2 px-6">
              <div className="w-full">
                <div className="flex flex-col">
                  <div className="flex flex-row items-center gap-8">
                    <h1 className="text-4xl font-bold text-white">Science</h1>
                    <LineScienceSVG />
                  </div>
                </div>
                <div className="gap-x-4 md:gap-x-0 flex flex-nowrap flex-row py-4 overflow-auto overflow-x items-center justify-evenly">
                  {_articles
                    .filter(
                      (item) =>
                        _rubrics.find((r) => r.id === item.rubricId)?.name ===
                        "Science"
                    )
                    .slice(0, 5)
                    .map((item, i) => (
                      <Science key={i} article={item} />
                    ))}
                </div>
              </div>
            </div>
          )}
          <div
            className="md:container mt-5 mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center py-4"
            style={{ padding: "unset" }}
          >
            <div className="w-full relative flex flex-row gap-3">
              <div className="w-full xl:w-10/12">
                {_articles.filter(
                  (item) =>
                    _rubrics.find((r) => r.id === item.rubricId)?.name ===
                    "Santé"
                ).length > 0 && (
                  <div className="bg-white px-4 md:px-10 py-6">
                    <SectionTitle title="Santé" />
                    <div className="flex h-auto flex-col mt-16">
                      <div className="flex h-full flex-row gap-6 ml-10 ">
                        <div className="h-auto w-0.5 bg-primary-100"></div>
                        <div className="flex flex-col">
                          <h1 className="text-3xl font-bold text-natural-75">
                            Astuces
                          </h1>
                          <h1 className="text-3xl font-light text-natural-75">
                            Santé
                          </h1>
                        </div>
                      </div>
                    </div>

                    <div className="relative grid grid-rows-2 md:grid-cols-2 md:flex-row justify-between mt-20 gap-8">
                      {_articles
                        .filter(
                          (item) =>
                            _rubrics.find((r) => r.id === item.rubricId)
                              ?.name === "Santé"
                        )
                        .slice(0, 4)
                        .map((article, index) => {
                          return (
                            <div
                              key={index}
                              className={classNames(
                                index % 2 !== 0 && "md:-mt-24 mt-12"
                              )}
                            >
                              <Tricks
                                rubricName={
                                  _rubrics.find(
                                    (r) => r.id === article.rubricId
                                  )?.name!
                                }
                                magazine={article}
                                classnames={classNames(index >= 2 && "mt-8")}
                              />
                            </div>
                          );
                        })}

                      <div className="md:flex hidden -mt-4 w-full justify-center">
                        <LineHealth />
                      </div>
                      <a
                        href={`/actu-sante`}
                        className="w-34 md:w-auto md:absolute bottom-0 right-0 inline-flex gap-2 text-white items-center bg-primary-100 border-0 py-2 px-4 focus:outline-none hover:bg-primary-100 md:mt-0"
                      >
                        <span className="font-normal text-sm md:text-base">
                          Plus d'astuces
                        </span>
                        <svg
                          width="18"
                          height="16"
                          viewBox="0 0 18 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.6924 8.70711C18.0829 8.31658 18.0829 7.68342 17.6924 7.29289L11.3284 0.928932C10.9379 0.538408 10.3047 0.538408 9.91421 0.928932C9.52368 1.31946 9.52368 1.95262 9.91421 2.34315L15.5711 8L9.91421 13.6569C9.52368 14.0474 9.52368 14.6805 9.91421 15.0711C10.3047 15.4616 10.9379 15.4616 11.3284 15.0711L17.6924 8.70711ZM0.0147095 9H16.9853V7L0.0147095 7L0.0147095 9Z"
                            fill="white"
                          />
                        </svg>
                      </a>
                    </div>
                    <div className="hidden md:flex justify-center my-5">
                      <LineHealth />
                    </div>
                    <div>
                      <div className="flex h-auto flex-col mt-8">
                        <div className="flex h-full flex-row gap-6 ml-10 ">
                          <div className="h-auto w-0.5 bg-primary-100"></div>
                          <div className="flex flex-col">
                            <h1 className="text-3xl font-bold text-natural-75">
                              Actualité
                            </h1>
                            <h1 className="text-3xl font-light text-natural-75">
                              Santé
                            </h1>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col md:flex-row w-full mt-24 md:mt-20">
                        <div className="w-full grid grid-rows-2 md:grid-cols-2">
                          {_articles
                            .filter(
                              (item) =>
                                _rubrics.find((r) => r.id === item.rubricId)
                                  ?.name === "Santé"
                            )
                            .slice(0, 4)
                            .map((article, index) => {
                              return (
                                <div
                                  key={index}
                                  className={classNames(
                                    index % 2 !== 0 && "md:-mt-24 mt-12"
                                  )}
                                >
                                  <Tricks
                                    rubricName={
                                      _rubrics.find(
                                        (r) => r.id === article.rubricId
                                      )?.name!
                                    }
                                    type="white"
                                    magazine={article}
                                    classnames={classNames(
                                      index >= 2 && "mt-8"
                                    )}
                                  />
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {!isUndefined(
                  _articles.filter(
                    (item) =>
                      _rubrics.find((r) => r.id === item.rubricId)?.name ===
                      "Innovation"
                  )[0]
                ) && (
                  <Innovation
                    rubricName={
                      _rubrics.find(
                        (r) =>
                          r.id ===
                          _articles.filter(
                            (item) =>
                              _rubrics.find((r) => r.id === item.rubricId)
                                ?.name === "Innovation"
                          )[0].rubricId
                      )?.name!
                    }
                    magazine={
                      _articles.filter(
                        (item) =>
                          _rubrics.find((r) => r.id === item.rubricId)?.name ===
                          "Innovation"
                      )[0]
                    }
                  />
                )}
                {/* <InnovationGroup />
            <InnovationGroup /> */}
                <a
                  href="/innovation"
                  className="hidden md:flex flex-row items-center justify-center my-6 gap-2"
                >
                  <h6 className="text-primary-100 text-sm font-normal">
                    Toutes les innovations
                  </h6>
                  <svg
                    width="18"
                    height="9"
                    viewBox="0 0 18 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5966 4.85355C17.7918 4.65829 17.7918 4.34171 17.5966 4.14645L14.4146 0.964466C14.2193 0.769204 13.9028 0.769204 13.7075 0.964466C13.5122 1.15973 13.5122 1.47631 13.7075 1.67157L16.5359 4.5L13.7075 7.32843C13.5122 7.52369 13.5122 7.84027 13.7075 8.03553C13.9028 8.2308 14.2193 8.2308 14.4146 8.03553L17.5966 4.85355ZM0.272461 5L17.243 5V4L0.272461 4V5Z"
                      fill="#EE763E"
                    />
                  </svg>
                </a>
              </div>
              <div className="w-4/12 hidden xl:block">
                <div className="flex flex-col bg-white">
                  {
                    <Business
                      business={_articles.filter(
                        (item) =>
                          _rubrics.find((r) => r.id === item.rubricId)?.name ===
                          "Business"
                      )}
                    />
                  }
                  {
                    <BlockNews
                      title="Innovation"
                      data={_articles.filter(
                        (item) =>
                          _rubrics.find((r) => r.id === item.rubricId)?.name ===
                          "Innovation"
                      )}
                    />
                  }
                </div>
                <img
                  src="../assets/img/pub.png"
                  className="w-full h-auto my-4"
                  alt="cerebrum"
                />
                <div className="flex flex-col bg-white">
                  {
                    <BlockNews
                      title="Business"
                      data={_articles.filter(
                        (item) =>
                          _rubrics.find((r) => r.id === item.rubricId)?.name ===
                          "Business"
                      )}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
          <Portraits portraits={_portraits} />
          <div
            className="md:container mt-5 mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center py-4"
            style={{ padding: "unset" }}
          >
            <div className="w-full relative flex flex-row gap-3">
              <div className="w-full xl:w-10/12">
                {!isUndefined(
                  _articles.filter(
                    (item) =>
                      _rubrics.find((r) => r.id === item.rubricId)?.name ===
                      "Société"
                  )[1]
                ) && (
                  <Innovation
                    rubricName={
                      _rubrics.find(
                        (r) =>
                          r.id ===
                          _articles.filter(
                            (item) =>
                              _rubrics.find((r) => r.id === item.rubricId)
                                ?.name === "Société"
                          )[1].rubricId
                      )?.name!
                    }
                    magazine={
                      _articles.filter(
                        (item) =>
                          _rubrics.find((r) => r.id === item.rubricId)?.name ===
                          "Société"
                      )[1]
                    }
                  />
                )}
                {/* <InnovationGroup />
            <InnovationGroup /> */}
                <a
                  href="/societe"
                  className="flex flex-row items-center justify-center my-6 gap-2"
                >
                  <h6 className="text-primary-100 text-sm font-normal">
                    Toutes les sociétés
                  </h6>
                  <svg
                    width="18"
                    height="9"
                    viewBox="0 0 18 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5966 4.85355C17.7918 4.65829 17.7918 4.34171 17.5966 4.14645L14.4146 0.964466C14.2193 0.769204 13.9028 0.769204 13.7075 0.964466C13.5122 1.15973 13.5122 1.47631 13.7075 1.67157L16.5359 4.5L13.7075 7.32843C13.5122 7.52369 13.5122 7.84027 13.7075 8.03553C13.9028 8.2308 14.2193 8.2308 14.4146 8.03553L17.5966 4.85355ZM0.272461 5L17.243 5V4L0.272461 4V5Z"
                      fill="#EE763E"
                    />
                  </svg>
                </a>
              </div>
              <div className="w-4/12 mt-4 hidden xl:block">
                <div className="flex flex-col bg-white">
                  {
                    <Business
                      business={_articles.filter(
                        (item) =>
                          _rubrics.find((r) => r.id === item.rubricId)?.name ===
                          "Business"
                      )}
                    />
                  }
                  {
                    <BlockNews
                      title="Innovation"
                      data={_articles.filter(
                        (item) =>
                          _rubrics.find((r) => r.id === item.rubricId)?.name ===
                          "Innovation"
                      )}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
          {_articles.filter(
            (item) =>
              _rubrics.find((r) => r.id === item.rubricId)?.name === "Science"
          ).length > 0 && (
            <div className="md:container bg-primary-100 mx-auto flex flex-wrap p-5 flex-col items-center py-2 px-4 md:px-6">
              <div className="flex flex-col w-full">
                <div className="flex flex-row items-center gap-8">
                  <h1 className="text-4xl font-bold text-white">Science</h1>
                  <div className="md:block hidden">
                    <LineScienceSVG width="100%" />
                  </div>
                </div>
              </div>
              <div className=" py-4 justify-between w-full gap-4 overflow-x-auto">
                <div className="flex flex-row gap-2 xl:w-full science-2">
                  {_articles
                    .filter(
                      (item) =>
                        _rubrics.find((r) => r.id === item.rubricId)?.name ===
                        "Science"
                    )
                    .slice(0, 3)
                    .map((magazine, i) => (
                      <ScienceCard
                        key={i}
                        magazine={magazine}
                        rubricName={
                          _rubrics.find((r) => r.id === magazine.rubricId)
                            ?.name!
                        }
                      />
                    ))}
                </div>
              </div>
            </div>
          )}
          <Magazine />
          <VideoBlock rubrics={_rubrics} />
          <Footer />
        </div>
      ) : (
        <div className="w-full flex justify-center items-center mt-16">
          <p className="text-2xl font-semibold">Aucun contenu</p>
        </div>
      )}
    </>
  );
}
