import { Link } from "react-router-dom";
import { LogoFooterSVG } from "../components";

export default function Footer() {
  return (
    <footer className="mt-30 bg-natural-110">
      <div className="md:container px-5 py-24 mx-auto flex md:items-center md:flex-row items-center justify-between md:flex-nowrap flex-wrap flex-col">
        <div className="md:w-1/2 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
          <Link
            to="#"
            className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900"
          >
            <LogoFooterSVG />
          </Link>
          <p className="mt-8 text-sm font-normal text-white font-roboto">
            Cerebrum Magazine is an English written healthcare magazine
            published by Arab Health Media Communication since 2010. The
            Magazine is available bimonthly throughout the Gulf area, the Levant
            and North Africa with a total circulation of 58000 copies. HOSPITALS
            is a leading source for reliable news and updated analysis on
            healthcare. Cerebrum Magazine is an English written healthcare
            magazine published by Arab Health Media Communication since 2010.
            The Magazine is available bimonthly throughout the Gulf area, the
            Levant and North Africa with a total circulation of 58000 copies.
            HOSPITALS is a leading source for reliable news and updated analysis
            on healthcare.
          </p>
        </div>
        <div className="w-auto flex flex-col justify-center items-center md:items-start mt-10 md:mt-0 font-roboto font-normal text-sm gap-2 text-white">
          <span>Lebanon - Mansourieh Highway </span>
          <span>Jihad Wakim ctr - 3rd flr</span>
          <span>T +961 4 534058 </span>
          <span>M +961 3 606100</span>
          <div className="flex flex-col items-center md:items-start mt-6 gap-1">
            <span>advertising@thearabhospital.com</span>
            <span>schammas@tahmag.com</span>
          </div>
          <div className="mt-6 items-center">
            <span>Download Our APP</span>
            <div className="flex flex-row gap-2 mt-4">
              <img src="../assets/img/google-play.png" alt="google-play" />
              <img src="../assets/img/app-store.png" alt="app-store" />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-natural-105">
        <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
          <p className="text-white text-sm font-light font-roboto text-center sm:text-left">
            © Copyright 2022, All Rights Reserved | CEREBRUM MAG
          </p>
        </div>
      </div>
    </footer>
  );
}
