import { HealthlineOne } from "./svg/healthLineOne.svg";

interface SectionProps {
  title: string;
}
export function SectionTitle({ title }: SectionProps) {
  return (
    <div className="relative flex flex-row items-center gap-8">
      <h1 className="text-5xl font-bold text-black z-10">{title}</h1>
      <div className="hidden md:flex">
        <HealthlineOne />
      </div>

      <div className="absolute -bottom-3 -left-3 h-6 w-14 bg-primary-100"></div>
    </div>
  );
}
