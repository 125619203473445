import { ArticleResponse } from "../app/interfaces/interfaces";
import React from "react";
import {
  BlockNews,
  Breadcrumbs,
  Business,
  LineHealthFullSVG,
  Tricks,
} from "./components";
import Footer from "./layouts/footer";
import Header from "./layouts/header";
import SubHeader from "./layouts/subHeader";
import { connect, useSelector } from "react-redux";
import { siteGetAllMagazine } from "../redux/magazine/actionMagazine";

interface DispatchProps {
  onGetMagazines: () => void;
}

function AstuceSante(props: DispatchProps) {
  const _magazines: ArticleResponse[] = useSelector(
    //@ts-ignore
    (state: StateMagazine) => state.magazine.magazines
  );

  React.useEffect(() => {
    props.onGetMagazines();
  }, [props]);
  return (
    <>
      <Header />
      <SubHeader />
      <Breadcrumbs title="Santé" href="/astu-sante" />
      <div
        className="md:container mt-5 mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center py-4"
        style={{ padding: "unset" }}
      >
        <div className="w-full relative flex flex-row gap-3">
          <div className="w-full xl:w-10/12 bg-white p-6">
            {_magazines.length > 0 ? (
              <div className="flex flex-col w-full">
                <h1 className="text-3xl font-bold text-primary-100 mb-4 md:mb-0">
                  Tous les articles
                </h1>
                <div className="md:my-4">
                  <LineHealthFullSVG width="100%" />
                </div>
                <div className="w-full ">
                  <div className="w-full grid grid-cols-1 md:grid-cols-2">
                    {_magazines
                      .filter((item) => item.rubric!.name === "Santé")
                      .slice(0, 8)
                      .map((magazine) => {
                        return (
                          <Tricks
                            rubricName="@@@@@@@@"
                            type="white"
                            line={true}
                            displayLine={true}
                            magazine={magazine}
                          />
                        );
                      })}
                  </div>
                </div>
                <div className="md:my-4">
                  <LineHealthFullSVG width="100%" />
                </div>
                <div className="flex justify-center my-4">
                  <button className="flex flex-row gap-4 items-center bg-primary-100 py-2 px-8">
                    <span className="font-normal text-base text-white">
                      Articles les plus anciens
                    </span>
                    <svg
                      width="18"
                      height="16"
                      viewBox="0 0 18 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.6923 8.70711C18.0828 8.31658 18.0828 7.68342 17.6923 7.29289L11.3284 0.928932C10.9378 0.538408 10.3047 0.538408 9.91414 0.928932C9.52362 1.31946 9.52362 1.95262 9.91414 2.34315L15.571 8L9.91414 13.6569C9.52362 14.0474 9.52362 14.6805 9.91414 15.0711C10.3047 15.4616 10.9378 15.4616 11.3284 15.0711L17.6923 8.70711ZM0.0146484 9H16.9852V7L0.0146484 7L0.0146484 9Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            ) : (
              <p className="text-lg text-center"> Aucun article publié</p>
            )}
          </div>
          {_magazines.length > 0 && (
            <div className="w-4/12 hidden xl:block">
              <div className="flex flex-col bg-white">
                {_magazines.filter(
                  (item) => item.rubric!.name === "Business"
                ) && (
                  <Business
                    business={_magazines.filter(
                      (item) => item.rubric!.name === "Business"
                    )}
                  />
                )}
                {_magazines.filter(
                  (item) => item.rubric!.name === "Innovation"
                ) && (
                  <BlockNews
                    title="Innovation"
                    data={_magazines.filter(
                      (item) => item.rubric!.name === "Innovation"
                    )}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {/* <VideoBlock rubrics={_rubrics} /> */}

      <Footer />
    </>
  );
}

const mapDispatchProps = (dispatch: any) => {
  return {
    onGetMagazines: () => dispatch(siteGetAllMagazine()),
  };
};

export default connect(null, mapDispatchProps)(AstuceSante);
