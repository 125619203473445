export function SocieteSVG({ ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.66668 5.66667C10.9553 5.66667 12 4.622 12 3.33333C12 2.04467 10.9553 1 9.66668 1C8.37801 1 7.33334 2.04467 7.33334 3.33333C7.33334 4.622 8.37801 5.66667 9.66668 5.66667Z"
        fill="currentColor"
      />
      <path
        d="M3.66666 6C4.77123 6 5.66666 5.10457 5.66666 4C5.66666 2.89543 4.77123 2 3.66666 2C2.56209 2 1.66666 2.89543 1.66666 4C1.66666 5.10457 2.56209 6 3.66666 6Z"
        fill="currentColor"
      />
      <path
        d="M15.6667 5.6665C16.7712 5.6665 17.6667 4.77107 17.6667 3.6665C17.6667 2.56193 16.7712 1.6665 15.6667 1.6665C14.5621 1.6665 13.6667 2.56193 13.6667 3.6665C13.6667 4.77107 14.5621 5.6665 15.6667 5.6665Z"
        fill="currentColor"
      />
      <path
        d="M16 6.3335V9.00016C16 9.18416 15.8507 9.3335 15.6667 9.3335C15.4827 9.3335 15.3333 9.18416 15.3333 9.00016V6.3335C14.229 6.3335 13.3333 7.22883 13.3333 8.3335V12.0002H18V8.3335C18 7.22883 17.1043 6.3335 16 6.3335Z"
        fill="currentColor"
      />
      <path
        d="M15.6303 15.6667L13.8017 17.234L10.975 13.7003L9.5 15.6667H6C5.816 15.6667 5.66667 15.5173 5.66667 15.3333C5.66667 15.1493 5.816 15 6 15H7.94667C7.98033 14.784 8 14.5623 8 14.3333V13.6667C6.66667 13.3333 6.33333 13 5 12C3.66667 13 3.33333 13.3333 2 13.6667V14.3333C2 17.3333 5 19.3333 5 19.3333C5 19.3333 6.10667 18.589 6.97567 17.3333H6C5.816 17.3333 5.66667 17.184 5.66667 17C5.66667 16.816 5.816 16.6667 6 16.6667H10L11 15.3333L13.6667 18.6667L16 16.6667H19.3333V15.6667H15.6303Z"
        fill="currentColor"
      />
      <path
        d="M8.08866 13.0002H12.6667V8.66683C12.6667 7.37816 11.622 6.3335 10.3333 6.3335H9.99999V9.66683C9.99999 9.85083 9.85066 10.0002 9.66666 10.0002C9.48266 10.0002 9.33332 9.85083 9.33332 9.66683V6.3335H8.99999C7.71132 6.3335 6.66666 7.37816 6.66666 8.66683V12.3995C7.09266 12.6805 7.45599 12.8372 8.08866 13.0002Z"
        fill="currentColor"
      />
      <path
        d="M5.00001 11.1665C5.76068 11.7385 5.70368 11.6955 6.00001 11.9192V8.6665C6.00001 7.56184 5.10434 6.6665 4.00001 6.6665V9.33317C4.00001 9.51717 3.85068 9.6665 3.66668 9.6665C3.48268 9.6665 3.33334 9.51717 3.33334 9.33317V6.6665C2.22901 6.6665 1.33334 7.56184 1.33334 8.6665V12.3332H3.43034C3.73801 12.1225 4.05834 11.8748 5.00001 11.1665Z"
        fill="currentColor"
      />
    </svg>
  );
}
