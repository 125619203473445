import {
  AddSVG,
  Button,
  EditSVG,
  Input,
  Modal,
  Spinner,
  TrashSVG,
} from "../../components";
import { Page } from "../../layouts/page";
import DataTable from "react-data-table-component";
import { FaSort } from "react-icons/fa";
import React from "react";
import { isEmpty, isNil, lowerCase } from "lodash";
import AlertConfirmation from "../../components/AlertConfirmation";
import { ActionType } from "../../interfaces/enum";
import { PortraitResponse } from "../../interfaces/interfaces";
import { formatDateMMMddyyy, getRandomFileName } from "../../../utils/utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  deletePortrait,
  getPortraits,
  setDbPortrait,
  updatePortrait,
  uploadImage,
} from "../../core/firebase/firestore-collection";
import { v4 as uuid } from "uuid";
import { PORTRAITS_PATH } from "../../core/firebase/constantes";
import { getDownloadURL } from "firebase/storage";

export default function PortraitPage() {
  const [_showAlert, setShowAlert] = React.useState<boolean>(false);
  const [_isLoading, setLoading] = React.useState<boolean>(false);
  const [_action, setAction] = React.useState<ActionType>(ActionType.POST);
  const [_portrait, setChangePortrait] = React.useState<PortraitResponse>();
  const [_show, setShow] = React.useState<boolean>(false);
  const [_portraits, setPortait] = React.useState<PortraitResponse[]>([]);

  const [_portraitId, setPortraitId] = React.useState<string>();
  const [_searchPortrait, setSearcPortrait] = React.useState<string>();

  const [form, _setFormReference] = React.useState<HTMLFormElement | null>(
    null
  );

  const handleButtonClick = (portrait: PortraitResponse, action?: boolean) => {
    if (action) {
      setAction(ActionType.PATCH);
      setShow(true);
      setChangePortrait(portrait);
    } else {
      setShowAlert(true);
    }
    setPortraitId(portrait.id);
  };

  const _confirmForm = React.useCallback(() => {
    if (!isNil(form)) {
      form.requestSubmit();
    }
  }, [form]);

  const _findPortraits = React.useMemo(() => {
    if (isEmpty(_searchPortrait)) return _portraits;
    return _portraits.filter((item) => {
      return lowerCase(item.name).includes(lowerCase(_searchPortrait));
    });
  }, [_searchPortrait, _portraits]);

  const fecthPortraits = React.useCallback(async () => {
    setPortait([]);
    setLoading(true);
    (await getPortraits()).forEach((item) => {
      const portrait = item.data() as PortraitResponse;
      setPortait((old) => [...old!, portrait]);
    });
    setLoading(false);
  }, []);

  React.useEffect(() => {
    fecthPortraits();
  }, [fecthPortraits]);

  const onDeletePortrait = React.useCallback(
    async (id: string) => {
      setLoading(true);
      try {
        await deletePortrait(id).then(async () => {
          toast("Rubric supprimer", { type: "success" });
          await fecthPortraits();
        });
      } catch (error: any) {
        toast(error.message, { type: "error" });
      }
      setLoading(false);
      setShowAlert(false);
    },
    [fecthPortraits]
  );

  const onFormSubmit = React.useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();

      const target = e.target as typeof e.target & {
        title: { value: string };
        idPortrait: { value: string };
        picture: { value: string };
        description: { value: string };
      };
      if (_action === ActionType.POST) {
        const id: string = uuid();
        //@ts-ignore
        const file = e.target[2]?.files[0];

        const uploadTask = uploadImage(
          PORTRAITS_PATH,
          getRandomFileName(file.name),
          file
        );

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            setLoading(true);
          },
          (error: any) => toast(error.message, { type: "error" }),
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then(
              async (url: string) => {
                try {
                  await setDbPortrait(id, {
                    id: id,
                    picture: url,
                    name: target.title.value,
                    description: target.description.value,
                    created_at: new Date().toString(),
                  }).then(async () => {
                    setShow(false);
                    await fecthPortraits();
                    toast("Portrait créer", { type: "success" });
                  });
                } catch (error: any) {
                  toast(error.message, { type: "error" });
                }
                setLoading(false);
              }
            );
          }
        );
      } else {
        setLoading(true);
        try {
          await updatePortrait(target.idPortrait.value, {
            name: target.title.value,
            description: target.description.value,
          }).then(async () => {
            toast("Portrait mise a jour", { type: "success" });
            setShow(false);
            await fecthPortraits();
          });
        } catch (error: any) {
          toast(error.message, { type: "error" });
        }
        setLoading(false);
      }
    },
    [_action, fecthPortraits]
  );

  return (
    <>
      <ToastContainer />
      <Page
        left={
          <h1 className="font-bold text-2xl text-background-100">Portraits</h1>
        }
      >
        {_isLoading ? (
          <Spinner />
        ) : (
          <>
            <div className="flex sm:flex-row flex-col sm:gap-0 gap-5 justify-between mt-5">
              <div className="flex flex-row ">
                <Input
                  block
                  type="search"
                  placeholder="Chercher un portrait"
                  id="title"
                  onChange={(e) => setSearcPortrait(e.target.value)}
                  name="title"
                  className="md:w-60"
                />
              </div>
              <div>
                <Button
                  color="primary"
                  type="button"
                  icon={<AddSVG />}
                  title="Nouveau portrait"
                  className="w-auto"
                  onClick={() => {
                    setShow(true);
                    setAction(ActionType.POST);
                  }}
                />
              </div>
            </div>

            <div className="">
              {isEmpty(_portraits) ? (
                <div className="bg-white py-12 flex mt-8 items-center justify-center w-full">
                  <p>No Data</p>
                </div>
              ) : (
                <div className=" -mx-4 mt-8 ring-1 bg-white border-0 ring-white sm:-mx-6 md:mx-0 md:rounded-xl">
                  <DataTable
                    title="Rubriques"
                    columns={[
                      {
                        name: "Ref.",
                        selector: "id",
                      },
                      {
                        name: "Profile",
                        selector: "picture",
                        cell: (row: PortraitResponse) => {
                          return (
                            <img
                              src={row.picture}
                              className="w-16 h-16 rounded-md object-cover my-2"
                              alt=""
                            />
                          );
                        },
                      },
                      {
                        name: "Nom",
                        selector: "name",
                      },
                      {
                        name: "Description",
                        selector: "description",
                      },
                      {
                        name: "Créer le",
                        selector: "created_at",
                        cell: (row: PortraitResponse) => {
                          return (
                            <span> {formatDateMMMddyyy(row.created_at!)} </span>
                          );
                        },
                      },
                      {
                        name: "Actions",
                        selector: "runtime",
                        right: true,
                        cell: (row) => (
                          <div className="flex flex-row gap-2 h-8">
                            <Button
                              onClick={() => handleButtonClick(row, true)}
                              color="success"
                              className="h-8"
                              type="button"
                              icon={<EditSVG stroke="#ffff" />}
                            />
                            <Button
                              onClick={() => handleButtonClick(row)}
                              color="danger"
                              className="h-8"
                              type="button"
                              icon={<TrashSVG stroke="#ffff" />}
                            />
                          </div>
                        ),
                      },
                    ]}
                    data={_findPortraits}
                    defaultSortField="title"
                    sortIcon={<FaSort />}
                    pagination
                  />
                </div>
              )}
            </div>
          </>
        )}
      </Page>

      <AlertConfirmation
        description={`Supprimer le portrait ${_portrait?.name}. Cette opération supprime également tous les états associés!.`}
        title="Veuillez confirmer s'il vous plait...!"
        onShow={() => setShowAlert(!_showAlert)}
        show={_showAlert}
        loading={_isLoading}
        onConfirm={() => onDeletePortrait(_portraitId!)}
      />
      <Modal
        show={_show}
        loading={_isLoading}
        onShow={() => setShow(!_show)}
        onConfirm={_confirmForm}
        title={
          _action === ActionType.POST
            ? "Ajouter un portrait"
            : "Modifier un portrait"
        }
      >
        <form
          className="mt-8 space-y-3"
          ref={_setFormReference}
          onSubmit={onFormSubmit}
        >
          <div className="w-full">
            <label className="block text-sm font-mediumtext-gray-700 mb-1">
              Nom<span className="text-danger-100">*</span>
            </label>
            {_action === ActionType.PATCH && (
              <Input
                block
                type="text"
                name="idPortrait"
                defaultValue={_portraitId}
                hidden={true}
                required={true}
              />
            )}
            <Input
              block
              type="text"
              name="title"
              defaultValue={_action === ActionType.PATCH ? _portrait?.name : ""}
              placeholder="Nom"
              required={true}
            />
          </div>
          <div className="w-full">
            <label className="block text-sm font-mediumtext-gray-700 mb-1">
              Description<span className="text-danger-100">*</span>
            </label>
            <textarea
              id="description"
              rows={4}
              name="description"
              defaultValue={
                _action === ActionType.PATCH ? _portrait?.description : ""
              }
              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Écrivez une description..."
              required={true}
            ></textarea>
          </div>
          {_action === ActionType.POST ? (
            <div className="w-full">
              <label
                htmlFor="formFile"
                className="block mt-4 text-sm font-mediumtext-gray-700 mb-1"
              >
                Photo
                <span className="text-danger-100">*</span>
              </label>
              <Input block type="file" accept=".jpef, .png, .jpg" />
            </div>
          ) : (
            <></>
          )}
        </form>
      </Modal>
    </>
  );
}
