export function SearchSVG() {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_308_18855)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.3619 15.2458C13.0933 16.3144 11.4552 16.9583 9.6667 16.9583C5.63961 16.9583 2.37503 13.6938 2.37503 9.66667C2.37503 5.63958 5.63961 2.375 9.6667 2.375C13.6938 2.375 16.9584 5.63958 16.9584 9.66667C16.9584 11.4553 16.3144 13.0933 15.2457 14.3619L18.4419 17.5581C18.5582 17.6755 18.6234 17.8342 18.623 17.9994C18.6227 18.1647 18.5568 18.3231 18.44 18.44C18.3231 18.5569 18.1647 18.6227 17.9994 18.6231C17.8341 18.6235 17.6754 18.5584 17.558 18.442L14.3619 15.2458ZM3.62503 9.66667C3.62503 6.33 6.33003 3.625 9.6667 3.625C13.0034 3.625 15.7084 6.33 15.7084 9.66667C15.7084 11.3004 15.0599 12.7828 14.0063 13.8703C13.9542 13.9083 13.9083 13.9542 13.8702 14.0063C12.7827 15.0599 11.3004 15.7083 9.6667 15.7083C6.33003 15.7083 3.62503 13.0034 3.62503 9.66667Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_308_18855">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.500015 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
