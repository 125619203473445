import {
  ArticleRequest,
  SpotlightResponse,
} from "../../app/interfaces/interfaces";
import {
  ADD_SPOTLIGHT,
  DELETE_SPOTLIGHT_SUCCESS,
  GET_ALL_MAGAZINE,
  GET_ALL_SPOTLIGHT,
  MAGAZINE_LOADING,
  SPOTLIGHT_ERROR,
  SPOTLIGHT_LOADING,
} from "../types/types";

export interface StateMagazine {
  spotlights: SpotlightResponse[];
  isSpotlightLoading: boolean;
  errorSpotlight: string;
  magazines: ArticleRequest[];
  isLoading: boolean;
}

const initialStateMagazine: StateMagazine = {
  spotlights: [],
  isSpotlightLoading: false,
  errorSpotlight: "",
  magazines: [],
  isLoading: false,
};

export const reducerMagazine = (state = initialStateMagazine, action: any) => {
  switch (action.type) {
    case SPOTLIGHT_LOADING:
      return {
        ...state,
        isSpotlightLoading: action.payload,
      };
    case ADD_SPOTLIGHT:
      return {
        ...state,
        spotlights: [...state.spotlights, action.payload],
        isSpotlightLoading: false,
      };
    case GET_ALL_SPOTLIGHT:
      return {
        ...state,
        spotlights: action.payload,
        isSpotlightLoading: false,
      };
    case DELETE_SPOTLIGHT_SUCCESS:
      const copy = [...state.spotlights];
      const index = copy.findIndex((element) => element.id === action.payload);
      if (index > -1) {
        copy.splice(index, 1);
      }
      return {
        ...state,
        spotlights: copy,
        errorSpotlight: "",
        isSpotlightLoading: false,
      };
    case SPOTLIGHT_ERROR:
      return {
        ...state,
        errorSpotlight: action.payload,
        isSpotlightLoading: false,
      };
    case MAGAZINE_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case GET_ALL_MAGAZINE:
      return {
        ...state,
        magazines: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};
