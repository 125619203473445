export function VideoSVG() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" fill="#EE763E" />
      <path d="M24 16L12 22.9282L12 9.0718L24 16Z" fill="white" />
    </svg>
  );
}
