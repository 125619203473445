import React from "react";
import classNames from "classnames";

type InputType =
  | "text"
  | "file"
  | "email"
  | "number"
  | "password"
  | "search"
  | "hidden";
interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  type: InputType;
  block?: boolean;
  onChange?: (date?: any) => void;
  disabled?: boolean;
  className?: string;
  placeholder?: string;
}

export function Input({
  type,
  block,
  onChange,
  disabled,
  className,
  placeholder,
  ...props
}: React.PropsWithChildren<InputProps>) {
  return (
    <input
      type={type}
      className={classNames(
        // block ? "w-full" : "w-68",
        className ?? className,
        type === "file" &&
          "form-control block w-full px-3 text-base font-normal text-gray-700 bg-gray-50 border border-solid transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-prmary-100 focus:outline-none",
        "bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-100 focus:border-primary-100 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
      )}
      onChange={onChange}
      disabled={disabled}
      placeholder={placeholder}
      {...props}
    />
  );
}
