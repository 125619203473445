import {
  doc,
  setDoc,
  updateDoc,
  getDocs,
  getDoc,
  deleteDoc,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  getCountFromServer,
} from "firebase/firestore";
import { ref, uploadBytesResumable, deleteObject } from "firebase/storage";
import {
  ArticleRequest,
  ArticleResponse,
  ChangeStatusRequest,
  MagazineRequest,
  PortraitResponse,
  PortraitUpdate,
  RubricResponse,
  RubricUpdate,
  ScienceRequest,
  ScienceUpdateRequest,
  UserResponse,
  UserUpdate,
  ViodeoRequest,
  ViodeoUpdate,
} from "../../interfaces/interfaces";
import { createCollection, simpleCollection, storage } from "./firebase-api";

/** Upload file */
export const uploadImage = (path: string, fileName: string, file: any) => {
  const storageRef = ref(storage, `${path}/${fileName}`);
  return uploadBytesResumable(storageRef, file);
};

export const deleteImage = (path: string, fileName: string) => {
  const desertRef = ref(storage, `${path}/${fileName}`);
  return deleteObject(desertRef);
};

const usersCol = createCollection<UserResponse | UserResponse[]>("users");
const rubricsCol = createCollection<RubricResponse>("rubrics");
export const articlesCol = createCollection<ArticleResponse>("articles");
export const portraitsCol = createCollection<PortraitResponse>("portraits");
export const videosCol = createCollection<ViodeoRequest>("videos");
export const magazinesCol = createCollection<MagazineRequest>("magazine");
export const sciencesCol = createCollection<ScienceRequest>("sciences");

/*Dashboards*/
export const getCountData = async () => {
  const usersCount = await getCountFromServer(usersCol);
  const rubricsCount = await getCountFromServer(rubricsCol);
  const articlesCount = await getCountFromServer(articlesCol);
  const portraitCount = await getCountFromServer(portraitsCol);
  const magaizesCount = await getCountFromServer(magazinesCol);
  const sciencesCount = await getCountFromServer(sciencesCol);
  const videosCount = await getCountFromServer(videosCol);

  return {
    users: usersCount.data().count,
    rubrics: rubricsCount.data().count,
    articles: articlesCount.data().count,
    portaits: portraitCount.data().count,
    magazines: magaizesCount.data().count,
    sciences: sciencesCount.data().count,
    videos: videosCount.data().count,
  };
};

/** User */
export const setDbUser = async (document: string, user: UserResponse) => {
  const userRef = doc(usersCol, document);
  await setDoc(userRef, user);
};

export const getUsers = async () => {
  return await getDocs(usersCol);
};

export const deleteUser = async (documentId: string) => {
  const userRef = doc(usersCol, documentId);
  await deleteDoc(userRef);
};

export const updateUser = async (documentId: string, user: UserUpdate) => {
  const userRef = doc(usersCol, documentId);
  await updateDoc(userRef, { ...user });
};

export const getOneUser = async (documentId: string) => {
  const userRef = doc(usersCol, documentId);
  const docSnap = await getDoc(userRef);
  return docSnap.data();
};

/** Rubrics */
export const setDbRubric = async (document: string, rubric: RubricResponse) => {
  const rubricRef = doc(rubricsCol, document);
  await setDoc(rubricRef, rubric);
};

export const getRubrics = async () => {
  return await getDocs(rubricsCol);
};

export const deleteRubric = async (documentId: string) => {
  const rubricRef = doc(rubricsCol, documentId);
  await deleteDoc(rubricRef);
};

export const updateRubric = async (
  documentId: string,
  rubric: RubricUpdate
) => {
  const rubricRef = doc(rubricsCol, documentId);
  await updateDoc(rubricRef, { ...rubric });
};

export const getOneRubric = async (documentId: string) => {
  const rubricRef = doc(rubricsCol, documentId);
  const docSnap = await getDoc(rubricRef);
  return docSnap.data();
};

/** Articles */
export const setDbArticle = async (
  document: string,
  article: ArticleRequest
) => {
  const articleRef = doc(articlesCol, document);
  await setDoc(articleRef, article);
};

export const getArticles = async () => {
  return await getDocs(articlesCol);
};

export const getArticlesPaginate = async (limitData: number) => {
  return query(articlesCol, orderBy("created_at"), limit(limitData));
};

export const searchArticles = async (searchTerm: string) => {
  const q = query(
    articlesCol,
    where("articleTitle", ">=", searchTerm),
    where("articleTitle", "<=", searchTerm + "\uf8ff")
  );
  return await getDocs(q);
};

export const getOneArticle = async (documentId: string) => {
  const articleRef = doc(articlesCol, documentId);
  const docSnap = await getDoc(articleRef);
  return docSnap.data();
};

export const getArticlesSpotlight = async () => {
  const q = query(simpleCollection("articles"), where("spotlight", "==", true));
  return q;
};

export const getArticlesByElement = async (
  key: string,
  value: string,
  limitData?: number
) => {
  const q = query(
    simpleCollection("articles"),
    where(key, "==", value),
    limit(limitData!)
  );
  return q;
};

export const getArticlesByElemenPaginate = async (
  key: string,
  value: string,
  lastDoc: any,
  limitData: number
) => {
  const q = query(
    simpleCollection("articles"),
    startAfter(lastDoc),
    where(key, "==", value),
    limit(limitData)
  );
  return q;
};

export const deleteArticle = async (documentId: string) => {
  const articleRef = doc(articlesCol, documentId);
  await deleteDoc(articleRef);
};

export const updateArticle = async (
  documentId: string,
  article: ChangeStatusRequest
) => {
  const articleRef = doc(articlesCol, documentId);
  await updateDoc(articleRef, { ...article });
};

/** Portraits */
export const setDbPortrait = async (
  document: string,
  portrait: PortraitResponse
) => {
  const portraitRef = doc(portraitsCol, document);
  await setDoc(portraitRef, portrait);
};

export const getPortraits = async () => {
  return await getDocs(portraitsCol);
};

export const deletePortrait = async (documentId: string) => {
  const portraitRef = doc(portraitsCol, documentId);
  await deleteDoc(portraitRef);
};

export const updatePortrait = async (
  documentId: string,
  portrait: PortraitUpdate
) => {
  const portraitRef = doc(portraitsCol, documentId);
  await updateDoc(portraitRef, { ...portrait });
};

/** Videos */
export const setDbVideo = async (document: string, video: ViodeoRequest) => {
  const videoRef = doc(videosCol, document);
  await setDoc(videoRef, video);
};

export const getVideos = async () => {
  return await getDocs(videosCol);
};

export const deleteVideo = async (documentId: string) => {
  const videoRef = doc(videosCol, documentId);
  await deleteDoc(videoRef);
};

export const updateVideo = async (documentId: string, video: ViodeoUpdate) => {
  const videoRef = doc(videosCol, documentId);
  await updateDoc(videoRef, { ...video });
};

/** Magazine */
export const setDbMagazine = async (
  document: string,
  magazine: MagazineRequest
) => {
  const magazineRef = doc(magazinesCol, document);
  await setDoc(magazineRef, magazine);
};

export const getMagazine = async () => {
  return await getDocs(magazinesCol);
};

export const deleteMagazine = async (documentId: string) => {
  const magazineRef = doc(magazinesCol, documentId);
  await deleteDoc(magazineRef);
};

/** Sciences */
export const setDbScience = async (
  document: string,
  science: ScienceRequest
) => {
  const scienceRef = doc(sciencesCol, document);
  await setDoc(scienceRef, science);
};

export const getSciences = async () => {
  return await getDocs(sciencesCol);
};

export const getOneScience = async (documentId: string) => {
  const scienceRef = doc(sciencesCol, documentId);
  const docSnap = await getDoc(scienceRef);
  return docSnap.data();
};

export const getSciencesByElement = async (key: string, value: string) => {
  const q = query(simpleCollection("sciences"), where(key, "==", value));
  return q;
};

export const deleteScience = async (documentId: string) => {
  const scienceRef = doc(sciencesCol, documentId);
  await deleteDoc(scienceRef);
};

export const updateScience = async (
  documentId: string,
  article: ScienceUpdateRequest
) => {
  const scienceRef = doc(sciencesCol, documentId);
  await updateDoc(scienceRef, { ...article });
};
