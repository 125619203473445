// const BASE_URL: string = "http://localhost:3000/api/v1";
const BASE_URL: string = "https://api.cerebrummagazine.com/api/v1";

export let headers: any = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

async function request(url: string, config: RequestInit): Promise<Response> {
  const response = await fetch(url, config);
  return response;
}

export const api = {
  get: (url: string) =>
    request(BASE_URL + url, {
      headers,
      method: "GET",
    }),

  post: <TBody extends BodyInit>(url: string, body: TBody) =>
    request(BASE_URL + url, {
      headers: headers,
      method: "POST",
      body,
    }),

  delete: (url: string) =>
    request(BASE_URL + url, {
      headers,

      method: "DELETE",
    }),

  patch: <TBody extends BodyInit>(url: string, body: TBody) =>
    request(BASE_URL + url, {
      headers: headers,
      method: "PATCH",

      body,
    }),
};
