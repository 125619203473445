import classNames from "classnames";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface SelectItem<T> {
  id: React.Key;
  name: string;
}

interface SelectedProps<T>
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  datas: SelectItem<T>[];
  onChange: (value: React.ChangeEvent<HTMLSelectElement>) => void;
  block?: boolean;
  className?: string;
}

export function Selected<T>({
  datas,
  onChange,
  block,
  className,
  ...props
}: SelectedProps<T>) {
  return (
    //@ts-ignore
    <select
      //@ts-ignore
      onChange={onChange}
      className={classNames(
        block ? "w-full" : "",
        className ? className : "",
        "mt-1 rounded-xl border-background-80 text-base focus:border-primary-100 focus:outline-none focus:ring-primary-100 sm:text-sm"
      )}
      {...props}
    >
      <option selected>Choisir...</option>
      {datas.map((item, index) => {
        return (
          <option key={index} value={item.id}>
            {item.name}
          </option>
        );
      })}
    </select>
  );
}
