interface Property {
  color: string;
  classname?: string;
}

export function PlayCircleSVG({ color, classname }: Property) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={classname}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.97 22.75C6.04997 22.75 1.21997 17.93 1.21997 12C1.21997 6.07 6.04997 1.25 11.97 1.25C17.89 1.25 22.72 6.07 22.72 12C22.72 17.93 17.9 22.75 11.97 22.75ZM11.97 2.75C6.86997 2.75 2.71997 6.9 2.71997 12C2.71997 17.1 6.86997 21.25 11.97 21.25C17.07 21.25 21.22 17.1 21.22 12C21.22 6.9 17.07 2.75 11.97 2.75Z"
        stroke={color}
      />
      <path
        d="M10.56 16.99C10.12 16.99 9.69999 16.88 9.32999 16.67C8.46999 16.17 7.98999 15.19 7.98999 13.91V10.56C7.98999 9.27999 8.45999 8.29999 9.31999 7.79999C10.18 7.29999 11.27 7.37999 12.38 8.01999L15.28 9.68999C16.39 10.33 17 11.23 17 12.23C17 13.22 16.39 14.13 15.28 14.77L12.38 16.44C11.76 16.81 11.13 16.99 10.56 16.99ZM10.56 8.96999C10.38 8.96999 10.21 9.00999 10.08 9.08999C9.69999 9.30999 9.48999 9.83999 9.48999 10.56V13.91C9.48999 14.62 9.69999 15.16 10.08 15.37C10.45 15.59 11.02 15.5 11.64 15.15L14.54 13.48C15.16 13.12 15.51 12.67 15.51 12.24C15.51 11.81 15.15 11.36 14.54 11L11.64 9.32999C11.24 9.08999 10.87 8.96999 10.56 8.96999Z"
        stroke={color}
      />
    </svg>
  );
}
