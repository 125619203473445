interface Property {
  classname?: string;
  stroke?: string;
  width?: string;
  height?: string;
}

export function TrashSVG({
  classname,
  stroke = "#757B82",
  width = "14",
  height = "14",
}: Property) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.25 3.48843C10.3075 3.29593 8.35333 3.19676 6.405 3.19676C5.25 3.19676 4.095 3.2551 2.94 3.37176L1.75 3.48843"
        stroke={stroke}
        strokeWidth="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.95825 2.89926L5.08659 2.1351C5.17992 1.58093 5.24992 1.16676 6.23575 1.16676H7.76409C8.74992 1.16676 8.82575 1.60426 8.91325 2.14093L9.04159 2.89926"
        stroke={stroke}
        strokeWidth="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.9958 5.33176L10.6167 11.2059C10.5525 12.1218 10.5 12.8334 8.87248 12.8334H5.12748C3.49998 12.8334 3.44748 12.1218 3.38332 11.2059L3.00415 5.33176"
        stroke={stroke}
        strokeWidth="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.02588 9.62509H7.96838"
        stroke={stroke}
        strokeWidth="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.54175 7.29176H8.45841"
        stroke={stroke}
        strokeWidth="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
