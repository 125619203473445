import {
  AddSVG,
  Button,
  EditSVG,
  Input,
  Modal,
  Selected,
  Spinner,
  TrashSVG,
} from "../../components";
import { Page } from "../../layouts/page";
import DataTable from "react-data-table-component";
import { FaSort } from "react-icons/fa";
import React from "react";
import { isEmpty, isNil, lowerCase } from "lodash";
import AlertConfirmation from "../../components/AlertConfirmation";
import { ActionType, RoleUser } from "../../interfaces/enum";
import { UserResponse } from "../../interfaces/interfaces";
import { formatDateMMMddyyy } from "../../../utils/utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { signUpUser } from "../../core/firebase/firebase-api";
import {
  deleteUser,
  getUsers,
  setDbUser,
  updateUser,
} from "../../core/firebase/firestore-collection";
import Badge from "../../components/Badge";

const roles = [
  {
    id: "admin",
    name: "Admin",
  },
  {
    id: "super-admin",
    name: "Super Admin",
  },
];

const gender = [
  {
    id: "male",
    name: "Masculin",
  },
  {
    id: "female",
    name: "Feminin",
  },
];

export default function UserPage() {
  const [_show, setShow] = React.useState<boolean>(false);
  const [_showAlert, setShowAlert] = React.useState<boolean>(false);
  const [_userId, setUserId] = React.useState<string>();
  const [_searchInput, setSearchInput] = React.useState<string>();
  const [_user, setUser] = React.useState<UserResponse>();
  const [_action, setAction] = React.useState<ActionType>(ActionType.POST);
  const [_loader, setLoader] = React.useState<boolean>(false);

  const [_users, setUsers] = React.useState<UserResponse[]>([]);

  const [form, _setFormReference] = React.useState<HTMLFormElement | null>(
    null
  );

  const handleButtonClick = (
    id: string,
    user: UserResponse,
    action?: boolean
  ) => {
    if (action) {
      setAction(ActionType.PATCH);
      setShow(true);
    } else {
      setShowAlert(true);
    }
    setUserId(id);
    setUser(user);
  };

  const _confirmForm = React.useCallback(() => {
    if (!isNil(form)) {
      form.requestSubmit();
    }
  }, [form]);

  const fecthUsers = React.useCallback(async () => {
    setUsers([]);
    (await getUsers()).forEach((item) => {
      const user = item.data() as UserResponse;
      setUsers((old) => [...old, user]);
    });
  }, []);
  const _onFormSubmit = React.useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();

      const target = e.target as typeof e.target & {
        name: { value: string };
        email: { value: string };
        password: { value: string };
        role: { value: string };
        gender: { value: string };
      };

      setLoader(true);

      if (_action === ActionType.POST) {
        try {
          const userSignUp = await signUpUser(
            target.email.value,
            target.password.value
          );
          if (userSignUp) {
            await setDbUser(userSignUp.user.uid, {
              id: userSignUp.user.uid,
              name: target.name.value,
              email: target.email.value,
              role: target.role.value as RoleUser,
              gender: target.gender.value,
              created_at: new Date().toString(),
            }).then(() => {
              toast("Utilisateur créer", { type: "success" });
            });
          }
        } catch (error: any) {
          toast(error.message, { type: "error" });
          setLoader((loader) => !loader);
        }
      } else {
        try {
          await updateUser(_userId!, {
            name: target.name.value,
            email: target.email.value,
            role: target.role.value as RoleUser,
            gender: target.gender.value,
          }).then(() => {
            toast("Utilisateur mise a jour", { type: "success" });
          });
        } catch (error: any) {
          toast(error.message, { type: "error" });
        }
      }
      setShow(false);
      setLoader((loader) => !loader);
      await fecthUsers();
    },
    [_action, _userId, fecthUsers]
  );

  const _removeUser = React.useCallback(
    async (id: string) => {
      setLoader(true);
      try {
        await deleteUser(id).then(async () => {
          toast("Utilisateur supprimer", { type: "success" });
          await fecthUsers();
        });
      } catch (error: any) {
        toast(error.message, { type: "error" });
      }
      setLoader((loader) => !loader);
      setShowAlert((alert) => !alert);
    },
    [fecthUsers]
  );

  const _findUsers = React.useMemo(() => {
    if (isEmpty(_searchInput)) return _users;
    return _users.filter((item) => {
      return lowerCase(item.name).includes(lowerCase(_searchInput));
    });
  }, [_searchInput, _users]);

  React.useEffect(() => {
    fecthUsers();
  }, [fecthUsers]);

  return (
    <>
      <ToastContainer />
      <Page
        left={
          <h1 className="font-bold text-2xl text-background-100">
            Utilisateurs
          </h1>
        }
      >
        <div className="flex sm:flex-row flex-col sm:gap-0 gap-5 justify-between mt-5">
          <div className="flex flex-row ">
            <Input
              block
              type="search"
              placeholder="Chercher un utilisateur"
              id="title"
              onChange={(e) => setSearchInput(e.target.value)}
              name="title"
              className="md:w-60"
            />
          </div>
          <div>
            <Button
              color="primary"
              type="button"
              icon={<AddSVG />}
              title="Nouvel Utilisateur"
              className="w-auto"
              onClick={() => {
                setShow(true);
                setAction(ActionType.POST);
              }}
            />
          </div>
        </div>

        <div className="">
          {isEmpty(_users) ? (
            <Spinner />
          ) : (
            <div className=" -mx-4 mt-8 ring-1 bg-white border-0 ring-white sm:-mx-6 md:mx-0 md:rounded-xl">
              <DataTable
                title="Rubriques"
                columns={[
                  {
                    name: "Ref.",
                    selector: "id",
                  },
                  {
                    name: "Nom",
                    selector: "name",
                  },
                  {
                    name: "Email",
                    selector: "email",
                  },
                  {
                    name: "Role",
                    selector: "role",
                    cell: (row) => {
                      return row.role === RoleUser.Super_Admin ? (
                        <Badge title={row.role} type="green" />
                      ) : (
                        <Badge title={row.role} type="blue" />
                      );
                    },
                  },
                  {
                    name: "Genre",
                    selector: "gender",
                  },
                  {
                    name: "Créer le",
                    selector: "created_at",
                    cell: (row) => {
                      return (
                        <span> {formatDateMMMddyyy(row.created_at!)} </span>
                      );
                    },
                  },
                  {
                    name: "Actions",
                    selector: "runtime",
                    right: true,
                    cell: (row) =>
                      row.email !== "admin@gmail.com" && (
                        <div className="flex flex-row gap-2 ">
                          <Button
                            onClick={() =>
                              handleButtonClick(row.id!, row, true)
                            }
                            color="success"
                            className="h-8"
                            type="button"
                            icon={<EditSVG stroke="#ffff" />}
                          />
                          <Button
                            onClick={() => handleButtonClick(row.id!, row)}
                            color="danger"
                            className="h-8"
                            type="button"
                            icon={<TrashSVG stroke="#ffff" />}
                          />
                        </div>
                      ),
                  },
                ]}
                data={_findUsers}
                defaultSortField="title"
                sortIcon={<FaSort />}
                pagination
              />
            </div>
          )}
        </div>
      </Page>
      <AlertConfirmation
        description={`Voulez vous vraiment supprimer cet utilisateur ?`}
        title="Veuillez confirmer s'il vous plait...!"
        onShow={() => setShowAlert((alert) => !alert)}
        show={_showAlert}
        loading={_loader}
        onConfirm={() => _removeUser(_userId!)}
      />
      <Modal
        show={_show}
        loading={_loader}
        onShow={() => setShow((show) => !show)}
        onConfirm={_confirmForm}
        title={
          _action === ActionType.POST
            ? "Ajouter un utilisateur"
            : "Modifier un utilisateur"
        }
      >
        <form
          className="mt-8 space-y-3"
          ref={_setFormReference}
          onSubmit={_onFormSubmit}
        >
          <div className="w-full">
            <label className="block text-sm font-mediumtext-gray-700 mb-1">
              Nom
            </label>
            <Input
              block
              type="text"
              name="name"
              defaultValue={_action === ActionType.PATCH ? _user?.name : ""}
              placeholder="Nom"
              required={true}
            />
          </div>
          <div className="w-full">
            <label className="block text-sm font-mediumtext-gray-700 mb-1">
              Email
            </label>
            <Input
              block
              type="email"
              name="email"
              defaultValue={_action === ActionType.PATCH ? _user?.email : ""}
              placeholder="user@email.com"
              required={true}
            />
          </div>
          {_action === ActionType.POST && (
            <div className="w-full">
              <label className="block text-sm font-mediumtext-gray-700 mb-1">
                Mot de passe
              </label>
              <Input
                block
                type="password"
                name="password"
                placeholder="Mot de passe"
                required={true}
              />
            </div>
          )}
          <div className="w-full">
            <label className="block text-sm font-mediumtext-gray-700 mb-1">
              Role
            </label>
            <Selected
              block
              className="h-12"
              name="role"
              defaultValue={_action === ActionType.PATCH ? _user?.role! : ""}
              required={true}
              datas={roles}
              onChange={(item) => {}}
            />
          </div>
          <div className="w-full">
            <label className="block text-sm font-mediumtext-gray-700 mb-1">
              Genre
            </label>
            <Selected
              block
              className="h-12"
              name="gender"
              defaultValue={_action === ActionType.PATCH ? _user?.gender! : ""}
              required={true}
              datas={gender}
              onChange={(item) => {}}
            />
          </div>
        </form>
      </Modal>
    </>
  );
}
