import {
  EMAIL_INPUT,
  LOGIN_ERROR,
  LOGIN_LOADING,
  LOGIN,
  PASSWORD_INPUT,
  LOGIN_SUCCESS,
} from "../types/types";

export interface StateAuth {
  email: string;
  password: string;
  isLoading: boolean;
  error: string;
}

export const initialStateAuth: StateAuth = {
  email: "",
  password: "",
  isLoading: false,
  error: "",
};

const reducerAuth = (state = initialStateAuth, action: any) => {
  switch (action.type) {
    case EMAIL_INPUT:
      return {
        ...state,
        email: action.payload,
      };
    case PASSWORD_INPUT:
      return {
        ...state,
        password: action.payload,
      };
    case LOGIN_LOADING:
      return {
        ...state,
        isLoading: action.payload,
        error: "",
      };
    case LOGIN_SUCCESS:
      window.location.href = "/dashboard";
      return {
        ...state,
        isLoading: false,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case LOGIN:
      return {
        ...state,
        email: "axel.deffo",
        password: "1234",
      };

    default:
      return state;
  }
};

export default reducerAuth;
