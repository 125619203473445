import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import NoFoundPage from "./app/pages/404/noFoundPage";
import LoginPage from "./app/pages/auth/loginPage";
import Dashboard from "./app/pages/dashboard/dashboard";
import RubricsPage from "./app/pages/rubrics/rubricsPage";
import UserPage from "./app/pages/user/userPage";
import AstuceSante from "./site/astuceSante";
import Home from "./site/home";
import MagazineDetail from "./site/magazineDetail";
import Sante from "./site/sante";
import BusinessPage from "./site/business";
import { Provider } from "react-redux";
import store from "./redux/store";
import NewMagazinePage from "./app/pages/maagazine/newMagazinePage";
import MagazinePage from "./app/pages/maagazine/magazinePage";
import SpotlightPage from "./app/pages/maagazine/spotlightPage";
import EmagazinePage from "./app/pages/eMagazine/emagazinePage";
import Innovation from "./site/innovation";
import Portrait from "./site/portrait";
import PortraitPage from "./app/pages/portrait/portraitPage";
import { RoleUser } from "./app/interfaces/enum";
import { AuthProvider } from "./app/context/auth-context";
import { ProtectedRoute } from "./routes/protectedRoute";
import { LoginRedirection } from "./routes/loginRediection";
import VideoPage from "./app/pages/videos/vodeoPage";
import AboutUs from "./site/aboutUs";
import SciencePage from "./app/pages/science/sciencePage";
import NewScience from "./app/pages/science/newScience";
import Science from "./site/science";
import ScienceDetail from "./site/scienceDetail";
import EMagazines from "./site/eMagazines";
import SearchResult from "./site/searchResult";
import SocietePage from "./site/societe";

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route index={true} element={<Home />} />
            <Route path="/actu-sante" element={<Sante />} />
            <Route path="/astu-sante" element={<AstuceSante />} />
            <Route path="/innovation" element={<Innovation />} />
            <Route path="/business" element={<BusinessPage />} />
            <Route path="/societe" element={<SocietePage />} />
            <Route path="/e-magazine" element={<EMagazines />} />
            <Route path="/portrait" element={<Portrait />} />
            <Route path="/science" element={<Science />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/search-result" element={<SearchResult />} />
            <Route path="/magazine-detail/:id" element={<MagazineDetail />} />
            <Route path="/science-detail/:id" element={<ScienceDetail />} />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute role={[RoleUser.Admin, RoleUser.Super_Admin]}>
                  <Dashboard />
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path="/dashboard/rubrics"
              element={
                <ProtectedRoute role={[RoleUser.Super_Admin]}>
                  <RubricsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/magazines"
              element={
                <ProtectedRoute role={[RoleUser.Admin, RoleUser.Super_Admin]}>
                  <MagazinePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/e-magazines"
              element={
                <ProtectedRoute role={[RoleUser.Admin, RoleUser.Super_Admin]}>
                  <EmagazinePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/new-magazines"
              element={
                <ProtectedRoute role={[RoleUser.Admin, RoleUser.Super_Admin]}>
                  <NewMagazinePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/spotlights"
              element={
                <ProtectedRoute role={[RoleUser.Admin, RoleUser.Super_Admin]}>
                  <SpotlightPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/science"
              element={
                <ProtectedRoute role={[RoleUser.Admin, RoleUser.Super_Admin]}>
                  <SciencePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/new-science-article"
              element={
                <ProtectedRoute role={[RoleUser.Admin, RoleUser.Super_Admin]}>
                  <NewScience />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/app-portrait"
              element={
                <ProtectedRoute role={[RoleUser.Admin, RoleUser.Super_Admin]}>
                  <PortraitPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/videos"
              element={
                <ProtectedRoute role={[RoleUser.Admin, RoleUser.Super_Admin]}>
                  <VideoPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/users"
              element={
                <ProtectedRoute role={[RoleUser.Super_Admin]}>
                  <UserPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/login"
              element={
                <LoginRedirection>
                  <LoginPage />
                </LoginRedirection>
              }
            />
            <Route path="*" element={<NoFoundPage />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
