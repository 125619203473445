import React from "react";

import {
  AddSVG,
  Button,
  Input,
  Spinner,
  SwitchSVG,
  TrashSVG,
} from "../../components";
import { Page } from "../../layouts/page";
import DataTable from "react-data-table-component";
import { FaSort } from "react-icons/fa";
import { isEmpty, lowerCase } from "lodash";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { ArticleResponse, RubricResponse } from "../../interfaces/interfaces";
import { formatDateMMMddyyy } from "../../../utils/utils";
import Badge from "../../components/Badge";
import AlertConfirmation from "../../components/AlertConfirmation";
import {
  deleteArticle,
  getArticles,
  getRubrics,
  updateArticle,
} from "../../core/firebase/firestore-collection";

export default function MagazinePage() {
  const [_searchInput, setSearchInput] = React.useState<string>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setShow] = React.useState<boolean>(false);
  const [_articles, setArticles] = React.useState<ArticleResponse[]>([]);
  const [_showAlert, setShowAlert] = React.useState<boolean>(false);
  const [_loader, setLoader] = React.useState<boolean>(false);
  const [_articleId, setArticleId] = React.useState<string>();
  const [_loaderArticle, setLoaderArticle] = React.useState<boolean>(false);
  const [_rubrics, seRubrics] = React.useState<RubricResponse[]>([]);

  let navigate = useNavigate();

  const _handleButtonClick = (id: string) => {
    setArticleId(id);
    setShowAlert(true);
  };

  const _findArticles = React.useMemo(() => {
    if (isEmpty(_searchInput)) return _articles;

    return _articles.filter((item) => {
      return (
        lowerCase(item.author).includes(lowerCase(_searchInput)) ||
        lowerCase(item.articleTitle).includes(lowerCase(_searchInput))
      );
    });
  }, [_searchInput, _articles]);

  const fecthArticles = React.useCallback(async () => {
    setArticles([]);
    setLoaderArticle(true);
    (await getArticles()).forEach((item) => {
      const article = item.data() as ArticleResponse;
      setArticles((old) => [...old, article]);
    });

    // onSnapshot(await getArticlesPaginate(2), (querySnapshot) => {
    //   return querySnapshot.forEach((doc) => {
    //     setArticles((old) => [...old, doc.data() as ArticleResponse]);
    //   });
    // });
    setLoaderArticle(false);
  }, []);

  const getRubric = React.useCallback(async () => {
    (await getRubrics()).forEach((item) => {
      const rubric = item.data() as RubricResponse;
      seRubrics((old) => [...old, rubric]);
    });
  }, []);

  const _handleUpdateClick = React.useCallback(
    async (id: string, status: boolean) => {
      try {
        await updateArticle(id!, {
          status: !status,
        }).then(async () => {
          toast("Status mise a jour", { type: "success" });
          setShow(false);
          await fecthArticles();
        });
      } catch (error: any) {
        toast(error.message, { type: "error" });
      }
    },
    [fecthArticles]
  );

  const _handleUpdateSpotlightClick = React.useCallback(
    async (id: string, spotlight: boolean) => {
      try {
        await updateArticle(id!, {
          spotlight: !spotlight,
        }).then(async () => {
          toast("Opération effectuer", { type: "success" });
          setShow(false);
          await fecthArticles();
        });
      } catch (error: any) {
        toast(error.message, { type: "error" });
      }
    },
    [fecthArticles]
  );

  const _removeArticle = React.useCallback(
    async (id: string) => {
      setLoader(true);
      try {
        await deleteArticle(id).then(async () => {
          toast("Magazine supprimer", { type: "success" });
          await fecthArticles();
        });
      } catch (error: any) {
        toast(error.message, { type: "error" });
      }
      setLoader((loader) => !loader);
      setShowAlert((alert) => !alert);
    },
    [fecthArticles]
  );

  React.useEffect(() => {
    getRubric();
    fecthArticles();
  }, [fecthArticles, getRubric]);
  return (
    <>
      <ToastContainer />
      <Page
        left={
          <h1 className="font-bold text-2xl text-background-100">Magazines</h1>
        }
      >
        {_loaderArticle ? (
          <Spinner />
        ) : (
          <>
            <div className="flex sm:flex-row flex-col sm:gap-0 gap-5 justify-between mt-5">
              <div className="flex flex-row ">
                <Input
                  block
                  type="search"
                  placeholder="Chercher un magazine"
                  id="title"
                  onChange={(e) => setSearchInput(e.target.value)}
                  name="title"
                  className="md:w-60"
                />
              </div>
              <div className="flex">
                <Button
                  color="primary"
                  type="button"
                  icon={<AddSVG />}
                  title="Nouveau Magazine"
                  className="w-auto"
                  onClick={() => navigate("/dashboard/new-magazines")}
                />
              </div>
            </div>
            <div className=" -mx-4 mt-8 ring-1 bg-white border-0 ring-white sm:-mx-6 md:mx-0 md:rounded-xl">
              <DataTable
                title="Liste des magazines"
                columns={[
                  {
                    name: "Ref",
                    selector: "id",
                  },
                  {
                    name: "Titre",
                    selector: "articleTitle",
                  },
                  {
                    name: "Photo de coverture",
                    selector: "articlePic",
                    cell: (row) => {
                      return (
                        <img
                          alt="profil"
                          src={
                            row.articlePic
                              ? row.articlePic
                              : "../assets/svg/logo.svg"
                          }
                          className="w-16 h-16 rounded-md object-cover my-2"
                        />
                      );
                    },
                  },
                  {
                    name: "Rubric",
                    selector: "rubric",
                    cell: (row) => {
                      const name = _rubrics.find(
                        (item) => item.id === row.rubricId
                      )?.name;
                      return <Badge title={name!} type="gray" />;
                    },
                  },
                  {
                    name: "Auteur",
                    selector: "author",
                  },
                  {
                    name: "Date de publication",
                    selector: "created_at",
                    cell: (row) => {
                      return (
                        <span> {formatDateMMMddyyy(row.created_at)} </span>
                      );
                    },
                  },
                  {
                    name: "Statut",
                    selector: "status",
                    cell: (row) => {
                      return (
                        <span>
                          {row.status ? (
                            <Button
                              onClick={() =>
                                _handleUpdateClick(row.id, row.status)
                              }
                              color="success"
                              className="h-8"
                              type="button"
                              title="En ligne"
                              icon={<SwitchSVG stroke="#ffff" />}
                            />
                          ) : (
                            <Button
                              onClick={() =>
                                _handleUpdateClick(row.id, row.status)
                              }
                              color="danger"
                              className="h-8"
                              type="button"
                              title="Hors ligne"
                              icon={<SwitchSVG stroke="#ffff" />}
                            />
                          )}
                        </span>
                      );
                    },
                  },

                  {
                    name: "Actions",
                    selector: "runtime",
                    right: true,
                    cell: (row) => (
                      <div className="flex flex-row gap-2 ">
                        <Button
                          onClick={() =>
                            _handleUpdateSpotlightClick(row.id, row.spotlight)
                          }
                          color={!row.spotlight ? "natural" : "success"}
                          className="h-8"
                          type="button"
                          title={
                            !row.spotlight ? "Mettre a la une" : "A la une"
                          }
                        />
                        <Button
                          onClick={() => _handleButtonClick(row.id)}
                          color="danger"
                          className="h-8"
                          type="button"
                          tooltip="Supprimer le magazine"
                          icon={<TrashSVG stroke="#ffff" />}
                        />
                      </div>
                    ),
                  },
                ]}
                data={_findArticles}
                defaultSortField="title"
                sortIcon={<FaSort />}
                pagination
              />
            </div>
          </>
        )}
      </Page>
      <AlertConfirmation
        description={`La suppression de ce magazine elle n'apparaitra plus sur le site`}
        title="Veuillez confirmer s'il vous plait...!"
        onShow={() => setShowAlert((alert) => !alert)}
        show={_showAlert}
        loading={_loader}
        onConfirm={() => _removeArticle(_articleId!)}
      />
    </>
  );
}
