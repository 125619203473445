import classNames from "classnames";
import { PortraitResponse } from "../../app/interfaces/interfaces";

interface PortraitProps {
  width?: string;
  portrait: PortraitResponse;
}
export function PortraitCard({ portrait, width = "md:w-50" }: PortraitProps) {
  return (
    <div>
      <div className={classNames("flex flex-col", width)}>
        <div className="w-full h-56">
          <img
            src={portrait.picture}
            alt=""
            className="w-full h-full object-cover"
          />
        </div>
        <div className="bg-natural-99 px-2 py-3">
          <span className="text-sm md:text-xl font-semibold text-white">
            {portrait.name}
          </span>
          <div className="flex flex-row items-center gap-2">
            <svg
              width="23"
              height="2"
              viewBox="0 0 23 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="0.826172"
                y1="1.49868"
                x2="22.8262"
                y2="1.49868"
                stroke="white"
                strokeWidth="1.00265"
              />
            </svg>

            <span className="text-xs font-normal text-white">
              {portrait.description}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
