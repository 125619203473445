export function PortraitSVG({ ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.66667 1C2.64167 1 1 2.64167 1 4.66667C1 6.69167 2.64167 8.33333 4.66667 8.33333C6.69167 8.33333 8.33333 6.69167 8.33333 4.66667C8.33333 2.64167 6.69167 1 4.66667 1ZM4.66667 2.33333C5.219 2.33333 5.66667 2.781 5.66667 3.33333C5.66667 3.88567 5.219 4.33333 4.66667 4.33333C4.11433 4.33333 3.66667 3.88567 3.66667 3.33333C3.66667 2.781 4.11433 2.33333 4.66667 2.33333ZM6.32467 6.753C5.86933 7.11533 5.29367 7.33333 4.66667 7.33333C4.03967 7.33333 3.464 7.11533 3.00867 6.753C3.007 6.72367 3 6.69633 3 6.66667C3 5.746 3.74633 5 4.66667 5C5.587 5 6.33333 5.746 6.33333 6.66667C6.33333 6.69633 6.32633 6.72367 6.32467 6.753Z"
        fill="currentColor"
      />
      <path
        d="M15.3333 11.6665C13.3083 11.6665 11.6667 13.3082 11.6667 15.3332C11.6667 17.3582 13.3083 18.9998 15.3333 18.9998C17.3583 18.9998 19 17.3582 19 15.3332C19 13.3082 17.3583 11.6665 15.3333 11.6665ZM15.3333 12.9998C15.8857 12.9998 16.3333 13.4475 16.3333 13.9998C16.3333 14.5522 15.8857 14.9998 15.3333 14.9998C14.781 14.9998 14.3333 14.5522 14.3333 13.9998C14.3333 13.4475 14.781 12.9998 15.3333 12.9998ZM16.9913 17.4195C16.536 17.7818 15.9603 17.9998 15.3333 17.9998C14.7063 17.9998 14.1307 17.7818 13.6753 17.4195C13.6737 17.3902 13.6667 17.3628 13.6667 17.3332C13.6667 16.4125 14.413 15.6665 15.3333 15.6665C16.2537 15.6665 17 16.4125 17 17.3332C17 17.3628 16.993 17.3902 16.9913 17.4195Z"
        fill="currentColor"
      />
      <path
        d="M6.93532 14.0513C5.87399 14.3109 4.93866 14.6656 4.19266 15.0019C5.25432 16.2333 6.69966 17.1229 8.34432 17.4853C7.92866 16.7179 7.36166 15.5206 6.93532 14.0513Z"
        fill="currentColor"
      />
      <path
        d="M11.6557 2.51465C12.0717 3.28198 12.6383 4.47932 13.065 5.94865C14.1263 5.68898 15.0617 5.33432 15.8077 4.99798C14.7457 3.76665 13.3 2.87665 11.6557 2.51465Z"
        fill="currentColor"
      />
      <path
        d="M12.182 11.8985C12.277 11.2912 12.3333 10.6555 12.3333 9.99988C12.3333 9.12588 12.233 8.28722 12.0753 7.50488C11.4127 7.60655 10.7173 7.66655 9.99999 7.66655C9.42499 7.66655 8.86566 7.62655 8.32466 7.55888C8.17066 7.75322 8.00099 7.93388 7.81799 8.10122C7.72299 8.70855 7.66666 9.34422 7.66666 9.99988C7.66666 10.8739 7.76699 11.7125 7.92466 12.4949C8.58732 12.3932 9.28266 12.3332 9.99999 12.3332C10.575 12.3332 11.1343 12.3732 11.6753 12.4409C11.8293 12.2462 11.9993 12.0659 12.182 11.8985Z"
        fill="currentColor"
      />
      <path
        d="M9.99999 2.3335C9.99999 2.3335 9.65632 2.84083 9.23265 3.70383C9.29799 4.0145 9.33332 4.3365 9.33332 4.66683C9.33332 5.23883 9.22532 5.7845 9.03699 6.29116C9.35265 6.31716 9.67332 6.3335 9.99999 6.3335C10.6023 6.3335 11.1873 6.28483 11.7497 6.20416C11.0583 3.89283 9.99999 2.3335 9.99999 2.3335Z"
        fill="currentColor"
      />
      <path
        d="M10.6667 15.3332C10.6667 14.7612 10.7747 14.2152 10.963 13.7088C10.6473 13.6828 10.3267 13.6665 10 13.6665C9.39767 13.6665 8.81267 13.7152 8.25034 13.7958C8.94167 16.1072 10 17.6665 10 17.6665C10 17.6665 10.3437 17.1592 10.7673 16.2962C10.702 15.9855 10.6667 15.6635 10.6667 15.3332Z"
        fill="currentColor"
      />
      <path
        d="M6.37568 9.00594C5.84601 9.21494 5.27068 9.33327 4.66668 9.33327C3.85868 9.33327 3.09901 9.1276 2.43668 8.7666C2.37101 9.16827 2.33334 9.5796 2.33334 9.99994C2.33334 11.4236 2.72268 12.7563 3.39901 13.8986C4.24601 13.5013 5.34768 13.0673 6.61701 12.7569C6.44368 11.8949 6.33334 10.9683 6.33334 9.99994C6.33334 9.66227 6.35101 9.33227 6.37568 9.00594Z"
        fill="currentColor"
      />
      <path
        d="M13.6243 10.9942C14.154 10.7852 14.7293 10.6669 15.3333 10.6669C16.1413 10.6669 16.901 10.8726 17.5633 11.2336C17.629 10.8319 17.6667 10.4206 17.6667 10.0002C17.6667 8.57656 17.2773 7.2439 16.601 6.10156C15.754 6.4989 14.6523 6.9329 13.383 7.24323C13.5563 8.10523 13.6667 9.0319 13.6667 10.0002C13.6667 10.3379 13.649 10.6679 13.6243 10.9942Z"
        fill="currentColor"
      />
    </svg>
  );
}
