import { Link } from "react-router-dom";
import { ArticleResponse } from "../../app/interfaces/interfaces";
import { Blockright } from "../layouts/blockRight";

interface BusinessProps {
  business: ArticleResponse[];
  title?: string;
}

export function Business({ business, title = "Business" }: BusinessProps) {
  const shuffle = (arr: any[]) => [...arr].sort(() => Math.random() - 0.5);

  return (
    <Blockright title={title}>
      {shuffle(business)
        .slice(0, 3)
        .map((item, i) => (
          <Link key={i} to={`/magazine-detail/${item.id}`}>
            <div className="flex items-start px-4 gap-2">
              <div className="h-6 px-2 text-white bg-primary-100 ">{i + 1}</div>
              <h4 className="text-black font-medium text-base">
                {item.articleTitle}
              </h4>
            </div>
          </Link>
        ))}
    </Blockright>
  );
}
