import React from "react";
import { AuthContext } from "../app/context/auth-context";
import { useNavigate } from "react-router-dom";

export const LoginRedirection = ({ children }: React.PropsWithChildren) => {
  const { currentUser } = React.useContext(AuthContext);
  const navigate = useNavigate();
  React.useEffect(() => {
    if (currentUser) {
      navigate("/dashboard");
    }
  }, [currentUser, navigate]);

  return <React.Fragment>{children}</React.Fragment>;
};
