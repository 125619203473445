import React from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import {
  ARTICLES_COVER_PATH,
  SCIENCE_DOC_PATH,
} from "../../core/firebase/constantes";
import {
  setDbScience,
  updateScience,
  uploadImage,
} from "../../core/firebase/firestore-collection";
import { ToastContainer, toast } from "react-toastify";
import { getDownloadURL } from "firebase/storage";
import { Page } from "../../layouts/page";
import { Button, Input, Selected } from "../../components";
import Editor from "../../components/Editor";
import AlertConfirmation from "../../components/AlertConfirmation";
import { isEmpty, isNil } from "lodash";
import { getRandomFileName } from "../../../utils/utils";

const domaine = [
  {
    id: "chimie",
    name: "Chimie",
  },
  {
    id: "Biologie",
    name: "Biologie",
  },
  {
    id: "Ecologie et environnement",
    name: "Ecologie et environnement",
  },
  {
    id: "Homme et société",
    name: "Homme et société",
  },
  {
    id: "Ingénierie et systèmes",
    name: "Ingénierie et systèmes",
  },
  {
    id: "Mathématiques",
    name: "Mathématiques",
  },
  {
    id: "Nucléaire et particules",
    name: "Nucléaire et particules",
  },
  {
    id: "Physique",
    name: "Physique",
  },
  {
    id: "Sciences de l’information",
    name: "Sciences de l’information",
  },
  {
    id: "Terre et Univers",
    name: "Terre et Univers",
  },
  {
    id: "Science",
    name: "Science",
  },
  {
    id: "Médecine",
    name: "Médecine",
  },
  {
    id: "Découvertes",
    name: "Découvertes",
  },
];

export default function NewScience() {
  let navigate = useNavigate();
  const [_loader, setLoader] = React.useState<boolean>(false);
  const [_content, setContent] = React.useState<Record<string, any>>({});
  const [_showAlert, setShowAlert] = React.useState<boolean>(false);
  const [progresspercent, setProgresspercent] = React.useState<number>(0);
  const [author, setAuthor] = React.useState<string>("");
  const [authorList, setAuthorlist] = React.useState<string[]>([]);

  const _formSubmit = React.useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();

      const target = e.target as typeof e.target & {
        articleTitle: { value: string };
        category: { value: string };
        author: { value: string };
      };

      const id: string = uuid();
      //@ts-ignore
      const file = e.target[0]?.files[0];

      //@ts-ignore
      const fileDoc = e.target[3]?.files[0];
      const fileName = getRandomFileName(file.name);

      const uploadTask = uploadImage(ARTICLES_COVER_PATH, fileName, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          setLoader(true);
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgresspercent(percent);
        },
        (error: any) => toast(error.message, { type: "error" }),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (url: string) => {
            try {
              await setDbScience(id, {
                id: id,
                articleTitle: target.articleTitle.value,
                articlePic: url,
                articlePicName: fileName,
                category: target.category.value,
                authors: authorList,
                content: JSON.stringify({ content: _content }),
                created_at: new Date().toString(),
              }).then(() => {
                setShowAlert(true);
                toast("Article ajouter", { type: "success" });
              });
            } catch (error: any) {
              toast(error.message, { type: "error" });
            }
            setLoader(false);
          });
        }
      );
      if (!isNil(fileDoc)) {
        const fileDocName = getRandomFileName(fileDoc.name);

        const uploadTask = uploadImage(SCIENCE_DOC_PATH, fileDocName, fileDoc);

        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          (error: any) => toast(error.message, { type: "error" }),
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then(
              async (url: string) => {
                try {
                  await updateScience(id, {
                    file: url,
                    fileName: fileDocName,
                  });
                } catch (error: any) {
                  toast(error.message, { type: "error" });
                }
                setLoader(false);
              }
            );
          }
        );
      }
      console.log(fileDoc);
    },
    [_content, authorList]
  );

  const handleKeywordKeyPress = React.useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        if (isEmpty(author)) {
          return;
        }
        setAuthorlist([...authorList, author]);
        setAuthor("");
      }
    },
    [author, authorList]
  );

  return (
    <>
      <ToastContainer />
      <Page
        left={
          <h1 className="font-bold text-2xl text-background-100">
            Nouvel article de science
          </h1>
        }
        right={
          <div>
            <Button
              color="outline-success"
              type="button"
              title="Retour"
              className="w-auto"
              onClick={() => navigate("/dashboard/science")}
            />
          </div>
        }
      >
        <div className=" -mx-4 mt-8 ring-1 bg-white border-0 ring-white sm:-mx-6 md:mx-0 md:rounded-xl py-10">
          <form onSubmit={_formSubmit}>
            <div className="flex flex-col justify-center px-16">
              <div className="grid grid-cols-2 gap-4">
                <div className="mb-3">
                  <label
                    htmlFor="formFile"
                    className="block text-base font-medium text-slate-700 mb-2"
                  >
                    Photo de présentation
                    <span className="text-danger-100">*</span>
                  </label>
                  <Input
                    required={true}
                    block
                    type="file"
                    accept=".jpef, .png, .jpg"
                  />
                </div>
                <div>
                  <label className="block text-base font-medium text-slate-700 mb-2">
                    Titre du magazine<span className="text-danger-100">*</span>
                  </label>
                  <Input
                    block
                    type="text"
                    name="articleTitle"
                    placeholder="Titre de l'article"
                    required={true}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4 py-4">
                <div>
                  <label className="block text-base font-medium text-slate-700 mb-1">
                    Choisir le domaine de recherche
                    <span className="text-danger-100">*</span>
                  </label>
                  <Selected
                    block
                    className="h-12"
                    name="category"
                    required={true}
                    datas={domaine}
                    onChange={(item) => console.log(item.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="formFile"
                    className="block text-base font-medium text-slate-700 mb-2"
                  >
                    Joindre un document
                  </label>
                  <Input block type="file" accept=".pdf, .docx" />
                </div>
              </div>
              <div
                className="p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400"
                role="alert"
              >
                <span className="font-medium">Info alert!</span> Appuyez sur la
                touche "entrer" apres avoir saisie pour valider
              </div>
              <div className="flex items-end gap-x-4 mb-3">
                <div>
                  <label className="block text-base font-medium text-slate-700 mb-1">
                    Ajouter un auteur
                    <span className="text-danger-100">*</span>
                  </label>
                  <Input
                    type="text"
                    value={author}
                    onKeyDown={handleKeywordKeyPress}
                    onChange={(event) => setAuthor(event.target.value)}
                  />
                </div>
                <div>
                  {!isEmpty(authorList) &&
                    authorList.map((author) => {
                      return (
                        <span className="bg-blue-100 text-blue-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
                          {author}
                        </span>
                      );
                    })}
                </div>
              </div>

              <Editor returnValue={(value) => setContent(value)} />
              <div className="flex justify-center">
                {_loader ? (
                  <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
                    <div
                      className="bg-primary-100 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
                      style={{ width: `${progresspercent}%` }}
                    >
                      {progresspercent}%
                    </div>
                  </div>
                ) : (
                  <div>
                    <Button
                      color="primary"
                      type="submit"
                      title="Enregistrer l'article"
                      className="mt-7 w-auto"
                    />
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      </Page>
      <AlertConfirmation
        description={`L'article a bien été créer.`}
        title="Opération terminée...!"
        onShow={() => setShowAlert((alert) => !alert)}
        show={_showAlert}
        simple={true}
        onConfirm={() => navigate("/dashboard/science")}
      />
    </>
  );
}
