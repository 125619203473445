import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Spinner } from "../app/components";
import {
  getRubrics,
  searchArticles,
} from "../app/core/firebase/firestore-collection";
import { ArticleResponse, RubricResponse } from "../app/interfaces/interfaces";
import { InnovationGroup, LineHealthFullSVG } from "./components";
import Footer from "./layouts/footer";
import Header from "./layouts/header";

export default function SearchResult() {
  let { search } = useLocation();
  const navigate = useNavigate();
  const [_rubrics, seRubrics] = React.useState<RubricResponse[]>([]);
  const [_loader, setLoader] = React.useState<boolean>(false);
  const [_articles, setArticles] = React.useState<ArticleResponse[]>([]);

  const query = new URLSearchParams(search);
  const param = query.get("key");

  const getRubric = React.useCallback(async () => {
    (await getRubrics()).forEach((item) => {
      const rubric = item.data() as RubricResponse;
      seRubrics((old) => [...old, rubric]);
    });
  }, []);

  const fecthArticles = React.useCallback(async () => {
    setArticles([]);
    setLoader(true);
    (await searchArticles(param!)).forEach((doc) => {
      setArticles((old) => [...old, doc.data() as ArticleResponse]);
    });

    setLoader(false);
  }, [param]);

  React.useEffect(() => {
    getRubric();
    fecthArticles();
  }, [fecthArticles, getRubric]);
  return (
    <>
      <Header />
      {_loader ? (
        <Spinner />
      ) : (
        <div className="m-0 text-base bg-body_background antialiased font-normal leading-default text-slate-500">
          <button
            onClick={() => navigate(-1)}
            className="m-4 md:inline-flex gap-8 text-black items-center bg-primary-100 border-0 py-4 px-10 focus:outline-none hover:bg-primary-100"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.0899 3.33047C15.2799 3.33047 15.4699 3.40047 15.6199 3.55047C15.9099 3.84047 15.9099 4.32047 15.6199 4.61047L9.0999 11.1305C8.6199 11.6105 8.6199 12.3905 9.0999 12.8705L15.6199 19.3905C15.9099 19.6805 15.9099 20.1605 15.6199 20.4505C15.3299 20.7405 14.8499 20.7405 14.5599 20.4505L8.0399 13.9305C7.5299 13.4205 7.2399 12.7305 7.2399 12.0005C7.2399 11.2705 7.5199 10.5805 8.0399 10.0705L14.5599 3.55047C14.7099 3.41047 14.8999 3.33047 15.0899 3.33047Z"
                fill="#FFFF"
              />
            </svg>
          </button>
          <div className="w-full lg:container mt-4 bg-white mx-auto flex md:flex-wrap p-2 md:p-5 flex-row justify-between items-center">
            {_articles.length > 0 ? (
              <div className="flex flex-col w-full">
                <h1 className="text-3xl font-bold  mb-4 md:mb-0">
                  Resultat(s) de recherche{" "}
                  <span className="text-primary-100"> "{param}"</span>
                </h1>
                <div className="md:my-4">
                  <LineHealthFullSVG width="100%" />
                </div>
                {_articles.map((item, i) => (
                  <>
                    <InnovationGroup
                      rubricName={
                        _rubrics.find((r) => r.id === item.rubricId)?.name!
                      }
                      magazine={item}
                    />
                    <div className="my-4">
                      <LineHealthFullSVG width="100%" />
                    </div>
                  </>
                ))}
              </div>
            ) : (
              <div className="h-24 flex justify-center items-center w-full">
                <p className="w-full text-lg text-center">
                  Aucun articles trouver
                </p>
              </div>
            )}
          </div>
          <Footer />
        </div>
      )}
    </>
  );
}
