interface Property {
  color: string;
  classname?: string;
}

export function MagazineSVG({ color, classname }: Property) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classname}
    >
      <path
        d="M16.3799 22.75H3.23993C2.28993 22.75 1.40993 22.31 0.839932 21.54C0.259932 20.76 0.0899403 19.78 0.37994 18.85L4.58993 5.31995C4.96993 4.05995 6.12995 3.19995 7.44995 3.19995H19.7499C20.9599 3.19995 22.0499 3.92004 22.5099 5.04004C22.7599 5.62004 22.8099 6.28005 22.6599 6.93005L19.2899 20.46C18.9699 21.81 17.7699 22.75 16.3799 22.75ZM7.45993 4.70996C6.80993 4.70996 6.21993 5.14002 6.02993 5.77002L1.81994 19.3C1.67994 19.77 1.75993 20.26 2.05993 20.66C2.33993 21.04 2.77994 21.26 3.24994 21.26H16.3899C17.08 21.26 17.6799 20.79 17.8399 20.12L21.2099 6.57996C21.2899 6.24996 21.2699 5.92 21.1399 5.63C20.9 5.06 20.3699 4.69995 19.7599 4.69995H7.45993V4.70996Z"
        fill={color}
      />
      <path
        d="M20.78 22.75H16C15.59 22.75 15.25 22.41 15.25 22C15.25 21.59 15.59 21.25 16 21.25H20.78C21.19 21.25 21.57 21.08 21.85 20.78C22.13 20.48 22.27 20.08 22.24 19.67L21.25 6.05002C21.22 5.64002 21.53 5.27997 21.94 5.24997C22.35 5.22997 22.71 5.52991 22.74 5.93991L23.73 19.56C23.79 20.38 23.5 21.2 22.94 21.8C22.39 22.41 21.6 22.75 20.78 22.75Z"
        fill={color}
      />
      <path
        d="M9.67977 7.12996C9.61977 7.12996 9.55977 7.11994 9.49977 7.10994C9.09977 7.00994 8.84979 6.6099 8.94979 6.1999L9.98976 1.87996C10.0898 1.47996 10.4898 1.22991 10.8998 1.32991C11.2998 1.42991 11.5498 1.82994 11.4498 2.23994L10.4098 6.56001C10.3298 6.90001 10.0198 7.12996 9.67977 7.12996Z"
        fill={color}
      />
      <path
        d="M16.3799 7.13999C16.3299 7.13999 16.2699 7.13997 16.2199 7.11997C15.8199 7.02997 15.56 6.62995 15.64 6.22995L16.5799 1.88999C16.6699 1.47999 17.0699 1.23003 17.4699 1.31003C17.8699 1.39003 18.1299 1.79992 18.0499 2.19992L17.1099 6.54001C17.0399 6.90001 16.7299 7.13999 16.3799 7.13999Z"
        fill={color}
      />
      <path
        d="M15.7002 12.75H7.7002C7.2902 12.75 6.9502 12.41 6.9502 12C6.9502 11.59 7.2902 11.25 7.7002 11.25H15.7002C16.1102 11.25 16.4502 11.59 16.4502 12C16.4502 12.41 16.1102 12.75 15.7002 12.75Z"
        fill={color}
      />
      <path
        d="M14.7002 16.75H6.7002C6.2902 16.75 5.9502 16.41 5.9502 16C5.9502 15.59 6.2902 15.25 6.7002 15.25H14.7002C15.1102 15.25 15.4502 15.59 15.4502 16C15.4502 16.41 15.1102 16.75 14.7002 16.75Z"
        fill={color}
      />
    </svg>
  );
}
