import { getDownloadURL } from "firebase/storage";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Input, Selected } from "../../components";
import AlertConfirmation from "../../components/AlertConfirmation";
import Editor from "../../components/Editor";
import { ARTICLES_COVER_PATH } from "../../core/firebase/constantes";
import {
  getRubrics,
  setDbArticle,
  uploadImage,
} from "../../core/firebase/firestore-collection";
import { RubricResponse } from "../../interfaces/interfaces";
import { Page } from "../../layouts/page";
import { v4 as uuid } from "uuid";

export default function NewMagazinePage() {
  let navigate = useNavigate();
  const [_rubrics, setRubrics] = React.useState<RubricResponse[]>([]);
  const [_loader, setLoader] = React.useState<boolean>(false);
  const [_content, setContent] = React.useState<Record<string, any>>({});
  const [_showAlert, setShowAlert] = React.useState<boolean>(false);
  const [progresspercent, setProgresspercent] = React.useState<number>(0);

  const fecthRubrics = React.useCallback(async () => {
    setRubrics([]);
    setLoader(true);
    (await getRubrics()).forEach((item) => {
      const rubric = item.data() as RubricResponse;
      setRubrics((old) => [...old!, rubric]);
    });
    setLoader(false);
  }, []);

  React.useEffect(() => {
    fecthRubrics();
  }, [fecthRubrics]);

  const _formSubmit = React.useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();

      const target = e.target as typeof e.target & {
        articleTitle: { value: string };
        rubricId: { value: string };
        author: { value: string };
      };

      const id: string = uuid();
      //@ts-ignore
      const file = e.target[0]?.files[0];

      const uploadTask = uploadImage(ARTICLES_COVER_PATH, file.name, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          setLoader(true);
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgresspercent(percent);
        },
        (error: any) => toast(error.message, { type: "error" }),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (url: string) => {
            try {
              await setDbArticle(id, {
                id: id,
                articleTitle: target.articleTitle.value,
                articlePic: url,
                rubricId: target.rubricId.value,
                content: JSON.stringify({ content: _content }),
                status: false,
                created_at: new Date().toString(),
                spotlight: false,
              }).then(() => {
                setShowAlert(true);
                toast("Magazine créer", { type: "success" });
              });
            } catch (error: any) {
              toast(error.message, { type: "error" });
            }
            setLoader(false);
          });
        }
      );
    },
    [_content]
  );

  return (
    <>
      <ToastContainer />
      <Page
        left={
          <h1 className="font-bold text-2xl text-background-100">
            Nouveau magazine
          </h1>
        }
        right={
          <div>
            <Button
              color="outline-success"
              type="button"
              title="Retour"
              className="w-auto"
              onClick={() => navigate("/dashboard/magazines")}
            />
          </div>
        }
      >
        <div className=" -mx-4 mt-8 ring-1 bg-white border-0 ring-white sm:-mx-6 md:mx-0 md:rounded-xl py-10">
          <form onSubmit={_formSubmit}>
            <div className="flex flex-col justify-center px-16">
              <div className="grid grid-cols-2 gap-4">
                <div className="mb-3">
                  <label
                    htmlFor="formFile"
                    className="block text-base font-medium text-slate-700 mb-2"
                  >
                    Photo de présentation
                  </label>
                  <Input block type="file" accept=".jpef, .png, .jpg" />
                </div>
                <div>
                  <label className="block text-base font-medium text-slate-700 mb-2">
                    Titre du magazine<span className="text-danger-100">*</span>
                  </label>
                  <Input
                    block
                    type="text"
                    name="articleTitle"
                    placeholder="Titre de l'article"
                    required={true}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4 py-4">
                <div>
                  <label className="block text-base font-medium text-slate-700 mb-1">
                    Choisir la rubrique
                    <span className="text-danger-100">*</span>
                  </label>
                  <Selected
                    block
                    className="h-12"
                    name="rubricId"
                    required={true}
                    datas={_rubrics}
                    onChange={(item) => console.log(item.target.value)}
                  />
                </div>
                <div>
                  <label className="block text-base font-medium text-slate-700 mb-1">
                    Nom complet de l'auteur
                    <span className="text-danger-100">*</span>
                  </label>
                  <Input
                    block
                    type="text"
                    name="author"
                    placeholder="Nom complet"
                  />
                </div>
              </div>

              <Editor returnValue={(value) => setContent(value)} />
              <div className="flex justify-center">
                {_loader ? (
                  <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
                    <div
                      className="bg-primary-100 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
                      style={{ width: `${progresspercent}%` }}
                    >
                      {progresspercent}%
                    </div>
                  </div>
                ) : (
                  <div>
                    <Button
                      color="primary"
                      type="submit"
                      title="Enregistrer le magazine"
                      className="mt-7 w-auto"
                    />
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      </Page>
      <AlertConfirmation
        description={`L'article a bien été créer retour vous allez être rediriger vers la liste des articles.`}
        title="Opération terminée...!"
        onShow={() => setShowAlert((alert) => !alert)}
        show={_showAlert}
        simple={true}
        onConfirm={() => navigate("/dashboard/magazines")}
      />
    </>
  );
}
