import classNames from "classnames";
import { Button, TrashSVG, VideoSVG } from "../../app/components";
import { ViodeoRequest } from "../../app/interfaces/interfaces";

interface VideoProps {
  video: ViodeoRequest;
  rubricName: string;
  onDelete?: (video: ViodeoRequest) => void;
  openIframe: (video: ViodeoRequest) => void;
  isEditable?: boolean;
  className?: string;
}

export function VideoCard({
  video,
  rubricName,
  onDelete,
  isEditable,
  openIframe,
  className,
}: VideoProps) {
  return (
    <div className={classNames(className, "relative w-80 h-50 ")}>
      <img
        src={
          video.metada.thumbnail_url
            ? video.metada.thumbnail_url
            : "../assets/img/video.png"
        }
        alt=""
        className="h-full w-full"
      />
      <div className="absolute video w-full h-full top-0 left-0 right-0"></div>
      <div className="absolute bottom-0 right-0 left-0 flex flex-col p-4 gap-y-2">
        <div className="flex w-full">
          <p className="text-white font-semibold text-base text-2-lines">
            {video.title}
          </p>
        </div>
        <div className="w-full flex flex-row justify-between items-center">
          <div className="flex flex-row items-center">
            <hr className="w-6 h-1 bg-primary-100" />
            <span className="text-primary-100 text-sm font-normal">
              {rubricName}
            </span>
          </div>
          <div
            className="hover:cursor-pointer"
            onClick={() => openIframe(video)}
          >
            <VideoSVG />
          </div>
        </div>
      </div>
      {isEditable && (
        <div className="absolute top-2 right-2">
          <Button
            onClick={() => onDelete!(video)}
            color="danger"
            className="h-8"
            type="button"
            icon={<TrashSVG stroke="#ffff" />}
          />
        </div>
      )}
    </div>
  );
}
