interface Property {
  color: string;
  classname?: string;
  width?: string;
  height?: string;
}

export function UsersSVG({
  color,
  classname,
  width = "20",
  height = "20",
}: Property) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classname}
    >
      <path
        d="M9.16006 11.62C9.13006 11.62 9.11006 11.62 9.08006 11.62C9.03006 11.61 8.96006 11.61 8.90006 11.62C6.00006 11.53 3.81006 9.25 3.81006 6.44C3.81006 3.58 6.14006 1.25 9.00006 1.25C11.8601 1.25 14.1901 3.58 14.1901 6.44C14.1801 9.25 11.9801 11.53 9.19006 11.62C9.18006 11.62 9.17006 11.62 9.16006 11.62ZM9.00006 2.75C6.97006 2.75 5.31006 4.41 5.31006 6.44C5.31006 8.44 6.87006 10.05 8.86006 10.12C8.92006 10.11 9.05006 10.11 9.18006 10.12C11.1401 10.03 12.6801 8.42 12.6901 6.44C12.6901 4.41 11.0301 2.75 9.00006 2.75Z"
        fill={color}
      />
      <path
        d="M16.5401 11.75C16.5101 11.75 16.4801 11.75 16.4501 11.74C16.0401 11.78 15.6201 11.49 15.5801 11.08C15.5401 10.67 15.7901 10.3 16.2001 10.25C16.3201 10.24 16.4501 10.24 16.5601 10.24C18.0201 10.16 19.1601 8.96 19.1601 7.49C19.1601 5.97 17.9301 4.74 16.4101 4.74C16.0001 4.75 15.6601 4.41 15.6601 4C15.6601 3.59 16.0001 3.25 16.4101 3.25C18.7501 3.25 20.6601 5.16 20.6601 7.5C20.6601 9.8 18.8601 11.66 16.5701 11.75C16.5601 11.75 16.5501 11.75 16.5401 11.75Z"
        fill={color}
      />
      <path
        d="M9.1701 22.55C7.2101 22.55 5.2401 22.05 3.7501 21.05C2.3601 20.13 1.6001 18.87 1.6001 17.5C1.6001 16.13 2.3601 14.86 3.7501 13.93C6.7501 11.94 11.6101 11.94 14.5901 13.93C15.9701 14.85 16.7401 16.11 16.7401 17.48C16.7401 18.85 15.9801 20.12 14.5901 21.05C13.0901 22.05 11.1301 22.55 9.1701 22.55ZM4.5801 15.19C3.6201 15.83 3.1001 16.65 3.1001 17.51C3.1001 18.36 3.6301 19.18 4.5801 19.81C7.0701 21.48 11.2701 21.48 13.7601 19.81C14.7201 19.17 15.2401 18.35 15.2401 17.49C15.2401 16.64 14.7101 15.82 13.7601 15.19C11.2701 13.53 7.0701 13.53 4.5801 15.19Z"
        fill={color}
      />
      <path
        d="M18.3402 20.75C17.9902 20.75 17.6802 20.51 17.6102 20.15C17.5302 19.74 17.7902 19.35 18.1902 19.26C18.8202 19.13 19.4002 18.88 19.8502 18.53C20.4202 18.1 20.7302 17.56 20.7302 16.99C20.7302 16.42 20.4202 15.88 19.8602 15.46C19.4202 15.12 18.8702 14.88 18.2202 14.73C17.8202 14.64 17.5602 14.24 17.6502 13.83C17.7402 13.43 18.1402 13.17 18.5502 13.26C19.4102 13.45 20.1602 13.79 20.7702 14.26C21.7002 14.96 22.2302 15.95 22.2302 16.99C22.2302 18.03 21.6902 19.02 20.7602 19.73C20.1402 20.21 19.3602 20.56 18.5002 20.73C18.4402 20.75 18.3902 20.75 18.3402 20.75Z"
        fill={color}
      />
    </svg>
  );
}
