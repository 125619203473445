interface BreadcrumbsProps {
  title?: string;
  href?: string;
  btnLeft?: boolean;
}

export function Breadcrumbs({ title, href, btnLeft = true }: BreadcrumbsProps) {
  return (
    <div className="lg:container mt-4 bg-white mx-auto flex md:flex-wrap p-5 flex-row justify-between items-center">
      <div className="flex flex-row gap-4">
        <a
          href={href}
          className="flex flex-row gap-4 items-center bg-primary-100 py-2 px-8"
        >
          <span className="font-normal text-sm md:text-xl text-white">
            {title}
          </span>
          <svg
            width="18"
            height="16"
            viewBox="0 0 18 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.6923 8.70711C18.0828 8.31658 18.0828 7.68342 17.6923 7.29289L11.3284 0.928932C10.9378 0.538408 10.3047 0.538408 9.91414 0.928932C9.52362 1.31946 9.52362 1.95262 9.91414 2.34315L15.571 8L9.91414 13.6569C9.52362 14.0474 9.52362 14.6805 9.91414 15.0711C10.3047 15.4616 10.9378 15.4616 11.3284 15.0711L17.6923 8.70711ZM0.0146484 9H16.9852V7L0.0146484 7L0.0146484 9Z"
              fill="white"
            />
          </svg>
        </a>
      </div>
    </div>
  );
}
