import { isNil } from "lodash";
import { DateTime } from "luxon";
import { ArticleResponse, ScienceRequest } from "../app/interfaces/interfaces";
import {
  DeltaInsertOp,
  InlineGroup,
  QuillDeltaToHtmlConverter,
} from "quill-delta-to-html";
import TimeAgo from "javascript-time-ago";
import fr from "javascript-time-ago/locale/fr";

TimeAgo.addDefaultLocale(fr);

const timeAgo = new TimeAgo("fr");

export function formatDatePost(date: Date): string {
  const dateConvert = new Date(date);
  return timeAgo.format(dateConvert);
}

export function formatDateDay(date: Date): string {
  return DateTime.fromISO(date.toString()).setLocale("fr").toFormat("TTTT");
}

export function formatDateMMMddyyy(date: Date): string {
  return DateTime.fromJSDate(new Date(date)).toFormat("MMMM dd, yyyy");
}

export function deltaTextToHtml(magazine: ArticleResponse) {
  const quillConverterConfig = {};

  const converter = new QuillDeltaToHtmlConverter(
    (JSON.parse(magazine.content as string) as Record<string, any>).content.ops,
    quillConverterConfig
  );
  converter.beforeRender(function (groupType, data) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const result = !isNil((<InlineGroup>data).ops)
      ? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, array-callback-return
        (<InlineGroup>data).ops.map((op: DeltaInsertOp) => {
          const { insert } = op;
          if (insert.type === "text") {
            return `${insert.value}`;
          }
        })
      : [];
    return result.join("");
  });
  const html = converter.convert();
  return html;
}

export function deltaDetailToHtml(magazine: ArticleResponse | ScienceRequest) {
  const quillConverterConfig = {};

  const converter = new QuillDeltaToHtmlConverter(
    (JSON.parse(magazine.content as string) as Record<string, any>).content.ops,
    quillConverterConfig
  );

  converter.beforeRender(function (groupType, data) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const result = !isNil((<InlineGroup>data).ops)
      ? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, array-callback-return
        (<InlineGroup>data).ops.map((op: DeltaInsertOp) => {
          const { insert, attributes } = op;
          if (insert.type === "text") {
            return `
                    ${
                      insert.value === "\n"
                        ? `<br/>`
                        : attributes.link
                        ? `<a target="_blank" href="${
                            attributes.link
                          }" className="font-roboto text-justify" style="color: ${
                            attributes.color
                          }; font-weight: ${
                            attributes.bold ? "bold" : "normal"
                          }; font-style:${
                            attributes.italic ? "italic" : "normal"
                          } ">
                      ${insert.value}
                    </a>`
                        : `<span className="font-roboto text-justify" style="color: ${
                            attributes.color
                          }; font-weight: ${
                            attributes.bold ? "bold" : "normal"
                          }; font-style:${
                            attributes.italic ? "italic" : "normal"
                          } ">
                      ${insert.value}
                    </span> 
                    `
                    }
          `;
          } else if (insert.type === "image") {
            return `<img src=${insert.value}  className='object-cover md:h-100 mr-6'/>`;
          }
        })
      : [];
    return result.join("");
  });
  const html = converter.convert();
  return html;
}

export const defaultRubric = [
  "Santé",
  "Innovation",
  "Portrait",
  "Business",
  "Science",
];

export function getRandomFileName(file: any) {
  var timestamp = new Date().toISOString().replace(/[-:.]/g, "");
  var random = ("" + Math.random()).substring(2, 8);
  var random_number = timestamp + random;
  var file_name = file.substr(
    (Math.max(0, file.lastIndexOf(".")) || Infinity) + 1
  );
  return random_number + "." + file_name;
}
