export function GridMenuSVG() {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.86 3H8.01C4.725 3 3 4.725 3 7.995V10.845C3 14.115 4.725 15.84 7.995 15.84H10.845C14.115 15.84 15.84 14.115 15.84 10.845V7.995C15.855 4.725 14.13 3 10.86 3Z"
        fill="#6D6A7F"
      />
      <path
        opacity="0.4"
        d="M28.005 3H25.155C21.885 3 20.16 4.725 20.16 7.995V10.845C20.16 14.115 21.885 15.84 25.155 15.84H28.005C31.275 15.84 33 14.115 33 10.845V7.995C33 4.725 31.275 3 28.005 3Z"
        fill="#6D6A7F"
      />
      <path
        d="M28.005 20.145H25.155C21.885 20.145 20.16 21.87 20.16 25.14V27.99C20.16 31.26 21.885 32.985 25.155 32.985H28.005C31.275 32.985 33 31.26 33 27.99V25.14C33 21.87 31.275 20.145 28.005 20.145Z"
        fill="#6D6A7F"
      />
      <path
        opacity="0.4"
        d="M10.86 20.145H8.01C4.725 20.145 3 21.87 3 25.14V27.99C3 31.275 4.725 33 7.995 33H10.845C14.115 33 15.84 31.275 15.84 28.005V25.155C15.855 21.87 14.13 20.145 10.86 20.145Z"
        fill="#6D6A7F"
      />
    </svg>
  );
}
