import React from "react";
import { useQuill } from "react-quilljs";
import BlotFormatter from "quill-blot-formatter";
import "quill/dist/quill.snow.css";

interface EditorProps {
  returnValue: (value: Record<string, any>) => void;
}

export default function Editor({ returnValue }: EditorProps) {
  const { quill, quillRef, Quill } = useQuill({
    modules: { blotFormatter: {} },
    placeholder: "Rédiger votre article ici...",
  });

  if (Quill && !quill) {
    // const BlotFormatter = require('quill-blot-formatter');
    Quill.register("modules/blotFormatter", BlotFormatter);
  }

  React.useEffect(() => {
    if (quill) {
      quill.on("text-change", (delta, oldContents) => {
        // console.log("Text change!");
        // console.log(delta);
        returnValue(quill.getContents());
        // let currrentContents = quill.getContents();
        // console.log(currrentContents.diff(oldContents));
      });
    }
  }, [quill, Quill, returnValue]);

  return (
    <>
      <div ref={quillRef} />
    </>
  );
}
