import React from "react";
import { PortraitResponse } from "../../app/interfaces/interfaces";
import { HealthlineWhiteSVG, PortraitCard } from "../components";

interface PortraitProps {
  portraits: PortraitResponse[];
}

export default function Portraits({ portraits }: PortraitProps) {
  const elementRef = React.useRef<any>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setArrowDisable] = React.useState(true);

  const handleHorizantalScroll = (
    element: any,
    speed: number,
    distance: number,
    step: number
  ) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      //@ts-ignore
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      //@ts-ignore
      if (element.scrollLeft === 0) {
        setArrowDisable(true);
      } else {
        setArrowDisable(false);
      }
    }, speed);
  };

  return (
    <div className="md:container mt-6 md:mt-0 bg-primary-100 mx-auto flex gap-12 flex-wrap p-5 flex-col md:flex-row items-center py-4 px-4 md:px-8">
      <div className="relative flex flex-row items-center w-full justify-between gap-8">
        <h1 className="text-5xl font-bold text-black ">Portraits</h1>
        <div className="hidden md:flex">
          <HealthlineWhiteSVG />
        </div>
        <div className="absolute -bottom-3 -left-3 h-6 w-14 bg-white"></div>
        <div className="flex items-center">
          <div
            className="h-10 w-12 grid place-items-center bg-white cursor-pointer"
            onClick={() => {
              handleHorizantalScroll(elementRef.current, 25, 100, -10);
            }}
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.54495 1.66523C7.63995 1.66523 7.73495 1.70023 7.80995 1.77523C7.95495 1.92023 7.95495 2.16023 7.80995 2.30523L4.54995 5.56523C4.30995 5.80523 4.30995 6.19523 4.54995 6.43523L7.80995 9.69523C7.95495 9.84023 7.95495 10.0802 7.80995 10.2252C7.66495 10.3702 7.42495 10.3702 7.27995 10.2252L4.01995 6.96523C3.76495 6.71024 3.61995 6.36523 3.61995 6.00023C3.61995 5.63523 3.75995 5.29023 4.01995 5.03523L7.27995 1.77523C7.35495 1.70523 7.44995 1.66523 7.54495 1.66523Z"
                fill="#292D32"
              />
            </svg>
          </div>
          <div
            className="h-10 w-12 grid place-items-center bg-black cursor-pointer"
            onClick={() => {
              handleHorizantalScroll(elementRef.current, 25, 100, 10);
            }}
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.45505 10.3348C4.36005 10.3348 4.26505 10.2998 4.19005 10.2248C4.04505 10.0798 4.04505 9.83977 4.19005 9.69477L7.45005 6.43477C7.69005 6.19477 7.69005 5.80477 7.45005 5.56477L4.19005 2.30477C4.04505 2.15977 4.04505 1.91977 4.19005 1.77477C4.33505 1.62977 4.57505 1.62977 4.72005 1.77477L7.98005 5.03477C8.23505 5.28977 8.38005 5.63477 8.38005 5.99977C8.38005 6.36477 8.24005 6.70977 7.98005 6.96477L4.72005 10.2248C4.64505 10.2948 4.55005 10.3348 4.45505 10.3348Z"
                fill="#EE763E"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="img-container gap-4 " ref={elementRef}>
        {portraits.map((item, i) => {
          return <PortraitCard key={i} portrait={item} width="md:w-72" />;
        })}
      </div>
    </div>
  );
}
