interface Property {
  color: string;
  classname?: string;
  width?: string;
  height?: string;
}

export function ShoppingSVG({
  color,
  classname,
  width = "20",
  height = "20",
}: Property) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classname}
    >
      <path
        d="M14 6.75H10C9.04 6.75 7.25 6.75 7.25 4C7.25 1.25 9.04 1.25 10 1.25H14C14.96 1.25 16.75 1.25 16.75 4C16.75 4.96 16.75 6.75 14 6.75ZM10 2.75C9.01 2.75 8.75 2.75 8.75 4C8.75 5.25 9.01 5.25 10 5.25H14C15.25 5.25 15.25 4.99 15.25 4C15.25 2.75 14.99 2.75 14 2.75H10Z"
        fill={color}
      />
      <path
        d="M14 22.75H9C3.38 22.75 2.25 20.17 2.25 16V9.99997C2.25 5.43997 3.9 3.48997 7.96 3.27997C8.35 3.25997 8.73 3.56997 8.75 3.98997C8.77 4.40997 8.45 4.74997 8.04 4.76997C5.2 4.92997 3.75 5.77997 3.75 9.99997V16C3.75 19.7 4.48 21.25 9 21.25H14C14.41 21.25 14.75 21.59 14.75 22C14.75 22.41 14.41 22.75 14 22.75Z"
        fill={color}
      />
      <path
        d="M20.9999 15.7501C20.5899 15.7501 20.2499 15.4101 20.2499 15.0001V10.0001C20.2499 5.78005 18.7999 4.93005 15.9599 4.77005C15.5499 4.75005 15.2299 4.39005 15.2499 3.98005C15.2699 3.57005 15.6299 3.25005 16.0399 3.27005C20.0999 3.49005 21.7499 5.44005 21.7499 9.99005V14.9901C21.7499 15.4101 21.4099 15.7501 20.9999 15.7501Z"
        fill={color}
      />
      <path
        d="M15 19.75C14.59 19.75 14.25 19.41 14.25 19V16C14.25 15.59 14.59 15.25 15 15.25H18C18.41 15.25 18.75 15.59 18.75 16C18.75 16.41 18.41 16.75 18 16.75H15.75V19C15.75 19.41 15.41 19.75 15 19.75Z"
        fill={color}
      />
      <path
        d="M21 22.75C20.81 22.75 20.62 22.68 20.47 22.53L14.51 16.57C14.22 16.28 14.22 15.8 14.51 15.51C14.8 15.22 15.28 15.22 15.57 15.51L21.53 21.47C21.82 21.76 21.82 22.24 21.53 22.53C21.38 22.68 21.19 22.75 21 22.75Z"
        fill={color}
      />
    </svg>
  );
}
