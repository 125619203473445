import React from "react";
import classNames from "classnames";

interface BlockrightProps {
  title: string;
  classname?: string;
}

export function Blockright({
  title,
  classname,
  children,
}: React.PropsWithChildren<BlockrightProps>) {
  return (
    <div className={classNames(classname, "m-2 bg-primary-70")}>
      <div className="bg-primary-100 px-4 w-1/4 py-1">
        <span className="uppercase text-sm font-semibold text-white">
          {title}
        </span>
      </div>
      <div className="flex flex-col gap-4 py-4 mx-2">{children} </div>
    </div>
  );
}
