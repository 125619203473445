import { Link } from "react-router-dom";
import { ArticleResponse } from "../../app/interfaces/interfaces";

interface ScienceProps {
  article: ArticleResponse;
}
export function Science({ article }: ScienceProps) {
  return (
    <Link to={`/magazine-detail/${article.id}`}>
      <div className="flex flex-col w-64">
        <div className="h-34 w-full">
          <img src={article.articlePic} className="h-full w-full" alt="" />
        </div>
        <div className="h-10">
          <h3 className="text-2-lines text-base font-medium text-white w-64 mt-2">
            {article.articleTitle}
          </h3>
        </div>
      </div>
    </Link>
  );
}
