import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducerAuth from "./auth/reducerAuth";
import { reducerEmagazine } from "./eMagazine/reducerEmagazine";
import { reducerMagazine } from "./magazine/reducerMagazine";
import { reducerPortrait } from "./portrait/reducerPortrait";
import { reducerRubric } from "./rubrics/reducerRubric";

const rootReducer = combineReducers({
  auth: reducerAuth,
  rubric: reducerRubric,
  magazine: reducerMagazine,
  emagazine: reducerEmagazine,
  portrait: reducerPortrait,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
