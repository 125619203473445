import { Button, Input } from "../app/components";
import Footer from "./layouts/footer";
import Header from "./layouts/header";
import SubHeader from "./layouts/subHeader";

export default function AboutUs() {
  return (
    <>
      <Header />
      <SubHeader />
      <div className=" m-0 text-base bg-body_background antialiased font-normal leading-default text-slate-500">
        <section
          className="md:container mt-5 mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center py-4"
          style={{ padding: "unset" }}
        >
          <div className="relative flex flex-row items-center gap-8">
            <h1 className="text-8xl font-bold text-primary-100 z-10">
              A propos de nous
            </h1>
            <div className="absolute -bottom-3 -left-3 h-12 w-24 bg-primary-90"></div>
          </div>
        </section>
        <section className="grid grid-cols-2 gap-6 w-full mt-16 relative">
          <div className="bg-bg_about w-full h-96"></div>
          <div className="w-3/4">
            <p>
              <span className="text-black font-semibolds">
                Cerebrum Magazine
              </span>{" "}
              se veut être ce magazine africain dédié à la science, la
              technologie, la connaissance et à la promotion du savoir africain
              pour l’Afrique et pour le monde, écrit en français et en anglais
              publié par Manekeng Communication depuis 2022.
            </p>
          </div>
          <div className="absolute right-64 top-30">
            <div className="flex flex-row">
              <img
                src="../assets/img/about1.png"
                className="w-full h-full object-cover"
                alt=""
              />
              <img
                src="../assets/img/about2.png"
                className="w-full h-full object-cover"
                alt=""
              />
              <img
                src="../assets/img/about3.png"
                className="w-full h-full object-cover"
                alt=""
              />
            </div>
            <div className="bg-primary-90 p-4">
              <p className="text-white">
                <span className="font-semibolds">Cerebrum Magazine</span>{" "}
                Cerebrum Magazine souhaite être une plateforme d’éducation et de
                transmission de la connaissance et de la bonne information
                scientifique
              </p>
            </div>
          </div>
        </section>
        <section className="mt-96 mb-24 md:container mx-auto w-full flex flex-wrap p-5 flex-col md:flex-row items-center justify-center">
          <div className="w-4/6 flex flex-col items-center">
            <h1 className="text-base md:text-4xl font-bold text-center text-primary-75">
              S’informer c’est
              <span className="text-primary-100"> déjà bien</span> contribuer au
              contenu c’est <span className="text-primary-100"> un plus</span>
            </h1>
            <span className="text-primary-100 text-lg font-medium mt-4">
              Contactez-nous
            </span>
          </div>
        </section>
        <section className="bg-white p-8">
          <div
            className="md:container mt-5 mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center py-4"
            style={{ padding: "unset" }}
          >
            <div className="flex flex-row w-full justify-evenly">
              <div className="flex flex-col justify-center items-center">
                <img
                  src="../assets/img/amail-about.png"
                  className="object-cover"
                  alt=""
                />
                <span className="text-black text-xl font-bold mt-4">Email</span>
                <span className="text-base font-normal text-primary-85 mt-2">
                  Notre équipe sympathique est là pour vous aider.
                </span>
                <span className="text-primary-100 text-base font-medium mt-4">
                  BeAfricaKids@gmail.com
                </span>
              </div>

              <div className="flex flex-col justify-center items-center">
                <img
                  src="../assets/img/location-about.png"
                  className="object-cover"
                  alt=""
                />
                <span className="text-black text-xl font-bold mt-4">
                  Bureau
                </span>
                <span className="text-base font-normal text-primary-85 mt-2">
                  Venez nous saluer au siège de notre bureau.
                </span>
                <span className="text-primary-100 text-base font-medium mt-4">
                  100 Smith Street Collingwood VIC 3066 AU
                </span>
              </div>

              <div className="flex flex-col justify-center items-center">
                <img
                  src="../assets/img/phone-about.png"
                  className="object-cover"
                  alt=""
                />
                <span className="text-black text-xl font-bold mt-4">
                  Téléphone
                </span>
                <span className="text-base font-normal text-primary-85 mt-2">
                  Du lundi au vendredi de 8h à 17h.
                </span>
                <span className="text-primary-100 text-base font-medium mt-4">
                  +1 (407) 879-5393
                </span>
              </div>
            </div>
            <div className="p-16 bg-primary-100 rounded-2xl text-white flex flex-row w-full my-32 justify-between">
              <div className="flex flex-col justify-start">
                <p className="text-white font-bold text-4xl">
                  Vous aimez cerebrum ?
                </p>
                <span className="text-xl font-normal">
                  Abonnez vous à notre newsletter !
                </span>
              </div>
              <div className="flex flex-row items-center gap-4">
                <div>
                  <Input type="email" placeholder="Email" />
                </div>
                <div>
                  <Button
                    color="blue"
                    type="submit"
                    title="Envoyer"
                    className="py-3"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
}
