import { ArticleResponse } from "../../app/interfaces/interfaces";
import { deltaTextToHtml, formatDatePost } from "../../utils/utils";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import { TextLineReducer } from "./TextLineReducer";

interface NewsCardProps {
  magazine: ArticleResponse;
}
export function NewsCard({ magazine }: NewsCardProps) {
  return (
    <div className="flex flex-col bg-white p-3 gap-2">
      <div className="flex items-center gap-1">
        <svg
          width="15"
          height="14"
          viewBox="0 0 15 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.56551 13.2707C4.10634 13.2707 1.29467 10.459 1.29467 6.99984C1.29467 3.54067 4.10634 0.729004 7.56551 0.729004C11.0247 0.729004 13.8363 3.54067 13.8363 6.99984C13.8363 10.459 11.0247 13.2707 7.56551 13.2707ZM7.56551 1.604C4.59051 1.604 2.16967 4.02484 2.16967 6.99984C2.16967 9.97484 4.59051 12.3957 7.56551 12.3957C10.5405 12.3957 12.9613 9.97484 12.9613 6.99984C12.9613 4.02484 10.5405 1.604 7.56551 1.604Z"
            fill="#807E7C"
          />
          <path
            d="M9.72966 9.29253C9.65382 9.29253 9.57799 9.27503 9.50799 9.22836L7.69966 8.14919C7.25049 7.88086 6.91799 7.29169 6.91799 6.77253V4.38086C6.91799 4.14169 7.11632 3.94336 7.35549 3.94336C7.59466 3.94336 7.79299 4.14169 7.79299 4.38086V6.77253C7.79299 6.98253 7.96799 7.29169 8.14882 7.39669L9.95716 8.47586C10.1672 8.59836 10.2313 8.86669 10.1088 9.07669C10.0213 9.21669 9.87549 9.29253 9.72966 9.29253Z"
            fill="#807E7C"
          />
        </svg>

        <span className="text-primary-50 text-sm font-light">
          {formatDatePost(magazine.created_at)}
        </span>
      </div>
      <Link to={`/magazine-detail/${magazine.id}`}>
        <h4 className="text-black font-medium text-base">
          {magazine.articleTitle}
        </h4>
      </Link>
      <div className="flex flex-row w-full items-center gap-4">
        <div className="h-16 w-24">
          <img src={magazine.articlePic} alt="" className="w-full h-full" />
        </div>
        <div className="w-3/5">
          <h5 className="text-2-lines text-primary-45 font-medium text-xs">
            <TextLineReducer
              text={deltaTextToHtml(magazine).slice(0, 200)}
              maxLines={2}
            />
          </h5>
        </div>
      </div>
    </div>
  );
}
