export enum ActionType {
  PATCH,
  POST,
}

export enum Gender {
  MALE = "male",
  FEMALE = "female",
}

export enum RoleUser {
  Super_Admin = "super-admin",
  Admin = "admin",
}
