export function MinLine() {
  return (
    <svg
      width="40"
      height="2"
      viewBox="0 0 40 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line y1="1" x2="40" y2="1" stroke="#EE763E" strokeWidth="2" />
    </svg>
  );
}
