import { GridMenuSVG, LogoSVG } from "../components";
import { FacebookSVG } from "../components/svg/facebook.svg";
import { LinkedinSVG } from "../components/svg/linkedin.svg";
import { TwitterSVG } from "../components/svg/twitter.svg";
import { toast } from "react-toastify";
import { isNil } from "lodash";
import React from "react";
import { Input, Modal } from "../../app/components";
import axios from "axios";
import { Link } from "react-router-dom";

export default function Header() {
  const [form, _setFormReference] = React.useState<HTMLFormElement | null>(
    null
  );
  const [_showModal, setShowMadal] = React.useState<boolean>(false);
  const [_actionLoading, setActionLoading] = React.useState<boolean>(false);

  const _confirmForm = React.useCallback(() => {
    if (!isNil(form)) {
      form.requestSubmit();
    }
  }, [form]);

  const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setActionLoading(true);
    const target = e.target as typeof e.target & {
      nom: { value: string };
      email: { value: string };
    };
    try {
      const apiKey =
        "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";
      const url = "https://api.sendinblue.com/v3/contacts";
      const data = {
        email: target.email.value,
        nom: target.nom.value,
        listIds: [0],
        updateEnabled: true,
      };
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
          "api-key": apiKey,
        },
      });

      if (response.status === 201) {
        toast("Merci d'avoir souscrit a notre newsletter", { type: "success" });
      }
    } catch (error) {}
    setShowMadal(false);
    setActionLoading(false);
  };
  return (
    <>
      <header className="bg-primary-100 body-font">
        <div className="lg:container mx-auto flex md:flex-wrap p-5 flex-row justify-between items-center">
          <a
            href="/"
            className="flex title-font font-medium items-center text-gray-900 mb-0 md:mb-4"
          >
            <LogoSVG />
          </a>
          <div className="md:hidden flex">
            <GridMenuSVG />
          </div>
          <div className="md:ml-auto hidden md:flex justify-center items-center ">
            <button
              onClick={() => setShowMadal(true)}
              className="inline-flex items-center py-2 px-6 bg-white border-0 focus:outline-none hover:bg-gray-200 rounded text-lg mt-4 md:mt-0"
            >
              <span className="text-primary-100">Je m'abonne</span>
            </button>

            <div className="h-7 bg-white mx-5" style={{ width: "1px" }}></div>
            <nav className=" flex flex-wrap items-center text-base justify-center">
              <Link to={"#"} className="cursor-pointer">
                <FacebookSVG />
              </Link>
              <Link to={"#"} className="cursor-pointer mx-2">
                <LinkedinSVG />
              </Link>
              <Link to={"#"} className="cursor-pointer">
                <TwitterSVG />
              </Link>
            </nav>
          </div>
        </div>
      </header>
      <Modal
        show={_showModal}
        loading={_actionLoading}
        onShow={() => setShowMadal(!_showModal)}
        onConfirm={() => _confirmForm()}
        title="Abonnez vous a la newsletter..."
      >
        <form
          className="mt-8 space-y-3"
          ref={_setFormReference}
          onSubmit={onFormSubmit}
        >
          <div className="w-full">
            <label className="block text-sm font-mediumtext-gray-700 mb-1">
              Nom
            </label>
            <Input
              block
              type="text"
              name="nom"
              required={true}
              placeholder="Votre nom"
            />
          </div>
          <div className="w-full">
            <label className="block text-sm font-mediumtext-gray-700 mb-1">
              Email
            </label>
            <Input
              block
              type="text"
              name="email"
              placeholder="cerebrummagazine@gmail.com"
              required={true}
            />
          </div>
        </form>
      </Modal>
    </>
  );
}
