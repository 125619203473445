import React, { Fragment } from "react";
import Footer from "./layouts/footer";
import Header from "./layouts/header";
import SubHeader from "./layouts/subHeader";
import "./css/carousel-1.css";
import {
  Breadcrumbs,
  InnovationGroup,
  LineHealthFullSVG,
  Loader,
} from "./components";
import { ArticleResponse, RubricResponse } from "../app/interfaces/interfaces";
import {
  getArticlesByElemenPaginate,
  getArticlesByElement,
  getRubrics,
} from "../app/core/firebase/firestore-collection";
import { onSnapshot } from "firebase/firestore";
import { VideoBlock } from "./components/videoBlock";

export default function Sante() {
  const [_articles, setArticles] = React.useState<ArticleResponse[]>([]);
  const [_loader, setLoader] = React.useState<boolean>(false);
  const [_loadPaginate, setPaginate] = React.useState<boolean>(false);
  const [_rubrics, seRubrics] = React.useState<RubricResponse[]>([]);
  const [lastVisible, setLastVisible] = React.useState<any>();

  const loadMore = React.useCallback(async () => {
    if (!lastVisible) return;
    setPaginate(true);

    (await getRubrics()).forEach(async (item) => {
      const rubric = item.data() as RubricResponse;
      if (rubric.name === "Santé") {
        onSnapshot(
          await getArticlesByElemenPaginate(
            "rubricId",
            rubric.id,
            lastVisible,
            4
          ),
          (querySnapshot) => {
            querySnapshot.forEach((doc) => {
              setArticles((old) => [...old, doc.data() as ArticleResponse]);
            });
            if (querySnapshot.docs.length < 4) {
              setLastVisible(null);
            } else {
              setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
            }
          }
        );
      }
    });
    setPaginate(false);
  }, [lastVisible]);
  const fecthArticles = React.useCallback(async () => {
    setLoader(true);
    (await getRubrics()).forEach(async (item) => {
      const rubric = item.data() as RubricResponse;
      if (rubric.name === "Santé") {
        onSnapshot(
          await getArticlesByElement("rubricId", rubric.id, 4),
          (querySnapshot) => {
            querySnapshot.forEach((doc) => {
              setArticles((old) => [...old, doc.data() as ArticleResponse]);
            });
            setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
          }
        );
      }
      seRubrics((old) => [...old, rubric]);
    });

    setLoader(false);
  }, []);

  React.useEffect(() => {
    fecthArticles();
  }, [fecthArticles]);

  return (
    <>
      <Header />
      <SubHeader />
      {_loader ? (
        <div className="mt-12 flex  justify-center">
          <div role="status">
            <svg
              aria-hidden="true"
              className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-primary-100"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="m-0 text-base bg-body_background antialiased font-normal leading-default text-slate-500">
          <Breadcrumbs title="Astuces Santé" href="/astu-sante" />
          <div className="w-full lg:container mt-4 bg-white mx-auto flex md:flex-wrap p-2 md:p-5 flex-row justify-between items-center">
            {_articles.length > 0 ? (
              <div className="flex flex-col w-full">
                <h1 className="text-3xl font-bold text-primary-100 mb-4 md:mb-0">
                  Tous les articles
                </h1>
                <div className="md:my-4">
                  <LineHealthFullSVG width="100%" />
                </div>
                {_articles.map((item, i) => (
                  <Fragment>
                    <InnovationGroup
                      rubricName={
                        _rubrics.find((r) => r.id === item.rubricId)?.name!
                      }
                      magazine={item}
                    />
                    <div className="my-4">
                      <LineHealthFullSVG width="100%" />
                    </div>
                  </Fragment>
                ))}
                {_loadPaginate && <Loader />}

                <div className="flex justify-end my-4">
                  <button
                    onClick={() => loadMore()}
                    className="flex flex-row gap-4 items-center bg-primary-100 py-2 px-8"
                  >
                    <span className="font-normal text-base text-white">
                      Articles les plus anciens
                    </span>
                    <svg
                      width="18"
                      height="16"
                      viewBox="0 0 18 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.6923 8.70711C18.0828 8.31658 18.0828 7.68342 17.6923 7.29289L11.3284 0.928932C10.9378 0.538408 10.3047 0.538408 9.91414 0.928932C9.52362 1.31946 9.52362 1.95262 9.91414 2.34315L15.571 8L9.91414 13.6569C9.52362 14.0474 9.52362 14.6805 9.91414 15.0711C10.3047 15.4616 10.9378 15.4616 11.3284 15.0711L17.6923 8.70711ZM0.0146484 9H16.9852V7L0.0146484 7L0.0146484 9Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            ) : (
              <p className="w-full text-lg text-center">Aucun article publié</p>
            )}
          </div>
          <VideoBlock rubrics={_rubrics} />
          <Footer />
        </div>
      )}
    </>
  );
}
