export function FacebookSVG() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0746 1.14111C6.52512 1.14111 1.21582 6.45041 1.21582 12.9999C1.21582 19.5495 6.52512 24.8588 13.0746 24.8588C19.6242 24.8588 24.9335 19.5495 24.9335 12.9999C24.9335 6.45041 19.6242 1.14111 13.0746 1.14111ZM15.8837 9.33605H14.1012C13.8899 9.33605 13.6552 9.614 13.6552 9.98335V11.2705H15.8849L15.5477 13.1062H13.6552V18.6168H11.5515V13.1062H9.643V11.2705H11.5515V10.1909C11.5515 8.64182 12.6262 7.38305 14.1012 7.38305H15.8837V9.33605Z"
        fill="white"
      />
    </svg>
  );
}
