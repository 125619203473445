import { ArticleResponse } from "../../app/interfaces/interfaces";
import {
  deltaTextToHtml,
  formatDateMMMddyyy,
  formatDatePost,
} from "../../utils/utils";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import { TextLineReducer } from "./TextLineReducer";

interface InnovationGroupInterface {
  magazine: ArticleResponse;
  rubricName: string;
}

export function InnovationGroup({
  magazine,
  rubricName,
}: InnovationGroupInterface) {
  return (
    <Link to={`/magazine-detail/${magazine.id}`}>
      <div className="bg-white p-4 flex flex-row items-center gap-8 mt-4 w-full">
        <div className="w-52 h-40 md:h-60 relative">
          <img
            src={magazine.articlePic}
            className="object-cover w-full h-full"
            alt=""
          />
          <div className="flex items-center justify-center h-10 w-10 bg-primary-100 absolute -right-4 bottom-3">
            <svg
              width="11"
              height="10"
              viewBox="0 0 11 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.58627 9.44866C9.96197 9.44866 10.2665 9.1441 10.2665 8.7684L10.2665 2.64602C10.2665 2.27032 9.96197 1.96575 9.58627 1.96575C9.21057 1.96575 8.906 2.27032 8.906 2.64602L8.906 8.08813L3.46389 8.08813C3.08819 8.08813 2.78362 8.3927 2.78362 8.7684C2.78362 9.1441 3.08819 9.44866 3.46389 9.44866L9.58627 9.44866ZM0.942076 1.08624L9.10525 9.24942L10.0673 8.28738L1.90412 0.124205L0.942076 1.08624Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
        <div className="w-8/12 flex flex-col gap-2 md:gap-6">
          <div className="flex items-center gap-2">
            <div className="h-0.5 w-8 bg-primary-100"></div>
            <span className="text-primary-100 font-normal text-base">
              {rubricName}
            </span>
          </div>
          <h5 className="text-black text-sm md:text-xl font-semibold">
            {magazine.articleTitle}
          </h5>
          <h6 className="text-4-lines text-natural-75 font-normal text-xs md:text-base font-roboto">
            <TextLineReducer
              text={deltaTextToHtml(magazine).slice(0, 500)}
              maxLines={4}
            />
          </h6>
          <div className="flex flex-row gap-4">
            <div className="flex items-center gap-1">
              <svg
                width="15"
                height="14"
                viewBox="0 0 15 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.56551 13.2707C4.10634 13.2707 1.29467 10.459 1.29467 6.99984C1.29467 3.54067 4.10634 0.729004 7.56551 0.729004C11.0247 0.729004 13.8363 3.54067 13.8363 6.99984C13.8363 10.459 11.0247 13.2707 7.56551 13.2707ZM7.56551 1.604C4.59051 1.604 2.16967 4.02484 2.16967 6.99984C2.16967 9.97484 4.59051 12.3957 7.56551 12.3957C10.5405 12.3957 12.9613 9.97484 12.9613 6.99984C12.9613 4.02484 10.5405 1.604 7.56551 1.604Z"
                  fill="#6D6A7F"
                />
                <path
                  d="M9.72966 9.29253C9.65382 9.29253 9.57799 9.27503 9.50799 9.22836L7.69966 8.14919C7.25049 7.88086 6.91799 7.29169 6.91799 6.77253V4.38086C6.91799 4.14169 7.11632 3.94336 7.35549 3.94336C7.59466 3.94336 7.79299 4.14169 7.79299 4.38086V6.77253C7.79299 6.98253 7.96799 7.29169 8.14882 7.39669L9.95716 8.47586C10.1672 8.59836 10.2313 8.86669 10.1088 9.07669C10.0213 9.21669 9.87549 9.29253 9.72966 9.29253Z"
                  fill="#6D6A7F"
                />
              </svg>

              <span className="text-natural-75 text-sm font-light">
                {formatDatePost(magazine.created_at)}
              </span>
            </div>
            <div className="flex items-center gap-1">
              <svg
                width="15"
                height="14"
                viewBox="0 0 15 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.23217 3.354C4.99301 3.354 4.79467 3.15567 4.79467 2.9165V1.1665C4.79467 0.927337 4.99301 0.729004 5.23217 0.729004C5.47134 0.729004 5.66967 0.927337 5.66967 1.1665V2.9165C5.66967 3.15567 5.47134 3.354 5.23217 3.354Z"
                  fill="#6D6A7F"
                />
                <path
                  d="M9.89884 3.354C9.65967 3.354 9.46134 3.15567 9.46134 2.9165V1.1665C9.46134 0.927337 9.65967 0.729004 9.89884 0.729004C10.138 0.729004 10.3363 0.927337 10.3363 1.1665V2.9165C10.3363 3.15567 10.138 3.354 9.89884 3.354Z"
                  fill="#6D6A7F"
                />
                <path
                  d="M9.31551 13.2707H5.81551C2.53717 13.2707 1.87801 11.7248 1.87801 9.22817V5.629C1.87801 2.864 2.81134 1.73817 5.20884 1.604H9.89884C9.90467 1.604 9.91634 1.604 9.92217 1.604C12.3197 1.73817 13.253 2.864 13.253 5.629V9.22817C13.253 11.7248 12.5938 13.2707 9.31551 13.2707ZM5.23217 2.479C3.59884 2.57234 2.75301 3.08567 2.75301 5.629V9.22817C2.75301 11.4623 3.17884 12.3957 5.81551 12.3957H9.31551C11.9522 12.3957 12.378 11.4623 12.378 9.22817V5.629C12.378 3.0915 11.538 2.57234 9.88717 2.479H5.23217Z"
                  fill="#6D6A7F"
                />
                <path
                  d="M12.6697 10.7041H2.46134C2.22217 10.7041 2.02384 10.5058 2.02384 10.2666C2.02384 10.0274 2.22217 9.8291 2.46134 9.8291H12.6697C12.9088 9.8291 13.1072 10.0274 13.1072 10.2666C13.1072 10.5058 12.9088 10.7041 12.6697 10.7041Z"
                  fill="#6D6A7F"
                />
                <path
                  d="M7.56551 4.8125C6.84801 4.8125 6.24134 5.20333 6.24134 5.96167C6.24134 6.32333 6.41051 6.5975 6.66717 6.7725C6.31134 6.9825 6.10717 7.32083 6.10717 7.7175C6.10717 8.44083 6.66134 8.89 7.56551 8.89C8.46384 8.89 9.02384 8.44083 9.02384 7.7175C9.02384 7.32083 8.81967 6.97667 8.45801 6.7725C8.72051 6.59167 8.88384 6.32333 8.88384 5.96167C8.88384 5.20333 8.28301 4.8125 7.56551 4.8125ZM7.56551 6.46917C7.26217 6.46917 7.04051 6.28833 7.04051 6.0025C7.04051 5.71083 7.26217 5.54167 7.56551 5.54167C7.86884 5.54167 8.09051 5.71083 8.09051 6.0025C8.09051 6.28833 7.86884 6.46917 7.56551 6.46917ZM7.56551 8.16667C7.18051 8.16667 6.90051 7.97417 6.90051 7.62417C6.90051 7.27417 7.18051 7.0875 7.56551 7.0875C7.95051 7.0875 8.23051 7.28 8.23051 7.62417C8.23051 7.97417 7.95051 8.16667 7.56551 8.16667Z"
                  fill="#6D6A7F"
                />
              </svg>
              <span className="text-natural-75 text-xs font-light">
                {formatDateMMMddyyy(magazine.created_at)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}
