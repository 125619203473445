import React from "react";
import {
  BookSquareSVG,
  BoxSVG,
  MagazineSVG,
  PortraitSVG,
  ShoppingSVG,
  UsersSVG,
} from "../../components";
import { PlayCircleSVG } from "../../components/svg/play-circle.svg";
import { getCountData } from "../../core/firebase/firestore-collection";

import { Page } from "../../layouts/page";
import { Card } from "./components/card";

export default function Dashboard() {
  const [lisCount, setListCount] = React.useState<Record<string, number>>({});
  React.useEffect(() => {
    loadCount();
  }, []);

  const loadCount = async () => {
    const data = await getCountData();
    setListCount(data);
  };

  return (
    <Page>
      <div className="w-full px-6 py-6 mx-auto">
        <div className="flex flex-wrap -mx-3 gap-y-4">
          <Card
            icon={<BoxSVG color="white" height="24" width="24" />}
            quantity={lisCount.rubrics}
            title="Total Rubriques"
            color="primary"
          />
          <Card
            icon={<MagazineSVG color="white" />}
            quantity={lisCount.articles}
            title="Total Magazines"
            color="success"
          />
          <Card
            icon={<PortraitSVG color="white" />}
            quantity={lisCount.portaits}
            title="Total Portraits"
            color="danger"
          />
          <Card
            icon={<PlayCircleSVG color="white" />}
            quantity={lisCount.videos}
            title="Total Videos"
            color="primary"
          />
          <Card
            icon={<BookSquareSVG color="white" />}
            quantity={lisCount.magazines}
            title="Total Emagazines"
            color="danger"
          />
          <Card
            icon={<ShoppingSVG color="white" />}
            quantity={lisCount.sciences}
            title="Total Sciences"
            color="warning"
          />
          <Card
            icon={<UsersSVG color="white" height="24" width="24" />}
            quantity={lisCount.users}
            title="Total Utilisateurs"
            color="success"
          />
        </div>
      </div>
    </Page>
  );
}
