import { ActionType } from "../../app/interfaces/enum";
import { PortraitResponse } from "../../app/interfaces/interfaces";
import {
  ACTION_PORTRAIT_LOADING,
  ACTION_PORTRAIT_TYPE,
  MESSAGE_PORTRAIT_ERROR,
  CREATE_PORTRAIT_SUCCESS,
  DELETE_PORTRAIT_SUCCESS,
  GET_ALL_PORTRAIT_ERROR,
  GET_ALL_PORTRAIT_SUCCESS,
  PORTRAIT_LOADING,
  SEARCH_PORTRAIT_INPUT,
  SHOW_ALERT_PORTRAIT,
  SHOW_PORTRAIT_MODAL,
  TITLE_PORTRAIT_INPUT,
  UPDATE_PORTRAIT_SUCCESS,
  PICTURE_PORTRAIT_INPUT,
} from "../types/types";

export interface StatePortrait {
  title: string;
  picture: any;
  search: string;
  isLoading: boolean;
  actionLoading: boolean;
  showModal: boolean;
  showAlert: boolean;
  error: string;
  portraits: PortraitResponse[];
  messageError: string;
  action: ActionType;
}

const initialStatePortrait: StatePortrait = {
  title: "",
  picture: "",
  search: "",
  isLoading: false,
  actionLoading: false,
  showModal: false,
  showAlert: false,
  error: "",
  portraits: [],
  messageError: "",
  action: ActionType.POST,
};

export const reducerPortrait = (state = initialStatePortrait, action: any) => {
  switch (action.type) {
    case PORTRAIT_LOADING:
      return {
        ...state,
        isLoading: action.payload,
        error: "",
      };
    case TITLE_PORTRAIT_INPUT:
      return {
        ...state,
        title: action.payload,
      };
    case PICTURE_PORTRAIT_INPUT:
      return {
        ...state,
        picture: action.payload,
      };
    case SEARCH_PORTRAIT_INPUT:
      return {
        ...state,
        search: action.payload,
      };
    case GET_ALL_PORTRAIT_SUCCESS:
      return {
        ...state,
        portraits: action.payload,
        isLoading: false,
      };
    case GET_ALL_PORTRAIT_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case SHOW_PORTRAIT_MODAL:
      return {
        ...state,
        showModal: action.payload,
        error: "",
      };
    case ACTION_PORTRAIT_TYPE:
      return {
        ...state,
        action: action.payload,
        error: "",
      };
    case ACTION_PORTRAIT_LOADING:
      return {
        ...state,
        actionLoading: action.payload,
        error: "",
      };
    case CREATE_PORTRAIT_SUCCESS:
      return {
        ...state,
        portraits: [...state.portraits, action.payload],
        messageError: "",
        actionLoading: false,
      };
    case MESSAGE_PORTRAIT_ERROR:
      return {
        ...state,
        messageError: "",
        actionLoading: false,
      };
    case SHOW_ALERT_PORTRAIT:
      return {
        ...state,
        showAlert: action.payload,
        createError: "",
      };
    case DELETE_PORTRAIT_SUCCESS:
      const copy = [...state.portraits];
      const index = copy.findIndex((element) => element.id === action.payload);
      if (index > -1) {
        copy.splice(index, 1);
      }
      return {
        ...state,
        portraits: copy,
        createError: "",
        actionLoading: false,
      };
    case UPDATE_PORTRAIT_SUCCESS:
      const copyUpdate = [...state.portraits];
      const item = copyUpdate.find(
        (element) => element.id === action.payload.id
      );
      const i = copyUpdate.indexOf(item!);
      copyUpdate[i] = action.payload;
      return {
        ...state,
        portraits: copyUpdate,
        createError: "",
        actionLoading: false,
      };
    default:
      return state;
  }
};
