export function SanteSVG({ ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.66669 11.3335C10.7713 11.3335 11.6667 10.4381 11.6667 9.3335C11.6667 8.22893 10.7713 7.3335 9.66669 7.3335C8.56212 7.3335 7.66669 8.22893 7.66669 9.3335C7.66669 10.4381 8.56212 11.3335 9.66669 11.3335Z"
        fill="currentColor"
      />
      <path
        d="M17 11.6667C18.2887 11.6667 19.3334 10.622 19.3334 9.33333C19.3334 8.04467 18.2887 7 17 7C15.7114 7 14.6667 8.04467 14.6667 9.33333C14.6667 10.622 15.7114 11.6667 17 11.6667Z"
        fill="currentColor"
      />
      <path
        d="M9.50002 17C10.6527 17 11.737 16.6817 12.6667 16.125V13.6667C12.6667 12.7463 11.9204 12 11 12H8.33335C7.41302 12 6.66669 12.7463 6.66669 13.6667V16.3047C7.51602 16.7463 8.47835 17 9.50002 17Z"
        fill="currentColor"
      />
      <path
        d="M8.07335 3.661L7.08935 3.07233C7.01402 3.02767 6.92735 3 6.83335 3C6.57302 3 6.33335 3.21467 6.33335 3.5C6.33335 3.68233 6.43169 3.84 6.57735 3.92733L7.74435 4.62C6.94869 6.36567 5.46002 8.66667 3.66235 8.66667C2.48435 8.66667 1.66669 7.64667 1.66669 6.501C1.66669 5.01033 2.28735 3.35033 3.13202 1.928L4.24402 2.59367C4.31935 2.639 4.40602 2.66667 4.50002 2.66667C4.77635 2.66667 5.00002 2.44267 5.00002 2.16667C5.00002 1.98433 4.90169 1.82667 4.75602 1.73933C4.19835 1.407 3.98669 1.281 3.79235 1.16533C3.60769 1.05533 3.35302 1 3.13335 1C2.79135 1 2.39335 1.20267 2.16235 1.60667C1.31569 3.08767 0.666687 4.81767 0.666687 6.47467C0.666687 8.13833 1.88535 9.66667 3.56435 9.66667C5.85369 9.66667 7.49802 7.43633 8.55035 5.231C8.62869 5.06733 8.66669 4.88867 8.66669 4.70733C8.66669 4.27833 8.44135 3.88133 8.07335 3.661V3.661Z"
        fill="currentColor"
      />
      <path
        d="M14.6667 2.1665C14.6667 1.89017 14.4427 1.6665 14.1667 1.6665C13.971 1.6665 13.803 1.78017 13.721 1.94417L13.0543 3.2775C13.0207 3.34484 13 3.4195 13 3.49984C13 3.77617 13.224 3.99984 13.5 3.99984C13.6957 3.99984 13.8637 3.88617 13.9457 3.72217L14.6123 2.38884C14.646 2.3215 14.6667 2.24684 14.6667 2.1665Z"
        fill="currentColor"
      />
      <path
        d="M10.3333 1.8335C10.3333 1.5575 10.5573 1.3335 10.8333 1.3335C11.1093 1.3335 11.3333 1.5575 11.3333 1.8335V3.16683C11.3333 3.44283 11.1093 3.66683 10.8333 3.66683C10.5573 3.66683 10.3333 3.44283 10.3333 3.16683V1.8335Z"
        fill="currentColor"
      />
      <path
        d="M16.5 3.6665C16.362 3.6665 16.237 3.7225 16.1463 3.81284L15.1463 4.81284C15.056 4.9035 15 5.0285 15 5.1665C15 5.4425 15.2237 5.6665 15.5 5.6665C15.638 5.6665 15.763 5.6105 15.8537 5.52017L16.8537 4.52017C16.944 4.4295 17 4.3045 17 4.1665C17 3.8905 16.7763 3.6665 16.5 3.6665Z"
        fill="currentColor"
      />
      <path
        d="M17 12.3331C16.7153 12.3331 16.4403 12.2908 16.1793 12.2161C15.5383 15.3248 12.7986 17.6665 9.49998 17.6665C5.72598 17.6665 2.66665 14.6071 2.66665 10.8331C2.66665 10.6238 2.67898 10.4175 2.69765 10.2128C2.23498 10.0871 1.80865 9.86381 1.43731 9.56348C1.37831 9.96214 1.33331 10.4255 1.33331 10.8331C1.33331 15.3435 4.98965 18.9998 9.49998 18.9998C13.514 18.9998 16.8426 16.1005 17.5283 12.2835C17.3566 12.3145 17.1806 12.3331 17 12.3331V12.3331Z"
        fill="currentColor"
      />
    </svg>
  );
}
