import classNames from "classnames";

type ButtonColor =
  | "primary"
  | "natural"
  | "success"
  | "danger"
  | "warning"
  | "outline-danger"
  | "blue"
  | "outline-success";
type ButtonType = "button" | "submit";

const buttonColor = {
  primary: ["bg-primary-100", "hover:bg-primary-80", "text-white"],
  natural: ["bg-natural-40 ", "hover:bg-natural-80", "text-natural-70"],
  success: ["bg-success-100 ", "hover:bg-success-80", "text-white"],
  blue: ["bg-primary-90", "hover:bg-primary-80", "text-white"],
  danger: ["bg-danger-100", "hover:bg-danger-80", "text-white"],
  warning: ["bg-warning-100 ", "hover:bg-warning-80", "text-white"],
  "outline-danger": ["bg-danger-60 border-2 ", "border-danger-80"],
  "outline-success": [
    "bg-success-60 border-2 ",
    "border-success-80",
    "text-success-100",
  ],
};

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color: ButtonColor;
  title?: string;
  type?: ButtonType;
  icon?: React.ReactNode;
  className?: string;
  block?: boolean;
  disabled?: boolean;
  tooltip?: string;
}

export function Button({
  color,
  title,
  type,
  icon,
  className,
  block,
  disabled,
  tooltip,
  ...props
}: React.PropsWithChildren<ButtonProps>) {
  return (
    <button
      type={type}
      className={classNames(
        disabled ? "bg-natural-40 text-natural-70" : "",
        buttonColor[color],
        block ?? "w-full",
        "rounded-lg flex justify-center items-center p-2 ",
        className ?? className
      )}
      disabled={disabled}
      {...props}
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      title={tooltip}
    >
      {icon && <span>{icon}</span>}
      {title && <span className="font-semibold mx-2"> {title}</span>}
    </button>
  );
}
