export function TwitterSVG() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9276 1.14111C6.37805 1.14111 1.06876 6.45041 1.06876 12.9999C1.06876 19.5495 6.37805 24.8588 12.9276 24.8588C19.4771 24.8588 24.7864 19.5495 24.7864 12.9999C24.7864 6.45041 19.4771 1.14111 12.9276 1.14111ZM17.7514 10.8555C17.7563 10.9568 17.7576 11.0581 17.7576 11.1569C17.7576 14.2451 15.4093 17.804 11.1129 17.804C9.84341 17.8061 8.6003 17.4415 7.53305 16.754C7.71464 16.7762 7.90117 16.7849 8.09017 16.7849C9.18464 16.7849 10.1914 16.4131 10.9906 15.7855C10.5036 15.776 10.0316 15.6147 9.6406 15.3242C9.24956 15.0337 8.95889 14.6284 8.80911 14.1648C9.15889 14.2313 9.51922 14.2174 9.86282 14.1241C9.33417 14.0172 8.85877 13.7307 8.51722 13.3133C8.17567 12.8959 7.98901 12.3732 7.98887 11.8338V11.8054C8.30387 11.9796 8.66458 12.0858 9.04752 12.0982C8.55192 11.7683 8.20108 11.2615 8.06675 10.6815C7.93243 10.1014 8.02476 9.49201 8.32487 8.97782C8.91158 9.69923 9.64325 10.2894 10.4725 10.7101C11.3018 11.1308 12.2101 11.3726 13.1388 11.42C13.0208 10.9188 13.0716 10.3927 13.2834 9.92344C13.4952 9.45415 13.8561 9.06798 14.31 8.82497C14.7639 8.58195 15.2854 8.49571 15.7933 8.57965C16.3013 8.66359 16.7673 8.913 17.1189 9.28911C17.6417 9.18569 18.143 8.99395 18.6013 8.72211C18.4271 9.26334 18.0623 9.72292 17.5748 10.0155C18.0378 9.9597 18.4899 9.83521 18.9163 9.64611C18.6031 10.1154 18.2086 10.5249 17.7514 10.8555Z"
        fill="white"
      />
    </svg>
  );
}
