import { Link } from "react-router-dom";
import { ArticleResponse } from "../../app/interfaces/interfaces";
import { formatDatePost } from "../../utils/utils";
import { TimeSVG } from "./svg/time.svg";

interface LastPostProps {
  magazine: ArticleResponse;
}

export function LastPost({ magazine }: LastPostProps) {
  return (
    <div className="flex flex-col w-1/4">
      <Link to={`/magazine-detail/${magazine.id}`}>
        <div className="h-14">
          <h5 className="text-2-lines text-background-99 text-sm md:text-base font-semibold">
            {magazine.articleTitle}
          </h5>
        </div>
        <div className="flex items-center gap-2">
          <TimeSVG />
          <span className="font-normal text-xs md:text-base">
            {formatDatePost(magazine.created_at)}
          </span>
        </div>
      </Link>
    </div>
  );
}
