import { isEmpty, isNil, lowerCase } from "lodash";
import React from "react";
import { toast, ToastContainer } from "react-toastify";
import {
  AddSVG,
  Button,
  Input,
  Modal,
  ModalVideo,
  Selected,
  Spinner,
} from "../../components";
import {
  deleteVideo,
  getRubrics,
  getVideos,
  setDbVideo,
  updateVideo,
} from "../../core/firebase/firestore-collection";
import { ActionType } from "../../interfaces/enum";
import { Page } from "../../layouts/page";
import { v4 as uuid } from "uuid";
import { RubricResponse, ViodeoRequest } from "../../interfaces/interfaces";
import ReactHtmlParser from "react-html-parser";
import axios from "axios";
import { VideoCard } from "../../../site/components";
import AlertConfirmation from "../../components/AlertConfirmation";

export default function VideoPage() {
  const [_show, setShow] = React.useState<boolean>(false);
  const [_showIframe, setShowIframe] = React.useState<boolean>(false);
  const [_action, setAction] = React.useState<ActionType>(ActionType.POST);
  const [_isLoader, setLoader] = React.useState<boolean>(false);
  const [_isLoading, setLoading] = React.useState<boolean>(false);
  const [_searchVideo, setSearcVideo] = React.useState<string>();
  const [_videos, setVideos] = React.useState<ViodeoRequest[]>([]);
  const [_video, setVideo] = React.useState<ViodeoRequest>();
  const [_showAlert, setShowAlert] = React.useState<boolean>(false);
  const [_rubrics, setRubrics] = React.useState<RubricResponse[]>([]);

  const [form, _setFormReference] = React.useState<HTMLFormElement | null>(
    null
  );

  const _confirmForm = React.useCallback(() => {
    if (!isNil(form)) {
      form.requestSubmit();
    }
  }, [form]);

  const fecthVideos = React.useCallback(async () => {
    setVideos([]);
    setLoader(true);
    (await getVideos()).forEach((item) => {
      const videos = item.data() as ViodeoRequest;
      setVideos((old) => [...old!, videos]);
    });
    setLoader(false);
  }, []);

  const fecthRubrics = React.useCallback(async () => {
    setRubrics([]);
    (await getRubrics()).forEach((item) => {
      const rubric = item.data() as RubricResponse;
      setRubrics((old) => [...old!, rubric]);
    });
  }, []);

  React.useEffect(() => {
    fecthVideos();
    fecthRubrics();
  }, [fecthRubrics, fecthVideos]);

  const onFormSubmit = React.useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setLoading(true);
      const target = e.target as typeof e.target & {
        title: { value: string };
        videoUrl: { value: string };
        rubricId: { value: string };
      };
      const result = await axios.get(
        `http://youtube.com/oembed?url=${target.videoUrl.value}&format=json`
      );
      if (_action === ActionType.POST) {
        const id: string = uuid();
        try {
          if (result.data) {
            await setDbVideo(id, {
              id: id,
              title: target.title.value,
              rubricId: target.rubricId.value,
              videoUrl: target.videoUrl.value,
              metada: result.data,
              created_at: new Date().toString(),
            }).then(async () => {
              setShow(false);
              await fecthVideos();
              toast("Video créer", { type: "success" });
            });
          }
        } catch (error: any) {
          toast(error.message, { type: "error" });
        }
      } else {
        if (result.data) {
          try {
            await updateVideo(_video!.id, {
              title: target.title.value,
              videoUrl: target.videoUrl.value,
              rubricId: target.rubricId.value,
            }).then(async () => {
              setShow(false);
              await fecthVideos();
              toast("video mise a jour", { type: "success" });
            });
          } catch (error: any) {
            toast(error.message, { type: "error" });
          }
        }
      }
      setLoading(false);
    },
    [_action, _video, fecthVideos]
  );

  const onDeleteVideo = React.useCallback(
    async (id: string) => {
      setLoading(true);
      try {
        await deleteVideo(id).then(async () => {
          toast("Video supprimer", { type: "success" });
          await fecthVideos();
        });
      } catch (error: any) {
        toast(error.message, { type: "error" });
      }
      setLoading(false);
      setShowAlert(false);
    },
    [fecthVideos]
  );

  const _findVideo = React.useMemo(() => {
    if (isEmpty(_searchVideo)) return _videos;

    return _videos.filter((item) => {
      return lowerCase(item.title).includes(lowerCase(_searchVideo));
    });
  }, [_searchVideo, _videos]);
  return (
    <>
      <ToastContainer />
      <Page
        left={
          <h1 className="font-bold text-2xl text-background-100">Videos</h1>
        }
      >
        {_isLoader ? (
          <Spinner />
        ) : (
          <>
            <div className="flex sm:flex-row flex-col sm:gap-0 gap-5 justify-between mt-5">
              <div className="flex flex-row ">
                <Input
                  block
                  type="search"
                  placeholder="Chercher une video"
                  id="title"
                  onChange={(e) => setSearcVideo(e.target.value)}
                  name="title"
                  className="md:w-60"
                />
              </div>
              <div>
                <Button
                  color="primary"
                  type="button"
                  icon={<AddSVG />}
                  title="Nouvelle videos"
                  className="w-auto"
                  onClick={() => {
                    setShow(true);
                    setAction(ActionType.POST);
                  }}
                />
              </div>
            </div>
            <div className="mt-16">
              {isEmpty(_videos) ? (
                <div className="bg-white py-12 flex mt-8 items-center justify-center w-full">
                  <p>No Data</p>
                </div>
              ) : (
                <div className="grid grid-cols-4 gap-y-4 p-4 -mx-4 ring-1 bg-white border-0 ring-white sm:-mx-6 md:mx-0 md:rounded-xl">
                  {_findVideo.map((v) => {
                    const name = _rubrics.find(
                      (item) => item.id === v.rubricId
                    )?.name;
                    return (
                      <VideoCard
                        video={v}
                        rubricName={name!}
                        isEditable
                        onDelete={(item) => {
                          setVideo(item);
                          setShowAlert(true);
                        }}
                        openIframe={(item) => {
                          setShowIframe(true);
                          setVideo(item);
                        }}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </>
        )}
      </Page>

      <AlertConfirmation
        description={`Supprimer cette video`}
        title="Veuillez confirmer s'il vous plait...!"
        onShow={() => setShowAlert(!_showAlert)}
        show={_showAlert}
        loading={_isLoading}
        onConfirm={() => onDeleteVideo(_video?.id!)}
      />

      <Modal
        show={_show}
        loading={_isLoading}
        onShow={() => setShow(!_show)}
        onConfirm={_confirmForm}
        title={
          _action === ActionType.POST
            ? "Ajouter une video"
            : "Modifier une video"
        }
      >
        <form
          className="mt-8 space-y-3"
          ref={_setFormReference}
          onSubmit={onFormSubmit}
        >
          <div className="w-full">
            <div>
              <label className="block text-base font-medium text-slate-700 mb-1">
                Choisir la rubrique
                <span className="text-danger-100">*</span>
              </label>
              <Selected
                block
                className="h-12"
                name="rubricId"
                required={true}
                datas={_rubrics}
                onChange={(item) => console.log(item.target.value)}
              />
            </div>
          </div>
          <div className="w-full">
            <label className="block text-sm font-mediumtext-gray-700 mb-1">
              Titre de la video <span className="text-danger-100">*</span>
            </label>
            <Input
              block
              type="text"
              name="title"
              // defaultValue={_action === ActionType.PATCH ? _portrait?.name : ""}
              placeholder="Nom"
              required={true}
            />
          </div>
          <div className="w-full">
            <label className="block text-sm font-mediumtext-gray-700 mb-1">
              Lien youtube<span className="text-danger-100">*</span>
            </label>
            <Input
              block
              type="text"
              name="videoUrl"
              // defaultValue={_action === ActionType.PATCH ? _portrait?.name : ""}
              placeholder="Lien"
              required={true}
            />
          </div>
        </form>
      </Modal>
      <ModalVideo show={_showIframe} onShow={() => setShowIframe(!_showIframe)}>
        {!isEmpty(_video) && ReactHtmlParser(_video?.metada.html)}
      </ModalVideo>
    </>
  );
}
