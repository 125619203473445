export function CalendarSVG() {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 4.8125C5.6925 4.8125 5.4375 4.5575 5.4375 4.25V2C5.4375 1.6925 5.6925 1.4375 6 1.4375C6.3075 1.4375 6.5625 1.6925 6.5625 2V4.25C6.5625 4.5575 6.3075 4.8125 6 4.8125Z"
        fill="#6D6A7F"
      />
      <path
        d="M12 4.8125C11.6925 4.8125 11.4375 4.5575 11.4375 4.25V2C11.4375 1.6925 11.6925 1.4375 12 1.4375C12.3075 1.4375 12.5625 1.6925 12.5625 2V4.25C12.5625 4.5575 12.3075 4.8125 12 4.8125Z"
        fill="#6D6A7F"
      />
      <path
        d="M11.25 17.5625H6.75C2.535 17.5625 1.6875 15.575 1.6875 12.365V7.7375C1.6875 4.1825 2.8875 2.735 5.97 2.5625H12C12.0075 2.5625 12.0225 2.5625 12.03 2.5625C15.1125 2.735 16.3125 4.1825 16.3125 7.7375V12.365C16.3125 15.575 15.465 17.5625 11.25 17.5625ZM6 3.6875C3.9 3.8075 2.8125 4.4675 2.8125 7.7375V12.365C2.8125 15.2375 3.36 16.4375 6.75 16.4375H11.25C14.64 16.4375 15.1875 15.2375 15.1875 12.365V7.7375C15.1875 4.475 14.1075 3.8075 11.985 3.6875H6Z"
        fill="#6D6A7F"
      />
      <path
        d="M15.5625 14.2627H2.4375C2.13 14.2627 1.875 14.0077 1.875 13.7002C1.875 13.3927 2.13 13.1377 2.4375 13.1377H15.5625C15.87 13.1377 16.125 13.3927 16.125 13.7002C16.125 14.0077 15.87 14.2627 15.5625 14.2627Z"
        fill="#6D6A7F"
      />
      <path
        d="M9 6.6875C8.0775 6.6875 7.2975 7.19 7.2975 8.165C7.2975 8.63 7.515 8.9825 7.845 9.2075C7.3875 9.4775 7.125 9.9125 7.125 10.4225C7.125 11.3525 7.8375 11.93 9 11.93C10.155 11.93 10.875 11.3525 10.875 10.4225C10.875 9.9125 10.6125 9.47 10.1475 9.2075C10.485 8.975 10.695 8.63 10.695 8.165C10.695 7.19 9.9225 6.6875 9 6.6875ZM9 8.8175C8.61 8.8175 8.325 8.585 8.325 8.2175C8.325 7.8425 8.61 7.625 9 7.625C9.39 7.625 9.675 7.8425 9.675 8.2175C9.675 8.585 9.39 8.8175 9 8.8175ZM9 11C8.505 11 8.145 10.7525 8.145 10.3025C8.145 9.8525 8.505 9.6125 9 9.6125C9.495 9.6125 9.855 9.86 9.855 10.3025C9.855 10.7525 9.495 11 9 11Z"
        fill="#6D6A7F"
      />
    </svg>
  );
}
