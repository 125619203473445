export function BusinessSVG({ ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19 11.3332C19 11.8855 18.5523 12.3332 18 12.3332C17.4477 12.3332 17 11.8855 17 11.3332V10.6665H19V11.3332Z"
        fill="currentColor"
      />
      <path
        d="M7 6.99984V5.6665C7 5.30184 7.09967 4.96117 7.27067 4.6665H2C1.44767 4.6665 1 5.11417 1 5.6665V6.99984H7Z"
        fill="currentColor"
      />
      <path
        d="M16 11.3335V2.3335C16 1.78116 15.5523 1.3335 15 1.3335C14.4477 1.3335 14 1.78116 14 2.3335V12.6668H16.5137C16.1953 12.3128 16 11.8458 16 11.3335Z"
        fill="currentColor"
      />
      <path
        d="M5 1.3335C4.44767 1.3335 4 1.78116 4 2.3335V3.66683H9C10.1043 3.66683 11 4.5625 11 5.66683V17.2965C11 17.7802 11.327 18.2235 11.802 18.3148C12.441 18.4372 13 17.9508 13 17.3335V2.3335C13 1.96883 13.0997 1.62816 13.2707 1.3335H5Z"
        fill="currentColor"
      />
      <path
        d="M10 17.2962V5.6665C10 5.11517 9.55133 4.6665 9 4.6665C8.44767 4.6665 8 5.11417 8 5.6665V7.99984H2V17.6665C2 18.2158 2.45067 18.6665 3 18.6665H10.5233C10.199 18.3015 10 17.8208 10 17.2962ZM6 12.6665C7.28633 12.6665 8.33333 13.5635 8.33333 14.6665C8.33333 15.5708 7.62933 16.3358 6.66667 16.5822V16.6665C6.66667 17.0345 6.368 17.3332 6 17.3332C5.632 17.3332 5.33333 17.0345 5.33333 16.6665V16.5822C4.45567 16.3515 3.92533 15.7002 3.72967 15.2828C3.687 15.1912 3.66667 15.0948 3.66667 14.9998C3.66667 14.6292 3.96833 14.3332 4.33333 14.3332C4.584 14.3332 4.824 14.4755 4.937 14.7168C4.96533 14.7725 5.26933 15.3332 6 15.3332C6.53267 15.3332 7 15.0215 7 14.6665C7 14.3115 6.53267 13.9998 6 13.9998C4.71367 13.9998 3.66667 13.1028 3.66667 11.9998C3.66667 11.0955 4.37067 10.3305 5.33333 10.0842V9.99984C5.33333 9.63184 5.632 9.33317 6 9.33317C6.368 9.33317 6.66667 9.63184 6.66667 9.99984V10.0842C7.54433 10.3148 8.07467 10.9662 8.27033 11.3835C8.313 11.4752 8.33333 11.5715 8.33333 11.6665C8.33333 12.0365 8.032 12.3335 7.66667 12.3332C7.41667 12.3332 7.17667 12.1912 7.063 11.9495C7.03467 11.8938 6.73067 11.3332 6 11.3332C5.46733 11.3332 5 11.6448 5 11.9998C5 12.3548 5.46733 12.6665 6 12.6665Z"
        fill="currentColor"
      />
    </svg>
  );
}
