interface TimeSvgProps {
    fill?: string;
}

export function TimeSVG({fill="#807E7C"}: TimeSvgProps) {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 17.5625C4.5525 17.5625 0.9375 13.9475 0.9375 9.5C0.9375 5.0525 4.5525 1.4375 9 1.4375C13.4475 1.4375 17.0625 5.0525 17.0625 9.5C17.0625 13.9475 13.4475 17.5625 9 17.5625ZM9 2.5625C5.175 2.5625 2.0625 5.675 2.0625 9.5C2.0625 13.325 5.175 16.4375 9 16.4375C12.825 16.4375 15.9375 13.325 15.9375 9.5C15.9375 5.675 12.825 2.5625 9 2.5625Z"
        fill={fill}
      />
      <path
        d="M11.7825 12.4478C11.685 12.4478 11.5875 12.4253 11.4975 12.3653L9.17248 10.9778C8.59498 10.6328 8.16748 9.87531 8.16748 9.20781V6.13281C8.16748 5.82531 8.42248 5.57031 8.72998 5.57031C9.03748 5.57031 9.29248 5.82531 9.29248 6.13281V9.20781C9.29248 9.47781 9.51748 9.87531 9.74998 10.0103L12.075 11.3978C12.345 11.5553 12.4275 11.9003 12.27 12.1703C12.1575 12.3503 11.97 12.4478 11.7825 12.4478Z"
        fill={fill}
      />
    </svg>
  );
}
