export function InnovationSVG({ ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.6666 12.3332C12.403 12.3332 13 11.7362 13 10.9998C13 10.2635 12.403 9.6665 11.6666 9.6665C10.9303 9.6665 10.3333 10.2635 10.3333 10.9998C10.3333 11.7362 10.9303 12.3332 11.6666 12.3332Z"
        fill="currentColor"
      />
      <path
        d="M12.3333 12.9998H10.276L8.138 10.8618C8.01733 10.7412 7.85067 10.6665 7.66667 10.6665C7.28667 10.6665 7 10.9675 7 11.3332C7 11.5172 7.07467 11.6838 7.19533 11.8045L9.66667 14.3332V16.6665H13V15.3332C13 15.1492 13.1493 14.9998 13.3333 14.9998C13.5173 14.9998 13.6667 15.1492 13.6667 15.3332V16.6665H14.6667V15.3332C14.6667 14.0445 13.622 12.9998 12.3333 12.9998Z"
        fill="currentColor"
      />
      <path
        d="M8.99998 10.0002C9.18398 10.0002 9.33331 9.85083 9.33331 9.66683V2.66683C9.33331 2.48283 9.18398 2.3335 8.99998 2.3335H1.66665C1.48265 2.3335 1.33331 2.48283 1.33331 2.66683V9.66683C1.33331 9.85083 1.48265 10.0002 1.66665 10.0002H8.99998ZM6.99998 4.00016H8.33331V8.3335H6.99998V4.00016ZM4.66665 4.66683H5.99998V8.3335H4.66665V4.66683ZM3.66665 8.3335H2.33331V5.3335H3.66665V8.3335Z"
        fill="currentColor"
      />
      <path
        d="M17 7.3335H10.3333V8.66683H15.6667V17.6668H4.33333V11.0002H3V17.6668C3 18.4032 3.597 19.0002 4.33333 19.0002H17C17.7363 19.0002 18.3333 18.4032 18.3333 17.6668V8.66683C18.3333 7.9305 17.7363 7.3335 17 7.3335ZM17 14.0002C16.632 14.0002 16.3333 13.7015 16.3333 13.3335C16.3333 12.9655 16.632 12.6668 17 12.6668C17.368 12.6668 17.6667 12.9655 17.6667 13.3335C17.6667 13.7015 17.368 14.0002 17 14.0002Z"
        fill="currentColor"
      />
      <path
        d="M14.8333 5.3335C15.1093 5.3335 15.3333 5.5575 15.3333 5.8335C15.3333 6.1095 15.1093 6.3335 14.8333 6.3335H13.5C13.2237 6.3335 13 6.1095 13 5.8335C13 5.5575 13.2237 5.3335 13.5 5.3335H14.8333Z"
        fill="currentColor"
      />
      <path
        d="M10.3333 1.8335C10.3333 1.5575 10.557 1.3335 10.8333 1.3335C11.1093 1.3335 11.3333 1.5575 11.3333 1.8335V3.16683C11.3333 3.44283 11.1093 3.66683 10.8333 3.66683C10.557 3.66683 10.3333 3.44283 10.3333 3.16683V1.8335Z"
        fill="currentColor"
      />
      <path
        d="M13.4797 2.47951C13.675 2.28417 13.9917 2.28417 14.187 2.47951C14.3824 2.67484 14.3824 2.99117 14.187 3.18651L13.187 4.18651C12.9917 4.38184 12.675 4.38184 12.4797 4.18651C12.2847 3.99117 12.2847 3.67484 12.4797 3.47951L13.4797 2.47951Z"
        fill="currentColor"
      />
    </svg>
  );
}
