import React from "react";
import {
  BlockNews,
  Business,
  CalendarSVG,
  SearchInputSVG,
  Tricks,
} from "./components";
import Footer from "./layouts/footer";
import Header from "./layouts/header";
import SubHeader from "./layouts/subHeader";
import {
  ArticleResponse,
  RubricResponse,
  ScienceRequest,
} from "../app/interfaces/interfaces";
import {
  getArticles,
  getRubrics,
  getSciences,
  getSciencesByElement,
} from "../app/core/firebase/firestore-collection";
import { isEmpty, lowerCase } from "lodash";
import { onSnapshot } from "firebase/firestore";
import { Link } from "react-router-dom";

export default function Science() {
  const [_rubrics, setRubrics] = React.useState<RubricResponse[]>([]);
  const [_articles, setArticles] = React.useState<ArticleResponse[]>([]);
  // const [_searchScience, setSearchScience] = React.useState<ScienceRequest[]>(
  //   []
  // );
  const [_science, setScience] = React.useState<ScienceRequest[]>([]);
  const [_searchInput, setSearchInput] = React.useState<string>();

  const fecthRubrics = React.useCallback(async () => {
    setRubrics([]);
    (await getRubrics()).forEach((item) => {
      const rubric = item.data() as RubricResponse;
      setRubrics((old) => [...old!, rubric]);
    });
  }, []);

  const fecthArticles = React.useCallback(async () => {
    setArticles([]);
    (await getArticles()).forEach((item) => {
      const article = item.data() as ArticleResponse;
      setArticles((old) => [...old, article]);
    });
  }, []);

  const fecthArticlesScience = React.useCallback(async () => {
    setArticles([]);
    (await getSciences()).forEach((item) => {
      const science = item.data() as ScienceRequest;
      setScience((old) => [...old, science]);
    });
  }, []);

  React.useEffect(() => {
    fecthArticlesScience();
    fecthRubrics();
    fecthArticles();
  }, [fecthArticles, fecthArticlesScience, fecthRubrics]);

  // const _findArticles = React.useMemo(() => {
  //   if (isEmpty(_searchInput)) return _science;

  //   return _science.filter((item) => {
  //     return (
  //       lowerCase(item.category).includes(lowerCase(_searchInput)) ||
  //       lowerCase(item.articleTitle).includes(lowerCase(_searchInput))
  //     );
  //   });
  // }, [_searchInput, _science]);

  const handleKeywordKeyPress = React.useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        if (isEmpty(_searchInput)) {
          return;
        }
        onSnapshot(
          await getSciencesByElement("category", _searchInput!),
          (querySnapshot) => {
            return querySnapshot.forEach((doc) => {
              // setSearchScience((old) => [...old, doc.data() as ScienceRequest]);
              // console.log(doc.data());
            });
          }
        );
      }
    },
    [_searchInput]
  );

  const _findSciences = React.useMemo(() => {
    if (isEmpty(_searchInput)) return _science;
    return _science.filter((item) => {
      return (
        lowerCase(item.articleTitle).includes(lowerCase(_searchInput)) ||
        lowerCase(item.category).includes(lowerCase(_searchInput))
      );
    });
  }, [_searchInput, _science]);

  return (
    <>
      <Header />
      <SubHeader />
      <div className="science_header">
        <div className="bg-science bg-no-repeat mt-4">
          <div className="md:container mx-auto w-full flex flex-wrap p-5 flex-col md:flex-row items-center justify-center">
            <div className="w-4/6 flex flex-col items-center py-16">
              <h1 className="text-base md:text-5xl font-bold text-center text-white">
                Découvrez les connaissances scientifiques et restez connectez
                avec le monde de la science
              </h1>
              <h6 className="font-normal text-lg text-white opacity-70 mt-6 mb-2 w-4/6 text-center">
                Accédez à de nombreuses recherches concernant la science
                particulièrement en Afrique et restez au courant de ce qui se
                passe dans votre domaine.
              </h6>
              <div className="relative w-full">
                <input
                  className="h-12 bg-white w-full pl-16 placeholder-natural-50"
                  placeholder="Faites vos recherches"
                  onChange={(e) => setSearchInput(e.target.value)}
                  onKeyDown={handleKeywordKeyPress}
                />
                <div className="absolute top-2 left-4 flex items-center gap-2 ">
                  <SearchInputSVG />
                  <hr className="h-8 w-0.5 bg-primary-45" />
                </div>
              </div>
              <div className="w-full flex items-center justify-center gap-4 py-6">
                <button className="bg-white bg-opacity-10 text-white rounded-md px-4 py-2 hover:bg-opacity-20">
                  Science
                </button>
                <button className="bg-white bg-opacity-10 text-white rounded-md px-4 py-2 hover:bg-opacity-20">
                  Chimie
                </button>
                <button className="bg-white bg-opacity-10 text-white rounded-md px-4 py-2 hover:bg-opacity-20">
                  Médecine
                </button>
                <button className="bg-white bg-opacity-10 text-white rounded-md px-4 py-2 hover:bg-opacity-20">
                  Découvertes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isEmpty(_searchInput) ? (
        <>
          <div className="md:container mx-auto w-full flex flex-wrap p-5 flex-col md:flex-row items-center justify-center">
            <div className="w-4/6 flex flex-col items-center">
              <h1 className="text-base md:text-4xl font-bold text-center text-black">
                Connectez vous à votre communauté scientifique
              </h1>
              <p className="font-normal text-lg w-3/6 text-center mt-4">
                Partagez vos recherches, collaborez avec vos pairs et obtenez le
                soutien dont vous avez besoin pour faire avancer votre carrière.
              </p>
            </div>
          </div>
          <div className="md:container mx-auto w-full flex flex-wrap p-5 flex-col md:flex-row items-center justify-center">
            <div className="grid grid-cols-2 gap-1">
              <div className="bg-primary-100 flex flex-col justify-center">
                <div className=" mx-auto w-4/6 flex flex-wrap p-5 flex-col md:flex-row items-center justify-center">
                  <p className="col-span-2 w-full text-left text-white text-base opacity-80 md:text-2xl font-bold ">
                    Domaines de recherche
                  </p>
                  <div className="w-full grid grid-cols-2 mx-auto gap-16 mt-8">
                    <span className="text-white opacity-70">Biologie</span>
                    <span className="text-white opacity-70">Mathématiques</span>
                  </div>
                  <div className="w-full grid grid-cols-2 mx-auto gap-16 mt-8">
                    <span className="text-white  opacity-70 col-span-1">
                      Chimie
                    </span>
                    <span className="text-white opacity-70">
                      Nucléaire et particules 
                    </span>
                  </div>
                  <div className="w-full grid grid-cols-2 mx-auto gap-16 mt-7">
                    <span className="text-white  opacity-70">
                      Ecologie et environnement
                    </span>
                    <span className="text-white opacity-70">Physique</span>
                  </div>
                  <div className="w-full grid grid-cols-2 mx-auto gap-16 mt-7">
                    <span className="text-white  opacity-70">
                      Homme et société
                    </span>
                    <span className="text-white opacity-70">
                      Sciences de l’information
                    </span>
                  </div>
                  <div className="w-full grid grid-cols-2 mx-auto gap-16 mt-7">
                    <span className="text-white  opacity-70">
                      Ingénierie et systèmes
                    </span>
                    <span className="text-white opacity-70">
                      Terre et Univers
                    </span>
                  </div>
                </div>
              </div>
              {_science.length > 0 && (
                <div
                  className="h-full w-full flex flex-col justify-center  object-cover"
                  style={{ background: `url(${_science[0].articlePic})` }}
                >
                  <div className="flex flex-col h-full py-5 px-8 justify-between">
                    <p className="text-white text-left text-base opacity-80 md:text-2xl font-bold">
                      {_science[0].articleTitle}
                    </p>
                    <div className="flex flex-row justify-end">
                      <a
                        href={`/science-detail/${_science[0].id}`}
                        className="bg-primary-100 px-16 py-4 font-normal text-sm text-white"
                      >
                        Découvrez cela ici
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="flex flex-row h-96 mt-1">
              <img src="../assets/img/never.png" alt="w-full h-full" />
              <div className="flex flex-col p-8 justify-between">
                <div>
                  <p className="text-primary-100 text-left text-base md:text-2xl font-bold">
                    A propos de Cerebrum
                  </p>
                  <p>
                    Chercheurs, ingénieurs, techniciens, personnels
                    administratifs : rejoignez le Cerebrum et publiez vos
                    recherches
                  </p>
                </div>
                <div>
                  <div className="flex flex-row">
                    <div className="flex flex-col gap-2">
                      <div className="flex flex-col">
                        <Link
                          to="#"
                          className="text-natural-99 w-20 font-medium text-base"
                        >
                          <span>Site carriere</span>
                          <div className="h-0.5 bg-primary-100 w-full"></div>
                        </Link>
                      </div>
                      <div className="flex flex-col">
                        <Link
                          to="#"
                          className="text-natural-99 w-4/6 font-medium text-base"
                        >
                          <span> Portail emploi</span>
                          <div className="h-0.5 bg-primary-100 w-full"></div>
                        </Link>
                      </div>
                      <div className="flex flex-col">
                        <Link
                          to="#"
                          className="text-natural-99 font-medium text-base"
                        >
                          <span>Magazine de recherche</span>
                          <div className="h-0.5 bg-primary-100 w-full"></div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-tech bg-no-repeat"></div>
              <div className="relative h-ful">
                <img src="../assets/img/tech.png" alt="w-full h-full" />
                <div className="absolute top-10 left-10 flex flex-col h-full w-full justify-between">
                  <div className="flex flex-col gap-8">
                    <p className="text-white text-left text-base md:text-2xl font-bold">
                      Innovation
                    </p>
                    <p className="font-light text-base text-white opacity-60">
                      Partenariats industriels, start-up et brevets
                    </p>
                  </div>
                </div>
                <p className="absolute bottom-20 left-10 font-light text-base cursor-pointer text-white opacity-60 underline underline-offset-8">
                  En savoir plus
                </p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className="md:container mt-5 mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center py-4"
            style={{ padding: "unset" }}
          >
            <div className="w-full relative flex flex-row gap-3">
              <div className="w-full xl:w-10/1">
                {_findSciences.map((item, i) => {
                  return (
                    <div className="flex flex-col justify-start items-start bg-white mt-5 w-full px-4 py-8">
                      <h1 className="text-base md:text-3xl font-bold text-center text-black">
                        {item.articleTitle}
                      </h1>
                      <div className="flex flex-row w-full justify-between mt-6">
                        <div className="flex flex-col">
                          <div className="flex flex-row gap-4">
                            <div className="bg-primary-100 py-1 px-2">
                              <span className="text-white">
                                Date de publication
                              </span>
                            </div>
                            <div className="flex items-center gap-1">
                              <CalendarSVG />
                              <span className="text-xs md:text-base font-normal">
                                juin 2022DOI : 10.1002/cai2.10ISBN : 2770-9183
                              </span>
                            </div>
                          </div>
                          <div className="mt-6">
                            <div className="flex flex-row items-center gap-2">
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  opacity="0.4"
                                  d="M10.0003 18.3415C14.6027 18.3415 18.3337 14.6105 18.3337 10.0081C18.3337 5.40576 14.6027 1.6748 10.0003 1.6748C5.39795 1.6748 1.66699 5.40576 1.66699 10.0081C1.66699 14.6105 5.39795 18.3415 10.0003 18.3415Z"
                                  fill="#333333"
                                  fill-opacity="0.99"
                                />
                                <path
                                  d="M10 5.7832C8.275 5.7832 6.875 7.1832 6.875 8.9082C6.875 10.5999 8.2 11.9749 9.95833 12.0249C9.98333 12.0249 10.0167 12.0249 10.0333 12.0249C10.05 12.0249 10.075 12.0249 10.0917 12.0249C10.1 12.0249 10.1083 12.0249 10.1083 12.0249C11.7917 11.9665 13.1167 10.5999 13.125 8.9082C13.125 7.1832 11.725 5.7832 10 5.7832Z"
                                  fill="#333333"
                                  fill-opacity="0.99"
                                />
                                <path
                                  d="M15.6496 16.1335C14.1663 17.5002 12.1829 18.3419 9.99961 18.3419C7.81628 18.3419 5.83294 17.5002 4.34961 16.1335C4.54961 15.3752 5.09128 14.6835 5.88294 14.1502C8.15794 12.6335 11.8579 12.6335 14.1163 14.1502C14.9163 14.6835 15.4496 15.3752 15.6496 16.1335Z"
                                  fill="#333333"
                                  fill-opacity="0.99"
                                />
                              </svg>
                              <span className="text-xs md:text-base font-light">
                                Christina Fotopoulou
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="w-40 h-20 md:h-30 relative">
                          <img
                            src={item.articlePic}
                            className="object-cover w-full h-full"
                            alt={item.articleTitle}
                          />
                          <div className="flex items-center justify-center h-10 w-10 bg-primary-100 absolute -right-4 bottom-3">
                            <svg
                              width="11"
                              height="10"
                              viewBox="0 0 11 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9.58627 9.44866C9.96197 9.44866 10.2665 9.1441 10.2665 8.7684L10.2665 2.64602C10.2665 2.27032 9.96197 1.96575 9.58627 1.96575C9.21057 1.96575 8.906 2.27032 8.906 2.64602L8.906 8.08813L3.46389 8.08813C3.08819 8.08813 2.78362 8.3927 2.78362 8.7684C2.78362 9.1441 3.08819 9.44866 3.46389 9.44866L9.58627 9.44866ZM0.942076 1.08624L9.10525 9.24942L10.0673 8.28738L1.90412 0.124205L0.942076 1.08624Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {_articles.length > 0 && (
                <div className="w-4/12 hidden xl:block">
                  <div className="flex flex-col bg-white">
                    {_articles.filter(
                      (item) =>
                        _rubrics.find((r) => r.id === item.rubricId)?.name ===
                        "Santé"
                    ) && (
                      <Business
                        business={_articles.filter(
                          (item) =>
                            _rubrics.find((r) => r.id === item.rubricId)
                              ?.name === "Santé"
                        )}
                        title="Santé"
                      />
                    )}
                    {_articles.filter(
                      (item) =>
                        _rubrics.find((r) => r.id === item.rubricId)?.name ===
                        "Innovation"
                    ) && (
                      <BlockNews
                        title="Innovation"
                        data={_articles.filter(
                          (item) =>
                            _rubrics.find((r) => r.id === item.rubricId)
                              ?.name === "Innovation"
                        )}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      <div className="md:container mx-auto w-full mt-24 flex flex-wrap p-5 flex-col md:flex-row items-center justify-center">
        <div className="w-4/6 flex flex-col items-center gap-8">
          <h1 className="text-base md:text-xl font-bold text-center text-natural-101">
            Advance your research and join a<br /> community of 20 million
            scientists
          </h1>
          <button className="bg-primary-100 px-16 py-4 font-normal text-sm text-white">
            Join for free
          </button>
        </div>
      </div>

      <div className="bg-white px-6  mt-6">
        <div className="md:container mx-auto pt-6 flex flex-wrap p-5 flex-col md:flex-row items-center py-4">
          <div className="relative flex flex-row items-center gap-8">
            <h1 className="text-5xl font-bold text-black z-10">
              Actualités sur la recherche
            </h1>
            <div className="absolute -bottom-3 -left-3 h-6 w-14 bg-primary-100"></div>
          </div>
          <div className="grid grid-cols-3">
            {_articles.slice(0, 3).map((magazine) => {
              return (
                <Tricks
                  rubricName={
                    _rubrics.find((r) => r.id === magazine.rubricId)?.name!
                  }
                  type="white"
                  line={true}
                  displayLine={true}
                  magazine={magazine}
                />
              );
            })}
          </div>
        </div>
        <div className="bg-primary-100 p-8">
          <div className="md:container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center py-4">
            <div className="flex flex-row w-full items-center justify-between">
              <div className="flex flex-col gap-6">
                <h1 className="font-bold text-lg text-white">
                  La fondation Stand Against Cancer
                </h1>
                <h6 className="text-white opacity-60">
                  Pour accompagner et développer une recherche sur le cancer
                </h6>
              </div>
              <div>
                <button className="bg-primary-100 px-16 py-4 border-2 border-white font-normal text-sm text-white">
                  Voir le site
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
