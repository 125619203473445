
interface CardInterface {
  title: string;
  quantity: number;
  icon: React.ReactNode;
  color: string
}

export function Card({title, icon, quantity, color}:CardInterface) {
  return (
    <div className="w-full max-w-full px-3 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 xl:w-1/4">
      <div className="relative flex flex-col h-40 min-w-0 break-words bg-white shadow-md dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
        <div className="flex-auto p-4 h-full">
          <div className="flex flex-row -mx-3 h-full">
            <div className="flex flex-col justify-between h-full w-full px-6 py-2">
              <div className="flex flex-row justify-items-center items-center gap-5">
                <div className={`flex items-center w-12 h-12 rounded-full bg-${color}-100 border-2 border-${color}-70`}>
                    <div className="flex justify-center items-center content-center h-20 w-20 rounded-full fill-current text-white">
                     {icon}
                    </div>
                </div>
                <div className="flex flex-col">
                  <h4 className="font-medium text-lg text-slate-700">{title}</h4>
                  <h4 className="font-bold text-3xl text-slate-700">{quantity}</h4>
                </div>
              </div>
              <div>
              <h4 className="font-normal text-sm text-primary-100">Voir Details</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  );
}
