/* AUTH */
export const EMAIL_INPUT = "EMAIL_INPUT";
export const PASSWORD_INPUT = "PASSWORD_INPUT";
export const LOGIN_LOADING = "LOADING";
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

/* RUBRICS */
export const RUBRIC_LOADING = "RUBRIC_LOADING";
export const ACTION_LOADING = "ACTION_LOADING";
export const SHOW_RUBRIC_MODAL = "SHOW_RUBRIC_MODAL";
export const ACTION_RUBRIC_TYPE = "ACTION_RUBRIC_TYPE";
export const TITLE_RUBRIC_INPUT = "TITLE_RUBRIC_INPUT";
export const SEARCH_RUBRIC_INPUT = "SEARCH_RUBRIC_INPUT";
export const SHOW_ALERT_RUBRIC = "SHOW_ALERT_RUBRIC";

export const GET_ALL_RUBRICS_SUCCESS = "GET_ALL_RUBRICS_SUCCESS";
export const GET_ALL_RUBRICS_ERROR = "GET_ALL_RUBRICS_ERROR";
export const CREATE_RUBRIC_SUCCESS = "CREATE_RUBRIC_SUCCESS";
export const MESSAGE_RUBRIC_ERROR = "MESSAGE_RUBRIC_ERROR";
export const UPDATE_RUBRIC_SUCCESS = "UPDATE_RUBRIC_SUCCESS";
export const DELETE_RUBRIC_SUCCESS = "DELETE_RUBRIC_SUCCESS";

/*MAGAZINES*/
export const GET_ALL_SPOTLIGHT = "GET_ALL_SPOTLIGHT";
export const ADD_SPOTLIGHT = "ADD_SPOTLIGHT";
export const REMOVE_SPOTLIGHT = "REMOVE_SPOTLIGHT";
export const SPOTLIGHT_ERROR = "SPOTLIGHT_ERROR";
export const SPOTLIGHT_LOADING = "SPOTLIGHT_LOADING";
export const DELETE_SPOTLIGHT_SUCCESS = "DELETE_SPOTLIGHT_SUCCESS";

export const MAGAZINE_LOADING = "MAGAZINE_LOADING";
export const GET_ALL_MAGAZINE = "GET_ALL_MAGAZINE";

/*E-MAGAZINE */
export const EMAGAZINE_ACTION_LOADING = "EMAGAZINE_ACTION_LOADING";
export const ACTION_EMAGAZINE_TYPE = "ACTION_EMAGAZINE_TYPE";
export const SHOW_EMAGAZINE_MODAL = "SHOW_EMAGAZINE_MODAL";
export const EMAGAZINE_LOADING = "EMAGAZINE_LOADING";
export const TITLE_EMAGAZINE_INPUT = "TITLE_EMAGAZINE_INPUT";
export const SEARCH_EMAGAZINE_INPUT = "SEARCH_EMAGAZINE_INPUT";
export const SHOW_ALERT_EMAGAZINE = "SHOW_ALERT_EMAGAZINE";

export const GET_ALL_EMAGAZINES_SUCCESS = "GET_ALL_EMAGAZINES_SUCCESS";
export const GET_ALL_EMAGAZINES_ERROR = "GET_ALL_EMAGAZINES_ERROR";
export const CREATE_EMAGAZINE_SUCCESS = "CREATE_EMAGAZINE_SUCCESS";
export const MESSAGE_EMAGAZINE_ERROR = "MESSAGE_EMAGAZINE_ERROR";
export const UPDATE_EMAGAZINE_SUCCESS = "UPDATE_EMAGAZINE_SUCCESS";
export const DELETE_EMAGAZINE_SUCCESS = "DELETE_EMAGAZINE_SUCCESS";

/* PORTRAIT */
export const PORTRAIT_LOADING = "PORTRAIT_LOADING";
export const ACTION_PORTRAIT_LOADING = "ACTION_PORTRAIT_LOADING";
export const SHOW_PORTRAIT_MODAL = "SHOW_PORTRAIT_MODAL";
export const ACTION_PORTRAIT_TYPE = "ACTION_PORTRAIT_TYPE";
export const TITLE_PORTRAIT_INPUT = "TITLE_PORTRAIT_INPUT";
export const PICTURE_PORTRAIT_INPUT = "PICTURE_PORTRAIT_INPUT";
export const SEARCH_PORTRAIT_INPUT = "SEARCH_PORTRAIT_INPUT";
export const SHOW_ALERT_PORTRAIT = "SHOW_ALERT_PORTRAIT";

export const GET_ALL_PORTRAIT_SUCCESS = "GET_ALL_PORTRAIT_SUCCESS";
export const GET_ALL_PORTRAIT_ERROR = "GET_ALL_PORTRAIT_ERROR";
export const CREATE_PORTRAIT_SUCCESS = "CREATE_PORTRAIT_SUCCESS";
export const MESSAGE_PORTRAIT_ERROR = "MESSAGE_PORTRAIT_ERROR";
export const UPDATE_PORTRAIT_SUCCESS = "UPDATE_PORTRAIT_SUCCESS";
export const DELETE_PORTRAIT_SUCCESS = "DELETE_PORTRAIT_SUCCESS";
