import classNames from "classnames";

type AlertType = "danger" | "success" | "warning" | "info";

const alertType = {
  danger: ["bg-red-100", "border-red-400", "text-red-700"],
  success: ["bg-green-100", "border-green-400", "text-green-700"],
  warning: ["bg-orange-100", "border-orange-400", "text-orange-700"],
  info: ["bg-blue-100", "border-blue-400", "text-blue-700"],
};

interface AlertProps {
  type: AlertType;
  title: string;
  description: string;
  className?: string;
}

export function Alert({ type, title, description, className }: AlertProps) {
  return (
    <div
      className={classNames(
        className,
        alertType[type],
        "flex p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:text-red-400 dark:border-red-800"
      )}
      role="alert"
    >
      <svg
        aria-hidden="true"
        className="flex-shrink-0 inline w-5 h-5 mr-3"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
          clip-rule="evenodd"
        ></path>
      </svg>
      <span className="sr-only">Info</span>
      <div>{description}.</div>
    </div>
  );
}
