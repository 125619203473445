import React, { useState, useEffect } from "react";

interface Props {
  text: string;
  maxLines: number;
}

export const TextLineReducer: React.FC<Props> = ({ text, maxLines }) => {
  const [truncatedText, setTruncatedText] = useState<string>(text);

  useEffect(() => {
    const lineHeight = 500; // remplacer par la hauteur de ligne de votre texte
    const maxHeight = maxLines * lineHeight;
    const node = document.createElement("div");
    const textNode = document.createTextNode(text);
    node.appendChild(textNode);
    document.body.appendChild(node);
    let i = 0;
    while (node.offsetHeight > maxHeight && i < text.length) {
      i++;
      setTruncatedText(text.slice(0, -i) + "...");
    }
    document.body.removeChild(node);
  }, [text, maxLines]);

  return <span>{truncatedText}</span>;
};
